// Funcion que consigue el token del session storage

function getToken() {
  try {
    let token = sessionStorage.getItem('accessToken')
    if (token) {
      return token
    }
  } catch (error) {
    return null
  }
}

// Function that gets the user type from the sesion storage
function getUserType() {
  try {
    let user = sessionStorage.getItem('user')
    user = JSON.parse(user)
    if (user) {
      user = user?.roles[0]?.code
    }
    return user
  } catch (error) {
    return null
  }
}

function getUser() {
  try {
    let user = sessionStorage.getItem('user')
    user = JSON.parse(user)
    return user
  } catch (error) {
    return null
  }
}

function getProvider() {
  try {
    let user = sessionStorage.getItem('user')
    user = JSON.parse(user)
    return user?.provider?._id
  } catch (error) {
    return null
  }
}

function logOut() {
  sessionStorage.clear()
  window.location.replace(`login`)
}

export default {
  getToken,
  getUserType,
  logOut,
  getUser,
  getProvider,
}
