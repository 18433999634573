import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const particularCustomerService = {
  getCasesByOperator: async (description) => {
    const response = await authInstance
      .get(
        `${url}/particularCustomers/search?description=${description}&limit=10`
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  createParticularCustomer: async (data) => {
    const response = await authInstance
      .post(`${url}/particularCustomers/create`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  editParticularCustomer: async (data, customerId) => {
    const response = await authInstance
      .put(`${url}/particularCustomers/${customerId}/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
