import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const stagesService = {
  createInitialTowStage: async (data) => {
    const response = await authInstance
      .post(`${url}/services/createTow`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
