import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const garageService = {
  getInvoices: async (params) => {
    const response = await authInstance
      .get(`${url}/tallerGp/listInvoices?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getGarageAddress: async () => {
    const response = await authInstance
      .get(`${url}/fixedAddress/list?`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
