import { useEffect, useState } from 'react';
import { ActionButton, Breadcrumb } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  Tables,
  InternalProviderTable,
  VehicularAssetsTable,
} from '../../common/Tables/Tables';
import {
  craneTableheader,
  internalProviderTableHeader,
  vehicularAssetsTableHeader,
} from '../../../assets/helpers/tables';
import {
  getProvidersEnum,
  providersOptions,
} from '../../../assets/helpers/options';
import { providersService } from '../../../services/providersService';
import { vehicularAssetsService } from '../../../services/vehicularAssetsService';
import { notify } from '../../../assets/helpers/toast';
import { Select, SearchInput } from '../../common/Forms/Input/Input';
import { useHistory } from 'react-router-dom';
import { exists, filterParams } from '../../../assets/helpers/funciones';
import './Providers.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const ExternalProviders = () => {
  const history = useHistory();

  const [providersDataTable, setProvidersDataTable] = useState({
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    type: getProvidersEnum.CRANE,
    isInternal: false,
    isLoading: true,
  });

  const links = [
    { name: 'Home', url: '/services' },
    { name: 'Proveedores', url: '/providers/external' },
  ];

  const handleProviderType = (e) => {
    const { value } = e.target;
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        type: value,
        description: '',
      };
    });
  };

  const selectitemsPerpage = (event) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * providersDataTable?.limit,
      };
    });
  };

  const getProviders = () => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    let params = {
      type: providersDataTable?.type,
      offset: providersDataTable?.offset,
      limit: providersDataTable?.limit,
      isInternal: providersDataTable?.isInternal,
      description: providersDataTable?.description,
    };

    providersService
      .getProvidersByType(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setProvidersDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.providers,
              count: response?.data?.count,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setProvidersDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setProvidersDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const handleSearchChange = (e) => {
    const { value } = e?.target;
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        description: value,
        page: 1,
        offset: 0,
      };
    });
    if (!value) {
      window.location.reload();
    }
  };

  const handleEdit = (e) => {
    history.push(`/providers/external/edit/${e?._id}`);
  };

  useEffect(() => {
    getProviders();
  }, [
    providersDataTable?.page,
    providersDataTable?.offset,
    providersDataTable?.limit,
    providersDataTable?.type,
  ]);

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Proveedores externos: {exists(providersDataTable?.count)}
        </h1>
        <div className="breadcrumb-container">
          <Breadcrumb links={links} />
          <div className="providers-buttons-container">
            <span></span>
            {/* <Select
              options={providersOptions}
              label={'Servicio'}
              value={providersDataTable?.type}
              onChange={handleProviderType}
            /> */}
            <ActionButton
              action={() => history.push('/providers/external/create')}
              label={'+ Nuevo Proveedor'}
            />
          </div>
        </div>
        <div className="gray-line"></div>

        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <div className="service-filter-buttons-container">
            <SelectPerPage
              label={'Registros por página'}
              options={itemsPerpage}
              value={providersDataTable?.limit}
              onChange={selectitemsPerpage}
            />
            <SearchInput
              placeholder={'Nombre, Apellido o Documento de Identidad'}
              width="360px"
              value={providersDataTable?.description}
              onChange={handleSearchChange}
              searchAction={getProviders}
            />
          </div>
        </div>
        <Tables
          header={craneTableheader}
          dataTable={providersDataTable}
          handlePageChange={handlePageChange}
          edit={handleEdit}
        />
      </div>
    </>
  );
};

export const InternalProviders = () => {
  const history = useHistory();
  const links = [
    { name: 'Home', url: '/services' },
    { name: 'Chóferes', url: '/providers/internal' },
  ];

  const [providersDataTable, setProvidersDataTable] = useState({
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    type: getProvidersEnum.ROAD_ATTENDANT,
    isInternal: true,
    isLoading: true,
    description: '',
  });

  const handleProviderType = (e) => {
    const { value } = e.target;
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        type: value,
        description: '',
      };
    });
  };

  const selectitemsPerpage = (event) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * providersDataTable?.limit,
      };
    });
  };

  const getProviders = () => {
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      type: providersDataTable?.type,
      offset: providersDataTable?.offset,
      limit: providersDataTable?.limit,
      isInternal: providersDataTable?.isInternal,
      description: providersDataTable?.description,
    };

    providersService
      .getProvidersByType(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setProvidersDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.providers,
              count: response?.data?.count,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setProvidersDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setProvidersDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const handleSearchChange = (e) => {
    const { value } = e?.target;
    setProvidersDataTable((prevState) => {
      return {
        ...prevState,
        description: value,
        page: 1,
        offset: 0,
      };
    });
    if (!value) {
      window.location.reload();
    }
  };

  const handleEdit = (e) => {
    history.push(`/providers/internal/edit/${e?._id}`);
  };

  const activateProvider = (data, active) => {
    providersService
      .acrtivateProvider({ isActive: !active }, data?._id)
      .then((response) => {
        if (response.status === 200) {
          notify(`Usuario ${data?.contactName} editado`, "success")
          getProviders();
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
  }

  useEffect(() => {
    getProviders();
  }, [
    providersDataTable?.page,
    providersDataTable?.offset,
    providersDataTable?.limit,
    providersDataTable?.type,
  ]);

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Chóferes registrados: {exists(providersDataTable?.count)}
        </h1>
        <div className="breadcrumb-container">
          <Breadcrumb links={links} />
          <div className="providers-buttons-container">
            <Select
              options={providersOptions}
              label={'Servicio'}
              value={providersDataTable?.type}
              onChange={handleProviderType}
            />
            <ActionButton
              action={() => history.push('/providers/internal/create')}
              label={'+ Nuevo Chofer'}
            />
          </div>
        </div>
        <div className="gray-line"></div>

        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <div className="service-filter-buttons-container">
            <SelectPerPage
              label={'Registros por página'}
              options={itemsPerpage}
              value={providersDataTable?.limit}
              onChange={selectitemsPerpage}
            />
            <SearchInput
              placeholder={'Nombre, Apellido o Documento de Identidad'}
              width="360px"
              value={providersDataTable?.description}
              onChange={handleSearchChange}
              searchAction={getProviders}
            />
          </div>
        </div>
        <InternalProviderTable
          header={internalProviderTableHeader}
          dataTable={providersDataTable}
          handlePageChange={handlePageChange}
          edit={handleEdit}
          activateProvider={activateProvider}
        />
      </div>
    </>
  );
};

export const Fleet = () => {
  const history = useHistory();
  const links = [
    { name: 'Home', url: '/services' },
    { name: 'Flota ', url: '/providers/fleet' },
  ];

  const [vehicularAssets, setVehicularAssets] = useState({
    limit: itemsPerpage[1],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    isLoading: true,
    description: '',
  });

  // const handleProviderType = (e) => {
  //   const { value } = e.target;
  //   setVehicularAssets((prevState) => {
  //     return {
  //       ...prevState,
  //       page: 1,
  //       offset: 0,
  //       type: value,
  //       description: '',
  //     };
  //   });
  // };

  const selectitemsPerpage = (event) => {
    setVehicularAssets((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setVehicularAssets((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * vehicularAssets?.limit,
      };
    });
  };

  const getProviders = () => {
    setVehicularAssets((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    vehicularAssetsService
      .getVehicularAssets(vehicularAssets)
      .then((response) => {
        if (response.status === 200) {
          setVehicularAssets((prevState) => {
            return {
              ...prevState,
              data: response?.data?.vehicularAssets,
              count: response?.data?.count,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setVehicularAssets((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setVehicularAssets((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  // const handleSearchChange = (e) => {
  //   const { value } = e?.target;
  //   setVehicularAssets((prevState) => {
  //     return {
  //       ...prevState,
  //       description: value,
  //       page: 1,
  //       offset: 0,
  //     };
  //   });
  // };

  const handleEdit = (e) => {
    history.push(`/providers/fleet/edit/${e?._id}`);
  };

  useEffect(() => {
    getProviders();
  }, [vehicularAssets?.page, vehicularAssets?.offset, vehicularAssets?.limit]);

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Flota / Vehiculos Registrados: {exists(vehicularAssets?.count)}
        </h1>
        <div className="breadcrumb-container">
          <Breadcrumb links={links} />
          <div className="providers-buttons-container">
            <span></span>
            <ActionButton
              action={() => history.push('/providers/fleet/create')}
              label={'+ Nuevo Vehículo'}
            />
          </div>
        </div>
        <div className="gray-line"></div>

        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <div className="service-filter-buttons-container">
            <SelectPerPage
              label={'Registros por página'}
              options={itemsPerpage}
              value={vehicularAssets?.limit}
              onChange={selectitemsPerpage}
            />
            {/* <SearchInput
              placeholder={'Nombre, Apellido o Documento de Identidad'}
              width="360px"
              value={vehicularAssets?.description}
              onChange={handleSearchChange}
              searchAction={getProviders}
            /> */}
          </div>
        </div>
        <VehicularAssetsTable
          header={vehicularAssetsTableHeader}
          dataTable={vehicularAssets}
          handlePageChange={handlePageChange}
          edit={handleEdit}
        />
      </div>
    </>
  );
};
