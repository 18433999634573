import {
  phoneCodes,
  idCodes,
  prodiverStatys,
  providersOptions,
} from '../helpers/options'

export const followingType = [
  { _id: 'Llamada', name: 'Llamada' },
  { _id: 'Whatsapp', name: 'Whatsapp' },
  { _id: 'SMS', name: 'SMS' },
  { _id: 'GPS', name: 'GPS' },
]

export const message = [
  { _id: 'En Via', name: 'En Via' },
  { _id: 'Finalizando otro servicio', name: 'Finalizando otro servicio' },
  { _id: 'En cola', name: 'En cola' },
  { _id: 'Accidente en via', name: 'Accidente en via' },
  { _id: 'Accidentado', name: 'Accidentado' },
]

// Login form values
export const login = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12',
      label: t('form.labels.email'),
      type: 'text',
      name: 'email',
      register: register,
      placeholder: t('form.labels.email'),
      errors: errors?.email?.message,
    },
    {
      bootstrap: 'col-12',
      label: t('form.labels.password'),
      type: 'password',
      name: 'password',
      register: register,
      placeholder: t('form.labels.password'),
      errors: errors?.password?.message,
    },
  ]
}

export const following = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12',
      label: 'Tipo de seguimiento',
      type: 'select',
      options: followingType,
      name: 'type',
      register: register,
      placeholder: '',
      errors: errors?.type?.message,
    },
    {
      bootstrap: 'col-12',
      label: 'Mensaje',
      type: 'select',
      options: message,
      name: 'message',
      register: register,
      placeholder: '',
      errors: errors?.message?.message,
    },
    {
      bootstrap: 'col-12',
      label: 'Observación',
      type: 'textarea',
      name: 'note',
      register: register,
      placeholder: '',
      errors: errors?.note?.message,
    },
  ]
}

export const CreateExternalProviderForm = (t, register, errors, options) => {
  return [
    {
      title: 'Detalles del propietario',
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Nombre de la empresa: *',
      type: 'text',
      name: 'companyName',
      register: register,
      placeholder: 'Nombre de la empresa',
      errors: errors?.companyName?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Nombre y Apellido *',
      type: 'text',
      name: 'contactName',
      register: register,
      placeholder: 'Nombre y Apellido',
      errors: errors?.contactName?.message,
    },
    {
      bootstrap: 'col-4 col-sm-2 col-md-1',
      label: 'C.I / RIF*',
      type: 'select',
      options: idCodes,
      name: 'identificationType',
      register: register,
      placeholder: 'C.I / RIF*',
      errors: errors?.identificationType?.message,
    },
    {
      bootstrap: 'col-8 col-sm-4 col-md-3',
      label: ' ',
      type: 'number',
      name: 'identification',
      register: register,
      placeholder: '',
      errors: errors?.identification?.message,
    },
    {
      bootstrap: 'col-4 col-sm-2 col-md-1',
      label: 'Teléfono *',
      type: 'select',
      options: phoneCodes,
      name: 'phonePrefix',
      register: register,
      placeholder: '',
      errors: errors?.phonePrefix?.message,
    },
    {
      bootstrap: 'col-8 col-sm-4 col-md-3',
      label: ' ',
      type: 'number',
      name: 'phoneNumber',
      register: register,
      placeholder: '',
      errors: errors?.phoneNumber?.message,
    },
    // {
    //   bootstrap: 'col-12 col-md-4',
    //   label: 'Servicio a prestar *',
    //   type: 'select',
    //   name: 'type',
    //   register: register,
    //   options: providersOptions,
    //   placeholder: '',
    //   errors: errors?.type?.message,
    // },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Estado donde presta servicio*',
      type: 'select',
      name: 'state',
      register: register,
      options: options?.statesOptions,
      placeholder: '',
      errors: errors?.state?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Estatus',
      type: 'select',
      name: 'isActive',
      register: register,
      options: prodiverStatys,
      placeholder: '',
      errors: errors?.isActive?.message,
    },
  ]
}

export const newVehicle = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Tipo *',
      type: 'select',
      options: [],
      name: 'cedula',
      register: register,
      placeholder: '',
      errors: errors?.cedula?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Modelo *',
      type: 'text',
      name: 'name1',
      register: register,
      placeholder: '',
      errors: errors?.name1?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Placa *',
      type: 'text',
      name: 'name2',
      register: register,
      placeholder: '',
      errors: errors?.name2?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Marca *',
      type: 'text',
      name: 'name3',
      register: register,
      placeholder: '',
      errors: errors?.name3?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Año*',
      type: 'text',
      name: 'name4',
      register: register,
      placeholder: '',
      errors: errors?.name4?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Fecha de Compra *',
      type: 'date',
      name: 'name5',
      register: register,
      placeholder: '',
      errors: errors?.name5?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Descripción',
      type: 'textarea',
      name: 'name6',
      register: register,
      placeholder: '',
      errors: errors?.name6?.message,
    },
  ]
}

export const approvalPaymentForm = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12',
      label: 'Autorizado Por:',
      type: 'select',
      options: [],
      name: 'email',
      register: register,
      placeholder: t('form.labels.email'),
      errors: errors?.email?.message,
    },
    {
      bootstrap: 'col-12',
      label: 'Contraseña',
      type: 'password',
      name: 'password',
      register: register,
      placeholder: t('form.labels.password'),
      errors: errors?.password?.message,
    },
  ]
}

export const CreateParticularCustomertForm = (
  t,
  register,
  errors,
  options,
  onChange,
  watch
) => {
  return [
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Nombre *',
      type: 'text',
      name: 'firstName',
      register: register,
      placeholder: '',
      errors: errors?.firstName?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Apellido *',
      type: 'text',
      name: 'lastName',
      register: register,
      placeholder: '',
      errors: errors?.lastName?.message,
    },
    {
      bootstrap: 'col-4 col-sm-2 col-md-1',
      label: 'Teléfono *',
      type: 'select',
      options: phoneCodes,
      name: 'phonePrefix',
      register: register,
      placeholder: 'xxxxxxxx',
      errors: errors?.phonePrefix?.message,
    },
    {
      bootstrap: 'col-8 col-sm-4 col-md-3',
      label: ' ',
      type: 'text',
      name: 'phoneNumber',
      register: register,
      placeholder: '',
      errors: errors?.phoneNumber?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Marca del Vehículo *',
      type: 'select',
      options: options?.vehicleBrandOptions,
      onChange: () => onChange?.getVehicleModels(watch('vehicleBrand')),
      name: 'vehicleBrand',
      register: register,
      placeholder: '',
      errors: errors?.vehicleBrand?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Modelo del vehículo *',
      type: 'select',
      options: options?.vehicleModelOptions,
      name: 'vehicleModel',
      register: register,
      placeholder: '',
      errors: errors?.vehicleModel?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Color del vehículo *',
      type: 'text',
      name: 'vehicleColor',
      register: register,
      placeholder: '',
      errors: errors?.vehicleColor?.message,
    },

    {
      bootstrap: 'col-12 col-md-2',
      label: 'Año',
      type: 'number',
      name: 'vehicleYear',
      register: register,
      placeholder: '',
      errors: errors?.vehicleYear?.message,
      min: '1940',
      max: '2040',
    },
    {
      bootstrap: 'col-12 col-md-2',
      label: 'Placa *',
      type: 'text',
      name: 'vehiclePlate',
      register: register,
      placeholder: '',
      errors: errors?.vehiclePlate?.message,
    },
  ]
}

export const Charge = (t, register, errors) => {
  return [
    {
      title: 'Detalles del propietario',
    },
    {
      bootstrap: 'col-4 col-sm-2 col-md-1',
      label: 'Teléfono del Cliente *',
      type: 'select',
      options: phoneCodes,
      name: 'name1',
      register: register,
      placeholder: '',
      errors: errors?.name1?.message,
    },
    {
      bootstrap: 'col-8 col-sm-4 col-md-3',
      label: ' ',
      type: 'text',
      name: 'name2',
      register: register,
      placeholder: '',
      errors: errors?.name2?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Marca del Vehículo*',
      type: 'select',
      options: [],
      name: 'name3',
      register: register,
      placeholder: '',
      errors: errors?.name3?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Año del Vehículo *',
      type: 'text',
      name: 'name4',
      register: register,
      placeholder: '',
      errors: errors?.name4?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Color del Vehículo *',
      type: 'text',
      name: 'name5',
      register: register,
      placeholder: '',
      errors: errors?.name5?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Placa del Vehículo',
      type: 'text',
      name: 'name6',
      register: register,
      placeholder: '',
      errors: errors?.name6?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Tiempo de llegada (min) *',
      type: 'text',
      name: 'name7',
      register: register,
      placeholder: '',
      errors: errors?.name7?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Costo del Proveedor USD (sin IVA)*',
      type: 'text',
      name: 'name8',
      register: register,
      placeholder: '',
      errors: errors?.name8?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'PVP del servicio USD *',
      type: 'text',
      name: 'name9',
      register: register,
      placeholder: '',
      errors: errors?.name9?.message,
    },
  ]
}

export const newInternalProviderForm = (t, register, errors, options) => {
  return [
    {
      title: 'Detalles del propietario',
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Nombre y Apellido *',
      type: 'text',
      name: 'contactName',
      register: register,
      placeholder: 'Nombre y Apellido',
      errors: errors?.contactName?.message,
    },
    {
      bootstrap: 'col-4 col-sm-2 col-md-1',
      label: 'C.I / RIF*',
      type: 'select',
      options: idCodes,
      name: 'identificationType',
      register: register,
      placeholder: 'C.I / RIF*',
      errors: errors?.identificationType?.message,
    },
    {
      bootstrap: 'col-8 col-sm-4 col-md-3',
      label: ' ',
      type: 'number',
      name: 'identification',
      register: register,
      placeholder: '',
      errors: errors?.identification?.message,
    },
    {
      bootstrap: 'col-4 col-sm-2 col-md-1',
      label: 'Teléfono *',
      type: 'select',
      options: phoneCodes,
      name: 'phonePrefix',
      register: register,
      placeholder: '',
      errors: errors?.phonePrefix?.message,
    },
    {
      bootstrap: 'col-8 col-sm-4 col-md-3',
      label: ' ',
      type: 'number',
      name: 'phoneNumber',
      register: register,
      placeholder: '',
      errors: errors?.phoneNumber?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Servicio a prestar *',
      type: 'select',
      name: 'type',
      register: register,
      options: providersOptions,
      placeholder: '',
      errors: errors?.type?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Estado donde presta servicio*',
      type: 'select',
      name: 'state',
      register: register,
      options: options?.statesOptions,
      placeholder: '',
      errors: errors?.state?.message,
    },
    {
      bootstrap: 'col-12 col-md-4',
      label: 'Estatus',
      type: 'select',
      name: 'isActive',
      register: register,
      options: prodiverStatys,
      placeholder: '',
      errors: errors?.isActive?.message,
    },
  ]
}

export const scheduleService = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12',
      label: 'Fecha',
      type: 'date',
      name: 'date',
      register: register,
      minDate: new Date(),
      placeholder: '',
      errors: errors?.date?.message,
    },
    {
      bootstrap: 'col-12',
      label: 'Hora',
      type: 'time',
      name: 'time',
      register: register,
      placeholder: '',
      errors: errors?.time?.message,
    },
  ]
}

export const register = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-6',
      label: 'Nombre',
      type: 'text',
      name: 'firstName',
      register: register,
      placeholder: '',
      errors: errors?.firstName?.message,
    },
    {
      bootstrap: 'col-6',
      label: 'Apellido',
      type: 'text',
      name: 'lastName',
      register: register,
      placeholder: '',
      errors: errors?.lastName?.message,
    },
    {
      bootstrap: 'col-12',
      label: t('form.labels.email'),
      type: 'text',
      name: 'email',
      register: register,
      placeholder: t('form.labels.email'),
      errors: errors?.email?.message,
    },
    {
      bootstrap: 'col-6',
      label: t('form.labels.password'),
      type: 'password',
      name: 'password',
      register: register,
      placeholder: t('form.labels.password'),
      errors: errors?.password?.message,
    },
    {
      bootstrap: 'col-6',
      label: t('form.labels.confirmPassword'),
      type: 'password',
      name: 'confirmPassword',
      register: register,
      placeholder: t('form.labels.confirmPassword'),
      errors:
        errors?.confirmPassword?.message && t('schema.password_confirmation'),
    },
  ]
}

export const createEmployee = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-6',
      label: 'Nombre',
      type: 'text',
      name: 'firstName',
      register: register,
      placeholder: '',
      errors: errors?.firstName?.message,
    },
    {
      bootstrap: 'col-6',
      label: 'Apellido',
      type: 'text',
      name: 'lastName',
      register: register,
      placeholder: '',
      errors: errors?.lastName?.message,
    },
    {
      bootstrap: 'col-6',
      label: t('form.labels.email'),
      type: 'text',
      name: 'email',
      register: register,
      placeholder: '',
      errors: errors?.email?.message,
    },
    {
      bootstrap: 'col-6',
      label: 'Telefono',
      type: 'text',
      name: 'phone',
      register: register,
      placeholder: '',
      errors: errors?.phone?.message,
    },
    {
      bootstrap: 'col-6',
      label: 'Salario Base $',
      type: 'number',
      name: 'baseSalary',
      register: register,
      placeholder: '',
      errors: errors?.baseSalary?.message,
    },
    {
      bootstrap: 'col-6',
      label: 'Cripto Address',
      type: 'text',
      name: 'cryptoAddress',
      register: register,
      placeholder: '',
      errors: errors?.cryptoAddress?.message,
    },
  ]
}

export const registerStepTwo = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12',
      label: 'Empresa',
      type: 'text',
      name: 'companyName',
      register: register,
      placeholder: '',
      errors: errors?.companyName?.message,
    },
    {
      bootstrap: 'col-12',
      label: 'Sector de la Empresa',
      type: 'select',
      options: [],
      name: 'companySector',
      register: register,
      placeholder: '',
      errors: errors?.companySector?.message,
    },
  ]
}

export const carCaseInfo1 = (t, register, errors) => {
  return [
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Código de Servicio',
      type: 'text',
      name: 'serviceCode',
      register: register,
      placeholder: '',
      errors: errors?.serviceCode?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Modelo',
      type: 'text',
      name: 'vehicleModel',
      register: register,
      placeholder: '',
      errors: errors?.vehicleModel?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Marca',
      type: 'text',
      name: 'vehicleBrand',
      register: register,
      placeholder: '',
      errors: errors?.vehicleBrand?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Placa',
      type: 'text',
      name: 'vehiclePlate',
      register: register,
      placeholder: '',
      errors: errors?.vehiclePlate?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Color',
      type: 'text',
      name: 'color',
      register: register,
      placeholder: '',
      errors: errors?.color?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '',
      type: 'text',
      name: '',
      register: register,
      placeholder: '',
      errors: errors?.client?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Nombre del Cliente',
      type: 'text',
      name: 'customerFirstName',
      register: register,
      placeholder: '',
      errors: errors?.customerFirstName?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Apellido del Cliente',
      type: 'text',
      name: 'customerLastName',
      register: register,
      placeholder: '',
      errors: errors?.customerLastName?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-6 col-md-2 col-lg-1',
      label: 'Teléfono',
      type: 'text',
      name: 'customerPhonePrefix',
      register: register,
      placeholder: '',
      errors: errors?.customerPhonePrefix?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-6 col-md-2 col-lg-1',
      label: '',
      type: 'text',
      name: 'customerPhoneNumber',
      register: register,
      placeholder: '',
      errors: errors?.customerPhoneNumber?.message,
      disabled: true,
    },
  ]
}
export const carCaseInfo2 = (t, register, errors, control) => {
  return [
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Fecha de Servicio *',
      type: 'date',
      name: 'serviceDate',
      register: register,
      placeholder: '',
      control: control,
      errors: errors?.serviceDate?.message,
      disabled: true,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: 'Motorizado',
      type: 'text',
      name: 'provider',
      register: register,
      placeholder: '',
      errors: errors?.provider?.message,
      disabled: true,
    },
  ]
}

export const carCaseInfo3 = (t, register, errors, options) => {
  return [
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Nivel del refrigerante en envase? *',
      type: 'select',
      name: 'coolantLevel',
      options: options?.LevelOptions,
      register: register,
      placeholder: '',
      errors: errors?.coolantLevel?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Nivel de aceite de motor? *',
      type: 'select',
      name: 'engineOilLevel',
      options: options?.LevelOptions,
      register: register,
      placeholder: '',
      errors: errors?.engineOilLevel?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Nivel de aceite de dirección? *',
      type: 'select',
      name: 'brakeFluidLevel',
      options: options?.LevelOptions,
      register: register,
      placeholder: '',
      errors: errors?.brakeFluidLevel?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Nivel de liga de freno? *',
      type: 'select',
      name: 'gearboxOilLevel',
      options: options?.LevelOptions,
      register: register,
      placeholder: '',
      errors: errors?.gearboxOilLevel?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: ' ¿Nivel de aceite de caja? *',
      type: 'select',
      name: 'powerSteeringFluidLevel',
      options: options?.LevelOptions,
      register: register,
      placeholder: '',
      errors: errors?.powerSteeringFluidLevel?.message,
    },
  ]
}

export const carCaseInfo4 = (t, register, errors, options) => {
  return [
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de aceite de motor? * *',
      type: 'select',
      name: 'engineOilCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.engineOilCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de refrigerante? *',
      type: 'select',
      name: 'coolantFluidCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.coolantFluidCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de aceite de dirección? *',
      type: 'select',
      name: 'powerSteeringFluidCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.powerSteeringFluidCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de liga de freno? *',
      type: 'select',
      name: 'brakeFluidCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.brakeFluidCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de aceite de caja? *',
      type: 'select',
      name: 'gearboxOilCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.gearboxOilCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de correa de tiempo? *',
      type: 'select',
      name: 'timingBeltCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.timingBeltCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de correa de única? *',
      type: 'select',
      name: 'uniqueBeltCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.uniqueBeltCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de correa de alternador? *',
      type: 'select',
      name: 'alternatorBeltCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.alternatorBeltCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de correa de aire acondicionado? *',
      type: 'select',
      name: 'airConditionerBeltCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.airConditionerBeltCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de bomba de dirección? *',
      type: 'select',
      name: 'steeringPumpBeltCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.steeringPumpBeltCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de poleas? *',
      type: 'select',
      name: 'pulleysCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.pulleysCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de tensor? *',
      type: 'select',
      name: 'tensorCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.tensorCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de manguera de radiador superior? *',
      type: 'select',
      name: 'upperHosesCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.upperHosesCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de manguera de radiador inferior? *',
      type: 'select',
      name: 'lowerHosesCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.lowerHosesCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de toma de termostato? *',
      type: 'select',
      name: 'thermostatCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.thermostatCondition?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Estado de envase de refrigerante? *',
      type: 'select',
      name: 'coolantTankCondition',
      options: options?.ConditionOptions,
      register: register,
      placeholder: '',
      errors: errors?.coolantTankCondition?.message,
    },
  ]
}
export const carCaseInfo5 = (t, register, errors, options) => {
  return [
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por tapavalvula? *',
      type: 'select',
      name: 'valveCoverLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.valveCoverLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por carter de motor? *',
      type: 'select',
      name: 'oilPanLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.oilPanLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por carter de caja? *',
      type: 'select',
      name: 'gearboxPanLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.gearboxPanLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por estopera delantera de cigüeñal? *',
      type: 'select',
      name: 'frontcrankshaftSealLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.frontcrankshaftSealLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por estoperas de levas? *',
      type: 'select',
      name: 'rearCrankshaftSealLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.rearCrankshaftSealLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por estopera trasera de cigüeñal? *',
      type: 'select',
      name: 'camshaftSealLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.camshaftSealLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por estoperas copa caja? *',
      type: 'select',
      name: 'gearboxSealLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.gearboxSealLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por mangueras de dirección? *',
      type: 'select',
      name: 'steeringHosesLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.steeringHosesLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por bomba de dirección? *',
      type: 'select',
      name: 'steeringPumpLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.steeringPumpLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por transfer? *',
      type: 'select',
      name: 'transferCaseLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.transferCaseLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de aceite por diferencial? *',
      type: 'select',
      name: 'differentialLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.differentialLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de refrigerante por tanque superior de radiador? *',
      type: 'select',
      name: 'UpperRadiatorTankLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.UpperRadiatorTankLeak?.message,
    },
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿Bote de refrigerante por tanque inferior de radiador? *',
      type: 'select',
      name: 'LowerRadiatorTankLeak',
      options: options?.LeakOptions,
      register: register,
      placeholder: '',
      errors: errors?.LowerRadiatorTankLeak?.message,
    },
  ]
}

export const carCaseInfo6 = (t, register, errors, options) => {
  return [
    {
      bootstrap: 'col-12 col-md-3 col-lg-2',
      label: '¿PSI Bomba de gasolina? *',
      type: 'select',
      name: 'fuelPumpHealth',
      options: options?.HealthOptions,
      register: register,
      placeholder: '',
      errors: errors?.fuelPumpHealth?.message,
    },
  ]
}
export const carCaseInfo7 = (t, register, errors, options) => {
  return [
    {
      bootstrap: 'col-12 col-md-6',
      label: 'Comentarios',
      type: 'textarea',
      name: 'comments',
      register: register,
      placeholder: '',
      errors: errors?.comments?.message,
    },
  ]
}
