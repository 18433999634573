import { useState } from 'react';
import logo from '../../../images/logo.svg';
import home from '../../../images/home.svg';
import craneLogo from '../../../images/craneLogo.png';
import services from '../../../images/services.svg';
import config from '../../../images/config.svg';
import maleta from '../../../images/maleta.svg';
import plus from '../../../images/plus.svg';
import threedots from '../../../images/threedots.svg';
import navOptionArrow from '../../../images/navOptionArrow.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { readRole, operationsRoles } from '../../../assets/helpers/roles';
import auth from '../../../services/authService';
import { LogOutModal } from '../../../components/common/Modals/Modals';
import authService from '../../../services/authService';
import './Header.scss';

const {
  operationsAdmin,
  operationsDev,
  operationsSupervisor,
  operationsOperator,
  OPERATIONS_FLOTA_GRUA,
  OPERATIONS_FLOTA_AV
} = operationsRoles;

const links = [
  {
    name: 'Servicios',
    link: 'services',
    src: home,
    roles: [
      operationsAdmin,
      operationsDev,
      operationsSupervisor,
      operationsOperator,
      OPERATIONS_FLOTA_GRUA,
      OPERATIONS_FLOTA_AV
    ],
  },
  {
    name: 'Estadisticas',
    link: 'providers-stats',
    src: "",
    roles: [
      OPERATIONS_FLOTA_AV,
      OPERATIONS_FLOTA_GRUA,
    ],
  },
  {
    name: 'Estadísticas',
    link: 'stats',
    src: services,
    levels: [
      {
        name: 'Operación',
        url: 'operations',
        roles: [operationsDev, operationsAdmin],
      },
      { name: 'Exportables', url: 'exportable' },
      {
        name: 'Revisiones',
        url: 'reviews',
        roles: [operationsDev, operationsAdmin, operationsSupervisor],
      },
      {
        name: 'Contador Operaciones',
        url: 'operators-counter',
        roles: [operationsDev, operationsAdmin],
      },
    ],
    roles: [operationsAdmin, operationsDev, operationsSupervisor],
  },
  {
    name: 'Proveedores',
    link: 'providers',
    src: maleta,
    levels: [
      { name: 'Proveedores Externos', url: 'external' },
      { name: 'Chóferes Internos', url: 'internal' },
      { name: 'Flota', url: 'fleet' },
      {
        name: 'Pago de Proveedores',
        url: 'providers-payments',
        roles: [operationsDev, operationsAdmin],
      },
      {
        name: 'Ubicación de Flota',
        url: 'fleet-location',
      },
    ],
    roles: [operationsDev, operationsAdmin],
  },
  {
    name: 'Incidencia',
    link: 'incidence/create',
    src: plus,
    levels: [
      { name: 'Afiliado', url: 'affiliated' },
      { name: 'Particular', url: 'particular' },
    ],
    roles: [
      operationsDev,
      operationsAdmin,
      operationsSupervisor,
      operationsOperator,
    ],
  },
  {
    name: 'Configuración',
    link: 'config',
    src: config,
    levels: [
      { name: 'Usuarios', url: 'users' },
      // { name: 'Flota', url: 'fleet' },
      // { name: 'Parametros', url: 'parameters' },
    ],
    roles: [operationsDev, operationsAdmin],
  },
  {
    name: 'Servicio de Taller',
    src: plus,
    link: 'garage',
    levels: [
      { name: 'Facturación Taller', url: 'billing' },
    ],
    roles: [
      operationsAdmin,
      operationsDev,
    ],
  },
];

const linksVentas = [
  {
    name: 'Módulos',
    link: 'modules',
    src: home,
    levels: [
      { name: 'Analíticas', url: 'analytics' },
      { name: 'Giftcards', url: 'giftcards' },
      { name: 'Registro de flota', url: 'fleet-registrer' },
    ],
  },
  {
    name: 'Dashboard',
    link: 'dashboard',
    src: services,
    levels: [
      { name: 'Indicadores', url: 'indicators' },
      { name: 'Exportables', url: 'exportable' },
      { name: 'Gasto Marketing', url: 'marketing-expense' },
      { name: 'Ventas Giftcard', url: 'giftcard-sold' },
    ],
  },
  {
    name: 'Configuración',
    link: 'config',
    src: config,
    levels: [
      { name: 'Tasa de cambio', url: 'exchange-rate' },
      { name: 'Usuarios', url: 'users' },
      { name: 'Agentes', url: 'agents' },
      { name: 'Parámetros', url: 'parameters' },
      { name: 'Giftcards', url: 'giftcards' },
      { name: 'Migración Woo', url: 'migration-woo' },
      { name: 'Flota', url: 'fleet' },
    ],
  },
  { name: 'Nueva afiliación', link: 'new-membership', src: plus },
  { name: 'Nueva campaña', link: 'new-campaign', src: plus },
];

export const SublevelComponent = (props) => {
  const { link, level, closeFirstLevel } = props;

  // Si el subnivel tiene roles asignados y no incluye alguno en si lista no muestra el subnivel
  //si el subnivel no tiene roles asignados muestralo siempre
  if (level.roles?.length > 0 && !level.roles.includes(auth.getUserType())) {
    return null;
  }
  return (
    <NavLink to={`/` + link.link + `/${level?.url}`}>
      <div
        onClick={() => closeFirstLevel()}
        className="header-sublevel-option"
        key={level.name}
      >
        {level.name}
      </div>
    </NavLink>
  );
};

export const LinkComponent = (props) => {
  const { index, link, openFirstLevel, closeFirstLevel } = props;

  let selected = '';

  if (window.location.href.includes(link.link)) {
    selected = true;
  }

  if (!link?.roles.includes(auth.getUserType())) {
    return null;
  }

  if (link?.levels?.length > 0) {
    return (
      <>
        {link?.isOpen && (
          <div
            className="header-sublevel-container"
            onClick={() => closeFirstLevel()}
          ></div>
        )}
        <div
          className={`link-component link-component-${selected}`}
          onClick={
            link.isOpen ? () => closeFirstLevel() : () => openFirstLevel(index)
          }
        >
          <img className="link-component-logo" src={link.src} alt="icon" />
          <span>{link.name}</span>
          <img
            className="link-component-arrow"
            src={navOptionArrow}
            alt="navOptionArrow"
          />

          {link?.isOpen && (
            <>
              <div className="header-sublevel-section">
                {link.levels.map((l) => (
                  <SublevelComponent
                    key={l.url}
                    link={link}
                    level={l}
                    closeFirstLevel={closeFirstLevel}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div
        className={`link-component link-component-${selected}`}
        onClick={() => closeFirstLevel()}
      >
        <NavLink to={`/` + link.link}>
          {link.src &&
            <img className="link-component-logo" src={link.src} alt="icon" />
          }
          <span>{link.name}</span>
        </NavLink>
      </div>
    );
  }
};

export const Header = () => {
  const history = useHistory();
  const [linkOptions, setLinkOptions] = useState(links);
  const [logOutModal, setLogOutModal] = useState({
    isOpen: false,
  });

  let user = sessionStorage.getItem('user');
  user = JSON.parse(user);

  const openFirstLevel = (index) => {
    let links = linkOptions;
    for (let i = 0; i < links.length; i++) {
      links[i].isOpen = false;
    }
    links[index].isOpen = true;

    setLinkOptions((prevState) => {
      return [...links];
    });
  };

  const closeFirstLevel = (index) => {
    let links = linkOptions;
    for (let i = 0; i < links.length; i++) {
      links[i].isOpen = false;
    }

    setLinkOptions((prevState) => {
      return [...links];
    });
  };

  const handleLogoutModal = () => {
    setLogOutModal((prevState) => {
      return {
        ...prevState,
        isOpen: !logOutModal?.isOpen,
      };
    });
  };

  const signOut = () => {
    handleLogoutModal();
    sessionStorage.clear();
    history.push('/login');
  };

  const redirectHome = () => {
    if (history.location.pathname === '/services') {
      window.location.reload();
    } else {
      history.push('/services');
    }
  };

  return (
    <>
      <LogOutModal
        modal={logOutModal}
        handleModal={handleLogoutModal}
        action={signOut}
      />
      <div className="header-component">
        <div className="header-links-container">
          <div className="navbar-options-container">
            {![OPERATIONS_FLOTA_GRUA, OPERATIONS_FLOTA_AV]?.includes(authService?.getUserType()) &&
              <>
                <img
                  onClick={() => redirectHome()}
                  src={logo}
                  alt="logo"
                  className="header-logo"
                />
                <img
                  onClick={() => redirectHome()}
                  src={craneLogo}
                  alt="logo"
                  className="header-logo-small"
                />
              </>
            }
            {linkOptions.map((l, i) => (
              <LinkComponent
                key={l.name}
                index={i}
                link={l}
                openFirstLevel={openFirstLevel}
                closeFirstLevel={closeFirstLevel}
              />
            ))}
          </div>
          <div className="header-name-settins-container">
            <h6>
              {user?.firstName} {user?.lastName}{' '}
              <span style={{ marginLeft: '12px', textTransform: 'uppercase' }}>
                {readRole(user?.roles[0]?.code)}
              </span>
            </h6>
            <img
              onClick={() => handleLogoutModal()}
              src={threedots}
              alt={'threedots'}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
};
