import moment from 'moment'
import { exists, capitalize } from './funciones'

export const smsText = {
  greenGroup: (data, provider, service, _case) => {
    return `*SERVICIO DE TU/GRUERO${
      data?.particularCustomer?._id ? ' Particular' : ''
    }*
- *FECHA DE CREACIÓN*: ${moment
      .utc(data?.createdAt)
      .format('DD/MM/YYYY')} ${moment.utc(data?.createdAt).format('LT')}
- *FECHA DE SERVICIO*: ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('DD/MM/YYYY')} ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('LT')}     
- *CLIENTE*: ${
      capitalize(
        data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.lastName ||
          data?.membership?.customer?.lastName
      )
    }
- *TELÉFONO*: ${
      exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)
    }
- *PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
- *VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
- *AVERÍA*: ${capitalize(data?.fault?.description)}
- *COMENTARIO AVERÍA*: ${capitalize(
      data?.customerFailureDescription
        ? data?.customerFailureDescription
        : 'Ninguno'
    )}
- *ORIGEN*: ${
      capitalize(data?.originAddress) +
      ', ' +
      capitalize(data?.originCity?.name) +
      ', ' +
      capitalize(data?.originState?.name) +
      '. ' +
      capitalize(data?.originReferencePoint)
    }\n- *OPERADOR*: ${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }${
      data?.lastService?.code
        ? `\n- *CÓDIGO DE SERVICIO*: ${exists(data?.lastService?.code)}`
        : ''
    }
- *MOTORIZADO*: @${capitalize(
      provider?.contactName
    )}\n- *SERVICIO SOLICITADO*: ${capitalize(
      service?.product || data?.lastService?.product?.name
    )}`
  },

  messageToClient: (data, provider) => {
    return `¡Estimad@! Ya tienes un asistente vial asignado\nDatos del motorizado:
    \n\n*Nombre: @${capitalize(provider?.contactName)} * ${
      '\n*Tiempo de espera estimado:' + +' 25 ' + 'minutos*'
    }\n\nProcura mantenerte en el lugar indicado para que nuestro asistente pueda ubicar tu posición.`
  },

  yellowGroup: (data, provider, service) => {
    return `*SERVICIO DE TU/GRUERO${
      data?.particularCustomer?._id ? ' Particular' : ''
    }*
- *FECHA DE CREACIÓN*: ${moment
      .utc(data?.createdAt)
      .format('DD/MM/YYYY')} ${moment.utc(data?.createdAt).format('LT')}
- *FECHA DE SERVICIO*: ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('DD/MM/YYYY')} ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('LT')}     
- *PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
- *TELÉFONO*: ${
      exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)
    }
- *VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
- *AVERÍA*: ${capitalize(data?.fault?.description)}
- *COMENTARIO AVERÍA*: ${capitalize(
      data?.customerFailureDescription
        ? data?.customerFailureDescription
        : 'Ninguno'
    )}
- *ORIGEN*: ${
      capitalize(data?.originAddress) +
      ', ' +
      capitalize(data?.originCity?.name) +
      ', ' +
      capitalize(data?.originState?.name) +
      '. ' +
      capitalize(data?.originReferencePoint)
    }
${data?.destinyState?.name ? `- *DESTINO*: ` : ''}${
      capitalize(data?.destinyAddress) +
      ', ' +
      capitalize(data?.destinyCity?.name) +
      ', ' +
      capitalize(data?.destinyState?.name) +
      '. ' +
      capitalize(data?.destinyReferencePoint)
    }\n- *PROVEEDOR*: ${capitalize(
      data?.lastService?.provider?.contactName
    )}\n- *COSTO DE PROVEEDOR*: ${exists(
      data?.lastService?.price
    )}$ \n- *OPERADOR*: ${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }
${
  data?.membership?.beginDate
    ? `- *VIGENCIA*: ${exists(
        moment.utc(data?.membership?.beginDate).format('DD/MM/YYYY')
      )}-${exists(moment.utc(data?.membership?.endDate).format('DD/MM/YYYY'))}`
    : ''
}
${
  data?.membership?.vehicle?.city
    ? `- *DOMICILIO*: ${
        capitalize(data?.membership?.vehicle?.city) +
        ', ' +
        capitalize(data?.membership?.vehicle?.state)
      }`
    : ''
}
${
  data?.membership?.towServices
    ? `- *CANTIDAD DE CASOS*: ${data?.membership?.towServices?.length}`
    : ''
}
${
  `- *OPERADOR*: ` +
  capitalize(data?.user?.firstName) +
  ' ' +
  capitalize(data?.user?.lastName)
}
${
  data?.lastService?.code
    ? `- *CÓDIGO DE SERVICIO*: ${exists(data?.lastService?.code)}`
    : ''
}
${
  data?.membership?.code
    ? `- *CÓDIGO DE PRODUCTO*:${
        ' ' +
        exists(data?.membership?.code?.prefixCode) +
        '-' +
        exists(data?.membership?.saleChannel?.shortName) +
        '-' +
        exists(data?.membership?.membershipId)
      }`
    : ''
}\n- *SERVICIO SOLICITADO*: ${capitalize(
      service?.product || data?.lastService?.product?.name
    )}`
  },

  towBroadcastMessage: (data) => {
    return `*SERVICIO DE TU/GRUERO*
- *FECHA DE CREACIÓN*: ${moment
      .utc(data?.createdAt)
      .format('DD/MM/YYYY')} ${moment.utc(data?.createdAt).format('LT')}
- *FECHA DE SERVICIO*: ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('DD/MM/YYYY')} ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('LT')}     
- *AVERÍA*: ${capitalize(data?.fault?.description)}
- *COMENTARIO AVERÍA*: ${capitalize(
      data?.customerFailureDescription
        ? data?.customerFailureDescription
        : 'Ninguno'
    )}
- *VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
- *ORIGEN*: ${
      capitalize(data?.originAddress) +
      ', ' +
      capitalize(data?.originCity?.name) +
      ', ' +
      capitalize(data?.originState?.name) +
      '. ' +
      capitalize(data?.originReferencePoint)
    }${data?.destinyState?.name ? `\n- *DESTINO*: ` : ''}${
      capitalize(data?.destinyAddress) +
      ', ' +
      capitalize(data?.destinyCity?.name) +
      ', ' +
      capitalize(data?.destinyState?.name) +
      '. ' +
      capitalize(data?.destinyReferencePoint)
    }${data?.caseKm ? `\n- *DISTANCIA*: ` : ''}${
      exists(data?.caseKm) + ' Km'
    }\n\n*Interesados responder con el precio y el tiempo de espera*`
  },

  towMesage: (data) => {
    return `*SERVICIO DE TU/GRUERO*
- *FECHA DE CREACIÓN*: ${moment
      .utc(data?.createdAt)
      .format('DD/MM/YYYY')} ${moment.utc(data?.createdAt).format('LT')}
- *FECHA DE SERVICIO*: ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('DD/MM/YYYY')} ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('LT')}     
- *CLIENTE*: ${
      exists(
        data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
      ) +
      ' ' +
      exists(
        data?.particularCustomer?.lastName ||
          data?.membership?.customer?.lastName
      )
    }
- *TELÉFONO*: ${
      exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)
    }
- *PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
- *VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
- *AVERÍA*: ${capitalize(data?.fault?.description)}
- *COMENTARIO AVERÍA*: ${capitalize(
      data?.customerFailureDescription
        ? data?.customerFailureDescription
        : 'Ninguno'
    )}
- *ORIGEN*: ${
      capitalize(data?.originAddress) +
      ', ' +
      capitalize(data?.originCity?.name) +
      ', ' +
      capitalize(data?.originState?.name) +
      '. ' +
      capitalize(data?.originReferencePoint)
    }
${data?.destinyState?.name ? `- *DESTINO*: ` : ''}${
      capitalize(data?.destinyAddress) +
      ', ' +
      capitalize(data?.destinyCity?.name) +
      ', ' +
      capitalize(data?.destinyState?.name) +
      '. ' +
      capitalize(data?.destinyReferencePoint)
    }
${data?.caseKm ? `- *DISTANCIA*: ${exists(data?.caseKm)} Km` : ''}
- *OPERADOR*: ${
      capitalize(data?.user?.firstName) + ' ' + capitalize(data?.user?.lastName)
    }${
      data?.lastService?.code
        ? `\n- *CÓDIGO DE SERVICIO*: ${exists(data?.lastService?.code)}`
        : ''
    }${
      data?.lastService?.price
        ? `\n- *COSTO DE PROVEEDOR*: ${exists(data?.lastService?.price) + '$'}`
        : ''
    }\n\n- *Estimado Gruero por favor llamar al cliente e indicarle al mismo Marca, Modelo, Color de la grua que le asistirá.*
    \n\n- *SE SOLICITA POR FAVOR QUE AL TOMAR EL SERVICIO NOS ENVÍEN 3 FOTOS, AL SUBIRLO A LA GRÚA, SOBRE LA GRÚA Y AL BAJARLO DE LA GRÚA. RECUERDE QUE EL CÓDIGO DEL SERVICIO DEBE ESTAR REFLEJADO EN LA FACTURA.*
`
  },

  towServiceMessage: (data) => {
    return `*SERVICIO DE TU/GRUERO${
      data?.particularCustomer?._id ? ' Particular' : ''
    }*
- *FECHA DE CREACIÓN*: ${moment
      .utc(data?.createdAt)
      .format('DD/MM/YYYY')} ${moment.utc(data?.createdAt).format('LT')}
- *FECHA DE SERVICIO*: ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('DD/MM/YYYY')} ${moment
      .utc(data?.scheduleDate ? data?.scheduleDate : data?.createdAt)
      .format('LT')}     
- *CLIENTE*: ${
      exists(
        data?.particularCustomer?.firstName ||
          data?.membership?.customer?.firstName
      ) +
      ' ' +
      exists(
        data?.particularCustomer?.lastName ||
          data?.membership?.customer?.lastName
      )
    }
- *TELÉFONO*: ${
      exists(data?.contactNumberPrefix) + '-' + exists(data?.contactNumber)
    }
- *PLACA*: ${exists(
      data?.particularCustomer?.vehiclePlate || data?.membership?.vehicle?.plate
    )}
- *VEHICULO*: ${
      capitalize(
        data?.particularCustomer?.vehicleBrand?.brand ||
          data?.membership?.vehicle?.brand
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleModel?.model ||
          data?.membership?.vehicle?.model
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleYear || data?.membership?.vehicle?.year
      ) +
      ' ' +
      capitalize(
        data?.particularCustomer?.vehicleColor ||
          data?.membership?.vehicle?.color
      )
    }
- *AVERÍA*: ${capitalize(data?.fault?.description)}
- *COMENTARIO AVERÍA*: ${capitalize(
      data?.customerFailureDescription
        ? data?.customerFailureDescription
        : 'Ninguno'
    )}
- *ORIGEN*: ${
      capitalize(data?.originAddress) +
      ', ' +
      capitalize(data?.originCity?.name) +
      ', ' +
      capitalize(data?.originState?.name) +
      '. ' +
      capitalize(data?.originReferencePoint)
    }
${data?.destinyState?.name ? `- *DESTINO*: ` : ''}${
      capitalize(data?.destinyAddress) +
      ', ' +
      capitalize(data?.destinyCity?.name) +
      ', ' +
      capitalize(data?.destinyState?.name) +
      '. ' +
      capitalize(data?.destinyReferencePoint)
    }
${data?.caseKm ? `- *DISTANCIA*: ${exists(data?.caseKm)} Km ` : ''}
${
  data?.services?.length > 0 && data?.services[0]?.type === 'Asistencia vial'
    ? `- *VERIFICADO POR*: ${data?.services[0]?.provider?.contactName} `
    : ''
}${
      data?.services?.length > 0 && data?.services[0]?.type === 'Grua'
        ? `- *VERIFICADO POR*: Servicio de grúa directo`
        : ''
    }
${
  data?.lastService?.provider?.contactName
    ? `- *PROVEEDOR*: ${capitalize(data?.lastService?.provider?.contactName)}`
    : ''
}
${
  data?.lastService?.price
    ? `- *COSTO DE PROVEEDOR*: ${exists(data?.lastService?.price)}$`
    : ''
}${
      data?.membership?.customer?.homeCity
        ? `- *DOMICILIO*:  ${
            capitalize(data?.membership?.customer?.homeState) +
            capitalize(data?.membership?.customer?.homeCity) +
            capitalize(data?.membership?.customer?.homeMunicipality) +
            capitalize(data?.membership?.customer?.homeAddress)
          }`
        : ''
    }
${
  data?.membership?.beginDate
    ? `- *VIGENCIA*: ${exists(
        moment.utc(data?.membership?.beginDate).format('DD/MM/YYYY')
      )}-${exists(moment.utc(data?.membership?.endDate).format('DD/MM/YYYY'))}`
    : ''
}
${
  data?.membership?.vehicle?.city
    ? `- *DOMICILIO*: ${
        capitalize(data?.membership?.vehicle?.city) +
        ', ' +
        capitalize(data?.membership?.vehicle?.state)
      }`
    : ''
}
${
  data?.membership?.towServices
    ? `- *CANTIDAD DE CASOS*: ${data?.membership?.towServices?.length}`
    : ''
}
${
  `- *OPERADOR*: ` +
  capitalize(data?.user?.firstName) +
  ' ' +
  capitalize(data?.user?.lastName)
}${
      data?.lastService?.code
        ? `\n- *CÓDIGO DE SERVICIO*: ${exists(data?.lastService?.code)}`
        : ''
    }${
      data?.membership?.code
        ? `\n- *CÓDIGO DE PRODUCTO*:${
            ' ' +
            exists(data?.membership?.code?.prefixCode) +
            '-' +
            exists(data?.membership?.saleChannel?.shortName) +
            '-' +
            exists(data?.membership?.membershipId)
          }`
        : ''
    }`
  },

  messageToClient: (data, provider) => {
    return `¡Estimad@! Ya tienes un asistente vial asignado\nDatos del motorizado:
    \n\n*Nombre: @${capitalize(provider?.contactName)} * ${
      data?.estimatedTime
        ? '\n*Tiempo de espera estimado:' + ' 25 ' + 'minutos*'
        : ''
    }\n\nProcura mantenerte en el lugar indicado para que nuestro asistente pueda ubicar tu posición.`
  },
}
