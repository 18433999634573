import { useState, useEffect, useRef } from 'react';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons.jsx';
import { Header } from '../../../common/Header/Header.jsx';
import { ParticularCounterTable } from '../../../common/Tables/Tables.jsx';
import { ParticularCounterHeader } from '../../../../assets/helpers/tables.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { servicesService } from '../../../../services/servicesService.js';
import { providersPaymentsTypes } from '../../../../assets/helpers/options.js';
import {
  FilterDatePicker,
  SearchInput,
} from '../../../common/Forms/Input/Input.jsx';
import { filterParams } from '../../../../assets/helpers/funciones.js';
import moment from 'moment';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner.jsx';

import './ParticularServicesCounter.scss';

const itemsPerpage = [10, 25, 50, 100, 500, 1000, 2500, 5000];

export const FilterButton = props => {
  const { label, type, action } = props;

  if (label === type) {
    return <ActionButton label={label} action={() => action(label)} />;
  } else {
    return <AltButton label={label} action={() => action(label)} />;
  }
};

export const ParticularServicesCounter = () => {
  const tableRef = useRef(null);

  const [sinceDate, setSinceDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());

  const [particularCountersDataTable, setParticularCountersDataTable] =
    useState({
      limit: itemsPerpage[2],
      offset: 0, //Elementos que ya pasaron la pagina
      data: [],
      count: 0, //Cantidad de elementos
      page: 1,
      sinceDate: '',
      untilDate: '',
      isInternal: true,
      isLoading: true,
      type: providersPaymentsTypes?.PAYMENT_AV,
      provider: '',
      paymentStatus: '',
    });

  const handleSinceDateChange = e => {
    if (e > untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = e => {
    if (e < sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const handlePageChange = (event, value) => {
    setParticularCountersDataTable(prevState => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * particularCountersDataTable?.limit,
      };
    });
  };

  function getExportables(type) {
    setParticularCountersDataTable(prevState => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      dateFrom: moment.utc(sinceDate).format('YYYY-MM-DD'),
      dateTo: moment.utc(untilDate).format('YYYY-MM-DD'),
      // limit: particularCountersDataTable?.limit,
      // offset: particularCountersDataTable?.offset,
      name: particularCountersDataTable?.name,
    };

    servicesService
      .getServicesByOperator(filterParams(params))
      .then(response => {
        if (response.status === 200) {
          setParticularCountersDataTable(prevState => {
            return {
              ...prevState,
              data: response?.data,
              // count: response?.data?.count,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setParticularCountersDataTable(prevState => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch(() => {
        setParticularCountersDataTable(prevState => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  }

  const handleMultipleSelection = (value, name) => {
    let data = [...particularCountersDataTable?.data];

    for (let i = 0; i < data?.length; i++) {
      if (
        data[i]?.providerName === name &&
        data[i]?.providerPaymentStatus !== 'Pagado'
      ) {
        data[i].selected = value;
      }
    }
    setParticularCountersDataTable(prevState => {
      return {
        ...prevState,
        data: data,
      };
    });
  };

  const handleSearchChange = e => {
    const { value } = e?.target;
    setParticularCountersDataTable(prevState => {
      return {
        ...prevState,
        name: value,
        page: 1,
        offset: 0,
      };
    });
    if (!value) {
      window.location.reload();
    }
  };

  const selectPayment = (payment, index) => {
    let datos = [...particularCountersDataTable?.data];
    datos[index].selected = !datos[index].selected;

    let none = '';

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].selected) {
        none = payment?.status;
      }
    }

    setParticularCountersDataTable(prevState => {
      return {
        ...prevState,
        data: datos,
        selectedType: none,
      };
    });
  };

  useEffect(() => {
    getExportables();
  }, [
    particularCountersDataTable?.page,
    particularCountersDataTable?.offset,
    particularCountersDataTable?.limit,
    particularCountersDataTable?.type,
    particularCountersDataTable?.sinceDate,
    particularCountersDataTable?.untilDate,
    particularCountersDataTable?.provider,
    particularCountersDataTable?.paymentStatus,
    sinceDate,
    untilDate,
  ]);

  return (
    <>
      <LoadSpinner show={particularCountersDataTable.isLoading} />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">Comportamiento Operadores</h1>
        </div>
        <div className="breadcrumb-container">
          <div className="exportables-buttons-container">
            <div style={{ marginTop: '36px', marginRight: '12px' }}>
              <SearchInput
                placeholder={'Nombre'}
                width="260px"
                value={particularCountersDataTable?.description}
                onChange={handleSearchChange}
                searchAction={getExportables}
              />
            </div>
            <FilterDatePicker
              bootstrap={'col-8'}
              label={'Desde'}
              type={'date'}
              name={'sinceDate'}
              placeholder={''}
              onChange={handleSinceDateChange}
              value={sinceDate}
            />{' '}
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Hasta'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleUntilDateChange}
              value={untilDate}
            />
            {/* <div className='col-6 providers-payments-filter-container'>
              <div style={{ width: "24px" }}>
                <SelectPerPage
                  label={'Registros'}
                  options={itemsPerpage}
                  value={particularCountersDataTable?.limit}
                  onChange={selectitemsPerpage}
                />
              </div>
            </div> */}
          </div>
          <span></span>
        </div>

        {!particularCountersDataTable.isLoading && (
          <ParticularCounterTable
            header={ParticularCounterHeader}
            handlePageChange={handlePageChange}
            selectPayment={selectPayment}
            data={particularCountersDataTable}
            type={particularCountersDataTable?.type}
            tableRef={tableRef}
            handleMultipleSelection={handleMultipleSelection}
          />
        )}
      </div>
    </>
  );
};
