import { Header } from '../../../common/Header/Header.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  NewCraneProviderForm,
  NewInternalProviderForm,
  NewVehicularAssetForm,
} from '../../../common/Forms/Form';
import { statesService } from '../../../../services/statesService';
import { vehicleService } from '../../../../services/vehicleService';
import { providersService } from '../../../../services/providersService.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { useState } from 'react';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import { providersOptions } from '../../../../assets/helpers/options.js';
import './NewProvider.scss';
import { vehicularAssetsService } from '../../../../services/vehicularAssetsService.js';

export const NewProvider = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [statesOptions, setStatesOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.CreateProviderSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      providersService
        .createProvider(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Proveedor externo creado con éxito`, 'success');
            history.push('/providers/external');

            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const cancelEdition = () => {
    history.push('/providers/external');
  };

  useState(() => {
    getStates();
    setValue('type', providersOptions[1]?._id);
    setValue('isInternal', false);
    setValue('isActive', true);
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner label={'Enviando'} show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Nuevo Proveedor Externo</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <NewCraneProviderForm
            t={t}
            register={register}
            errors={errors}
            action={onSubmit}
            options={{ statesOptions: statesOptions }}
            cancel={cancelEdition}
          />
        </div>
      </div>
    </>
  );
};

export const NewInternalProvider = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [statesOptions, setStatesOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.NewInternalProviderSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      providersService
        .createProvider(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Proveedor interno creado con éxito`, 'success');
            history.push('/providers/internal');

            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const cancelEdition = () => {
    history.push('/providers/internal');
  };

  useState(() => {
    getStates();
    setValue('isInternal', true);
    setValue('isActive', true);
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner label={'Enviando'} show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Registrar Chofer</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <NewInternalProviderForm
            t={t}
            register={register}
            errors={errors}
            action={onSubmit}
            cancel={cancelEdition}
            options={{ statesOptions: statesOptions }}
          />
        </div>
      </div>
    </>
  );
};

export const NewVehicularAsset = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.CreateVehicularAssetSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {
    if (!isLoading) {
      setIsloading(true);
      vehicularAssetsService
        .createVehicularAsset(data)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Vehículo creado con éxito`, 'success');
            history.push('/providers/fleet');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getVehicleClasses = () => {
    vehicleService
      .getVehicleClasses(true)
      .then((response) => {
        if (response.status === 200) {
          setVehicleClassesOptions(response?.data?.classes);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands(watch('class'))
      .then((response) => {
        if (response.status === 200) {
          setBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleModels = () => {
    vehicleService
      .getVehicleModels(watch('brand'))
      .then((response) => {
        if (response.status === 200) {
          setModelOptions(response?.data?.models);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const cancelEdition = () => {
    history.push('/providers/fleet');
  };

  useState(() => {
    getVehicleClasses();
  }, []);

  return (
    <>
      <Header />
      <LoadSpinner label={'Enviando'} show={isLoading} />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Nuevo Vehículo</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <NewVehicularAssetForm
            t={t}
            register={register}
            errors={errors}
            action={onSubmit}
            options={{
              vehicleClassesOptions: vehicleClassesOptions,
              brandOptions: brandOptions,
              modelOptions: modelOptions,
            }}
            cancel={cancelEdition}
            control={control}
            watch={watch}
            onChange={{
              getVehicleBrands: getVehicleBrands,
              getVehicleModels: getVehicleModels,
            }}
          />
        </div>
      </div>
    </>
  );
};
