import { fixResponseJSON, filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const membershipService = {
  getMembership: async (searchParam) => {
    const response = await authInstance
      .get(`${url}/memberships/search?searchParam=${searchParam}&limit=10`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getMembershipById: async (membershipId, moduleType) => {
    const response = await authInstance
      .get(`${url}/memberships/${moduleType}/${membershipId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getLastServices: async (membershipId) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    }

    const response = await fetch(
      `${url}/services/getLatest?membership=${membershipId}`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getCancelReason: async (membershipType) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    }

    const response = await fetch(
      `${url}/cancellationReasons/search?type=${membershipType}&limit=1000`,
      myInit
    )
      .then(async (response) => {
        return fixResponseJSON(response)
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
