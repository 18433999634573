import plusZoom from '../../../images/plusZoom.svg';
import minusZoom from '../../../images/minusZoom.svg';
import closeIcon from '../../../images/close.svg';
import craneLogo from '../../../images/craneLogo.png';
import bikeServiceSelected from '../../../images/bikeServiceSelected.svg';
import { capitalize } from '../../../assets/helpers/options';
import './CraneCounter.scss';

const service = {
  AVSERVICE: { name: 'Asistente Vial', value: "'avServices'" },
  TOWSERVICES: { name: 'Grueros', value: "'towServices'" },
};

export const CraneCounterData = (props) => {
  const { data, calculateTotal } = props;

  return (
    <>
      {data?.map((c) => (
        <tr key={c?._id}>
          <td>{capitalize(c?.contactName)}</td>
          <td style={{ textAlign: 'center' }}>{c?.activeServices}</td>
          <td style={{ textAlign: 'center' }}>{c?.finishedServices}</td>
          <td style={{ textAlign: 'center', fontSize: '9px' }}>
            {c?.activeDestinyAddress}
          </td>
        </tr>
      ))}
      <tr>
        <td>
          <b>Total:</b>
        </td>
        <td style={{ textAlign: 'center' }}>
          <b>{calculateTotal(data, 'activeServices')}</b>
        </td>
        <td style={{ textAlign: 'center' }}>
          <b>{calculateTotal(data, 'finishedServices')}</b>
        </td>
        <td></td>
      </tr>
    </>
  );
};

export const CraneCounter = (props) => {
  const { showCraneCounterModal, openDocumentModal, zoomDocumentModal } = props;

  const calculateTotal = (counters, name) => {
    let total = 0;

    for (let i = 0; i < counters?.length; i++) {
      total = total + counters[i]?.[name];
    }
    return total;
  };

  if (!showCraneCounterModal?.data) {
    return null;
  }

  if (showCraneCounterModal?.show && showCraneCounterModal?.data) {
    return (
      <div
        style={{
          height: 460 * showCraneCounterModal?.zoom,
          width: 540 * showCraneCounterModal?.zoom,
        }}
        className="document-image-component"
      >
        <div className="document-image-component-header">
          <div>
            <img
              onClick={() => zoomDocumentModal(1.3)}
              src={plusZoom}
              alt="plusZoom"
            />
            <img
              onClick={() => zoomDocumentModal(1)}
              src={minusZoom}
              alt="minusZoom"
            />
          </div>
          <div>
            <img
              onClick={() => openDocumentModal('')}
              src={closeIcon}
              alt="closeIcon"
            />
          </div>
        </div>
        <div className="document-image-component-content">
          <h5 style={{ textAlign: 'center', width: '100%', margin: '6px' }}>
            Contador de Servicios de {showCraneCounterModal?.service}
          </h5>

          {showCraneCounterModal?.service ===
            service?.TOWSERVICES?.name && (
              <table style={{ width: "100%", textAlign: "center" }}>
                <tr >
                  <th style={{ fontSize: '14px' }}>Servicios Externos Activos</th>
                  <th style={{ fontSize: '14px' }}>Servicios Externos Finalizados</th>
                </tr>
                <tr>
                  <td>{showCraneCounterModal?.data?.activeExtTowServicesCount}</td>
                  <td>{showCraneCounterModal?.data?.finishedExtTowServicesCount}</td>
                </tr>
              </table>
            )}

          <div className="table-container">
            <table className="cranes-counter-table">
              <tr>
                <th>Gruero</th>
                <th style={{ width: '10%', textAlign: 'center' }}>
                  Servicios Activos
                </th>
                <th style={{ width: '10%', textAlign: 'center' }}>
                  Servicios Acumulados
                </th>{' '}
                <th style={{ width: '30%', textAlign: 'center' }}>
                  Última Dirección de Servicio
                </th>
              </tr>
              {showCraneCounterModal?.service ===
                service?.TOWSERVICES?.name && (
                  <CraneCounterData
                    data={showCraneCounterModal?.data['towServices']}
                    calculateTotal={calculateTotal}
                  />
                )}
              {showCraneCounterModal?.service === service?.AVSERVICE?.name && (
                <CraneCounterData
                  data={showCraneCounterModal?.data['avServices']}
                  calculateTotal={calculateTotal}
                />
              )}
            </table>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  } else
    return (
      <>
        <div className="document-image-crane-button">
          <img
            src={craneLogo}
            alt="craneLogo"
            onClick={() =>
              openDocumentModal(craneLogo, service?.TOWSERVICES?.name)
            }
          />
          <img
            src={bikeServiceSelected}
            alt="bikeServiceSelected"
            onClick={() =>
              openDocumentModal(bikeServiceSelected, service?.AVSERVICE?.name)
            }
          />
        </div>
      </>
    );
};
