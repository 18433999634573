import { Header } from '../../../common/Header/Header.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { NewVehicleForm } from '../../../common/Forms/Form.jsx';
import './Fleets.scss';

export const NewFleet = () => {
  let history = useHistory();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.CreateProviderSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => {});

  return (
    <>
      <Header />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Nuevo Vehículo</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <NewVehicleForm
            t={t}
            control={control}
            register={register}
            errors={errors}
            action={onSubmit}
          />
        </div>
      </div>
    </>
  );
};
