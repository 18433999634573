import React, { useState } from 'react';
import {
    FileDropzone
} from '../Dropzone/Dropzone';
import { ActionButton, AltButton } from '../Buttons/Buttons';
// import { MembershipImage } from './LandingComponents';
import { paymentMethods, cashBakOptions } from '../../../assets/helpers/options';
import { Input } from '../Forms/Input/Input';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import './LandingModals.scss';
import { notify } from '../../../assets/helpers/toast';

export const AccountsSelect = (props) => {
    const { methods, register, errors } = props;

    let options = [];
    options = methods;

    for (let i = 0; i < methods?.length; i++) {
        if (
            ![
                paymentMethods.CASH_BS,
                paymentMethods.CASH_USD,
                paymentMethods.TRANSFERENCIA_BANESCO,
                paymentMethods.TRANSFERENCIA_MERCANTIL,
            ].includes(methods[i]?.name)
        ) {
            return null;
        }
    }
    return (
        <Input
            bootstrap={'col-12 col-md-6'}
            label={'Opciones de pago'}
            type={'select'}
            name={'paymentOption'}
            register={register}
            placeholder={''}
            options={options}
            errors={errors?.paymentOption?.message}
            disabled={false}
        />
    );
};

export const RefComponent = (props) => {
    const { methods, paymentModal, payment, handleReferenceChange } = props;

    for (let i = 0; i < methods?.length; i++) {
        if (
            [paymentMethods.CASH_BS, paymentMethods.CASH_USD].includes(
                methods[i]?.name
            )
        ) {
            return null;
        }
    }
    return (
        <>
            <div className={`input-component-container col-12 col-md-6`}>
                <label style={{ textAlign: 'center' }}>{`Referencia de Pago ${paymentModal?.paymentNumber > 1 ? paymentModal?.paymentNumber : ''
                    }`}</label>
                <div>
                    <div className="input-sublabel-container">
                        <input
                            name={`reference`}
                            value={payment?.reference}
                            onChange={handleReferenceChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};


export const PaymentMethodDetails = (props) => {
    const { method, methods } = props;
    const filteredMethod = methods?.filter(
        (m) =>
            m?._id === method?._id &&
            ![
                paymentMethods.CASH_BS,
                paymentMethods.CASH_USD,
                paymentMethods.TRANSFERENCIA_BANESCO,
                paymentMethods.TRANSFERENCIA_MERCANTIL,
            ]?.includes(m?.name)
    );

    if (filteredMethod?.length > 0) {
        return (
            <p style={{ margin: '24px 12px 24px 12px' }}>
                {method?.details?.account && (
                    <>
                        Cuenta: <b>{method?.details?.account}</b> <br />
                    </>
                )}
                {method?.details?.accountNumber && (
                    <>
                        Numero de Cuenta:: <b>{method?.details?.accountNumber}</b> <br />
                    </>
                )}
                {method?.details?.bank && (
                    <>
                        Banco: <b>{method?.details?.bank}</b> <br />
                    </>
                )}
                {method?.details?.phone && (
                    <>
                        Telefono: <b>{method?.details?.phone}</b> <br />
                    </>
                )}
                {method?.details?.rif && (
                    <>
                        RIF: <b>{method?.details?.rif}</b> <br />
                    </>
                )}
            </p>
        );
    } else return null;
};

export const AmounComponent = (props) => {
    const { paymentModal, amount, amountBsS } = props;

    let bs = false;
    let dollars = false;

    for (let i = 0; i < paymentModal?.methods?.length; i++) {
        if (paymentModal?.methods[i]?.currency === 'BsS') {
            bs = true;
        }
        if (paymentModal?.methods[i]?.currency === 'USD') {
            dollars = true;
        }
    }

    if (!amountBsS) {
        return null
    }
    return (
        <div className="ammount-container">
            <h5>Monto</h5>
            <h6>
                {bs && (
                    <>
                        {amountBsS?.toFixed(2) + ' '}
                        {'Bs' + '     '}
                    </>
                )}
                {dollars && (
                    <>
                        {amount?.toFixed(2)} {'USD'}
                    </>
                )}
            </h6>
        </div>
    );
};

// export const MethodLogoContainer = (props) => {
//     const { methods } = props;
//     if (methods?.length > 0) {
//         return (
//             <div className="payment-method-logos-container">
//                 {methods?.map((m, i) => (
//                     <MethodLogo key={m?.name + i} name={m?.name} />
//                 ))}
//             </div>
//         );
//     } else return null;
// };


export const IGTF = (props) => {
    const { methods } = props;

    let isDollar = false;

    for (let i = 0; i < methods.length; i++) {
        if (methods[i].currency === 'USD') {
            isDollar = true;
        }
    }

    if (!isDollar) {
        return null;
    }

    return (
        <p className="igtf-message-container">
            Este monto incluye el <b>IGTF (3%)</b> por utilizar un método de pago de
            une monede distinta a la de curso legal en Venezuela. Para más
            información, consulte la <b>Gaceta Oficial N°6.687</b>
        </p>
    );
};

// export const NewPaymentModal = (props) => {
//     const {
//         getRootProps,
//         getInputProps,
//         cancelPayment,
//         paymentImage,
//         removeImage,
//         acceptPayment,
//         watch,
//         dollarRate,
//         register,
//         errors,
//         payment,
//         handleReferenceChange,
//     } = props;

//     return (
//         <Modal
//             size="lg"
//             centered
//             show={payment?.isOpen}
//             onHide={() => cancelPayment(payment?.paymentNumber)}
//         >
//             <Modal.Header closeButton className="modal-header"></Modal.Header>
//             <Modal.Body className="modal-body">
//                 <MembershipImage
//                     membership={{ code: payment?.selectedMembership?.code }}
//                 />
//                 <div className="landing-payment-modal-header">
//                     <AmounComponent
//                         selectedMembership={payment?.selectedMembership}
//                         paymentModal={payment}
//                         dollarRate={dollarRate}
//                         amount={payment?.amount}
//                         amountBsS={payment?.amountBsS}
//                     />

//                     <MethodLogoContainer methods={payment?.methods} />
//                 </div>
//                 <div className="landing-payment-modal-body">
//                     <h6 className="landing-payment-modal-title">Datos de Pago</h6>
//                     {payment?.methods?.map((p) => (
//                         <PaymentMethodDetails
//                             key={p?.details?.account}
//                             method={p}
//                             paymentOption={watch('paymentOption')}
//                             methods={payment?.methods}
//                         />
//                     ))}
//                     <div
//                         style={{ width: '100%' }}
//                         className="row landing-payment-modals-form"
//                     >
//                         <AccountsSelect
//                             register={register}
//                             errors={errors}
//                             methods={payment?.methods}
//                         />

//                         <AmountCashReceived
//                             paymentModal={payment}
//                             register={register}
//                             errors={errors}
//                             watch={watch}
//                             methods={payment?.methods}
//                             payment={payment}
//                         />

//                         <RefComponent
//                             paymentModal={payment}
//                             register={register}
//                             errors={errors}
//                             methods={payment?.methods}
//                             payment={payment}
//                             handleReferenceChange={handleReferenceChange}
//                         />
//                     </div>
//                     {![paymentMethods?.PUNTO_DE_VENTA, paymentMethods?.TDC]?.includes(
//                         payment?.methods[0]?.name
//                     ) && (
//                             <div className="buttons-center-container">
//                                 {paymentImage ? (
//                                     <FileComponent
//                                         file={paymentImage}
//                                         remove={() => removeImage(payment?.paymentNumber)}
//                                     />
//                                 ) : (
//                                     <SalesDropzone
//                                         instructions={'Adjuntar Comprobante *'}
//                                         maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
//                                         getRootProps={getRootProps}
//                                         getInputProps={getInputProps}
//                                         message="Arrastrar o buscar Documento"
//                                     />
//                                 )}
//                             </div>
//                         )}
//                 </div>
//             </Modal.Body>
//             <div className="buttons-center-container">
//                 <AltButton
//                     label={`Cancelar `}
//                     action={() => cancelPayment(payment?.paymentNumber)}
//                 />
//                 <ActionButton label={`Pagar `} action={() => acceptPayment()} />
//             </div>
//             <Modal.Footer className="modal-footer"></Modal.Footer>
//         </Modal>
//     );
// };


export const AmountCashReceived = (props) => {

    const { methods, register, errors, } = props;

    for (let i = 0; i < methods?.length; i++) {
        if (
            ![
                paymentMethods.CASH_BS,
                paymentMethods.CASH_USD,
                paymentMethods.TRANSFERENCIA_BANESCO,
                paymentMethods.TRANSFERENCIA_MERCANTIL,
            ].includes(methods[i]?.name)
        ) {
            return null;
        }
    }

    return (
        <Input
            bootstrap={'col-12 col-md-6'}
            label={'Monto Recibido $'}
            type={'number'}
            name={'paid'}
            register={register}
            placeholder={''}
            errors={errors?.paid?.message}
            disabled={false}
        />
    )
}



// export const StripeModal = (props) => {
//     const {
//         stripeModal,
//         handleStipeModal,
//         clientSecret,
//         successfulStripe,
//     } = props;

//     const publishableKey = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

//     return (
//         <Modal
//             size="lg"
//             centered
//             show={stripeModal?.isOpen}
//             onHide={() => handleStipeModal('', 0, stripeModal?.paymentNumber)}
//         >
//             <Modal.Header closeButton className="modal-header"></Modal.Header>
//             <Modal.Body className="modal-body">
//                 <MembershipImage
//                     membership={{ code: stripeModal?.selectedMembership?.code }}
//                 />
//                 <div className="landing-payment-modal-header">
//                     <AmounComponent
//                         selectedMembership={stripeModal?.selectedMembership}
//                         paymentModal={stripeModal}
//                         amount={stripeModal?.amount}
//                     />

//                     {/* <MethodLogoContainer methods={paymentModal?.methods} /> */}
//                 </div>
//                 <div className="landing-payment-modal-body">
//                     <h6 className="landing-payment-modal-title">Datos de Pago</h6>

//                     {clientSecret && publishableKey && (
//                         <Elements stripe={publishableKey} options={{ clientSecret }}>
//                             <CheckoutForm
//                                 stripeModal={stripeModal}
//                                 handleStipeModal={handleStipeModal}
//                                 successfulStripe={successfulStripe}
//                             />
//                         </Elements>
//                     )}
//                 </div>
//             </Modal.Body>

//             <Modal.Footer className="modal-footer"></Modal.Footer>
//         </Modal>
//     );
// };

export const CheckoutForm = (props) => {
    const { stripeModal, handleStipeModal, successfulStripe } = props;
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/completion`,
            },
            redirect: 'if_required',
        });

        if (error?.type === 'card_error' || error?.type === 'validation_error') {
            setMessage(error.message);
        }
        if (paymentIntent?.status === 'succeeded') {
            notify('Su pago ha sido registrado con éxito', 'success');
            successfulStripe(stripeModal.paymentNumber);
        } else {
            setMessage('An unexpected error occured.');
        }

        setIsProcessing(false);
    };

    return (
        <form id="payment-form">
            <PaymentElement id="payment-element" />
            {message && <div id="payment-message">{message}</div>}

            {!isProcessing && (
                <div
                    style={{ margin: '24px 0px' }}
                    className="buttons-center-container"
                >
                    <AltButton
                        label={`Cancelar `}
                        action={() => handleStipeModal('', 0, stripeModal.paymentNumber)}
                    />

                    {isProcessing || !stripe || !elements ? null : (
                        <ActionButton label={`Pagar `} action={handleSubmit} />
                    )}
                </div>
            )}
        </form>
    );
};


export const CashBackComponent = (props) => {

    const { register, errors, watch, options, onChange, handleDrop, cashBackImage, removeFile, errorsDropzone } = props;
    const filterMethods = options?.paymemtMethodOptions?.filter((m) => ![paymentMethods?.CASHEA, paymentMethods?.RESERVE]?.includes(m?.name))

    if (watch("paid") && watch("paidBsS")) {
        return (
            <>
                <div className="col-12">
                    <h5 style={{ margin: '12px 0px' }}>
                        Vuelto
                    </h5>
                </div>
                <Input
                    bootstrap={'col-3'}
                    label={'Monto total Recibido USD $ *'}
                    type={'number'}
                    name={'paid'}
                    register={register}
                    placeholder={''}
                    errors={errors?.paid?.message}
                    disabled={true}
                />
                <Input
                    bootstrap={'col-3'}
                    label={'Monto total Recibido BsS*'}
                    type={'number'}
                    name={'paidBsS'}
                    register={register}
                    placeholder={''}
                    errors={errors?.paidBsS?.message}
                    disabled={true}
                />
                <Input
                    bootstrap={'col-3'}
                    label={'Vuelto a entregar USD $ *'}
                    type={'number'}
                    name={'amountReturned'}
                    register={register}
                    options={options?.paymemtMethodOptions}
                    placeholder={''}
                    errors={errors?.amountReturned?.message}
                    disabled={true}
                />
                <Input
                    bootstrap={'col-3'}
                    label={'Vuelto a entregar BsS $ *'}
                    type={'number'}
                    name={'amountReturnedBsS'}
                    register={register}
                    options={options?.paymemtMethodOptions}
                    placeholder={''}
                    errors={errors?.amountReturnedBsS?.message}
                    disabled={true}
                />
                <Input
                    bootstrap={'col-4'}
                    label={'Cantidad de métodos de vuelto *'}
                    type={'select'}
                    name={'cashbackMethodsQuantity'}
                    register={register}
                    options={cashBakOptions}
                    placeholder={''}
                    errors={errors?.cashbackMethodsQuantity?.message}
                    onChange={() => onChange?.handleCashbackMethodsQuantity()}
                // disabled={true}
                />
                <div className="col-md-8"></div>
                {watch('cashbackMethodsQuantity') > 0 &&
                    <CashBackInput
                        index={1}
                        register={register}
                        errors={errors}
                        options={{ filterMethods }}
                        onChange={onChange}
                        handleDrop={handleDrop}
                        file={cashBackImage[`image${1}`]}
                        removeFile={removeFile}
                        watch={watch}
                        errorsDropzone={errorsDropzone}
                    />
                }
                {watch('cashbackMethodsQuantity') > 1 &&
                    <CashBackInput
                        index={2}
                        register={register}
                        errors={errors}
                        options={{ filterMethods }}
                        onChange={onChange}
                        handleDrop={handleDrop}
                        file={cashBackImage[`image${2}`]}
                        removeFile={removeFile}
                        watch={watch}
                        errorsDropzone={errorsDropzone}
                    />
                }
                {watch('cashbackMethodsQuantity') > 2 &&
                    <CashBackInput
                        index={3}
                        register={register}
                        errors={errors}
                        options={{ filterMethods }}
                        onChange={onChange}
                        handleDrop={handleDrop}
                        file={cashBackImage[`image${3}`]}
                        removeFile={removeFile}
                        watch={watch}
                        errorsDropzone={errorsDropzone}
                    />
                }
            </>
        )
    }
    else return null
}

export const CashBackInput = (props) => {
    const { index, register, errors, options, onChange, file, removeFile, handleDrop, watch, errorsDropzone } = props

    return (
        <>
            <Input
                bootstrap={'col-3'}
                label={`Método de vuelto ${index}*`}
                type={'select'}
                name={`returnedMethod${index}`}
                register={register}
                options={options?.filterMethods}
                placeholder={''}
                errors={errors[`returnedMethod${index}`]?.message}
            />
            <Input
                bootstrap={'col-3'}
                label={`Monto USD Vuelto ${index} *`}
                type={'number'}
                name={`returnedAmount${index}`}
                register={register}
                placeholder={''}
                errors={errors[`returnedAmount${index}`]?.message}
                onChange={() => onChange?.handleCashbackBs(index)}
                disabled={parseFloat(watch("cashbackMethodsQuantity")) === index}
                min={"0"}
            />
            <Input
                bootstrap={'col-3'}
                label={`Monto Bs Vuelto ${index} *`}
                type={'number'}
                name={`returnedAmountBsS${index}`}
                register={register}
                placeholder={''}
                errors={errors[`returnedAmountBsS${index}`]?.message}
                disabled={true}
                min={"0"}
            />
            <Input
                bootstrap={'col-3'}
                label={`Referencia Vuelto ${index} *`}
                type={'text'}
                name={`returnedReference${index}`}
                register={register}
                placeholder={''}
                errors={errors[`returnedReference${index}`]?.message}
            />
            <div className="col-12">

                <FileDropzone
                    handleDrop={handleDrop}
                    index={index}
                    file={file}
                    removeFile={removeFile}
                    error={errorsDropzone?.[`error${index}`]}
                />
            </div>
        </>
    )
}