import { useEffect, useState } from 'react';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { ActionButton } from '../Buttons/Buttons';
import './Map.scss';

const center = { lat: 10.4983, lng: -66.8568 };

export const Map = (props) => {
  const {
    origin,
    setOrigin,
    destiny,
    setDestiny,
    handleClose,
    route,
    setRoute,
  } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const handleOriginChange = (e) => {
    const { value } = e.target;
    setOrigin(value);
  };
  const handleDestinyChange = (e) => {
    const { value } = e.target;
    setDestiny(value);
  };

  const aver = () => {
    // eslint-disable-next-line no-undef
    var geocoder = new google.maps.Geocoder();
    var address = 'Petare, Caracas, Miranda, Venezuela';

    geocoder.geocode({ address: address }, function (results, status) {
      // eslint-disable-next-line no-undef
      if (status == google.maps.GeocoderStatus.OK) {
        var latitude = results[0].geometry.location.lat();
        var longitude = results[0].geometry.location.lng();
        alert(latitude);
      }
    });
  };

  //   Function that get lat and lng from string address
  const addressToGeoCode = (address) => {
    // eslint-disable-next-line no-undef
    var geocoder = new google.maps.Geocoder();
    return geocoder.geocode({ address: address }).then((response) => {
      return {
        lat: response?.results[0].geometry.location.lat(),
        lng: response?.results[0].geometry.location.lng(),
        address: address,
      };
    });
  };

  // Function that get String address from Latitud and longitud
  const geocodeLatLng = (latlng) => {
    // eslint-disable-next-line no-undef
    var geocoder = new google.maps.Geocoder();
    return geocoder
      .geocode({ location: latlng })
      .then((response) => {
        return response?.results[1]?.formatted_address;
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e));
  };

  async function calculateRoute() {
    // if (origin === '' || destiny === '') {
    //   return;
    // }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      // eslint-disable-next-line no-undef
      origin: new google.maps.LatLng(origin?.lat, origin?.lng),
      // eslint-disable-next-line no-undef
      destination: new google.maps.LatLng(destiny?.lat, destiny?.lng),
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });

    // Colocar una alerta de que no se puede determinar una ruta
    setDirectionsResponse(results);
    setRoute({
      time: (results.routes[0].legs[0].duration.value / 60).toFixed(), //Valor de metros a Km
      distance: (results.routes[0].legs[0].distance.value / 1000).toFixed(2), // Valor de segundos a minutos
    });
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setRoute({
      time: '',
      distance: '',
    });
  }

  const onMarkerDragEndOrigin = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    geocodeLatLng({ lat: lat, lng: lng }).then((r) => {
      setOrigin({
        lat: lat,
        lng: lng,
        address: r,
      });
    });
  };

  const onMarkerDragEndDestiny = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    geocodeLatLng({ lat: lat, lng: lng }).then((r) => {
      setDestiny({
        lat: lat,
        lng: lng,
        address: r,
      });
    });
  };

  useEffect(() => {
    addressToGeoCode(origin?.address).then((r) => {
      setOrigin(r);
    });
    addressToGeoCode(destiny?.address).then((r) => {
      setDestiny(r);
    });
  }, []);
  if (!isLoaded) {
    return null;
  }

  // const reTrazeRoute = () => {
  //   handleClose(false);
  //   handleClose(true);
  // };

  return (
    <>
      {/* <Box position="absolute" left={0} top={0} h="100%" w="100%"> */}
      {/* Google Map Box */}
      <GoogleMap
        center={{
          lat: (origin?.lat + destiny?.lat) / 2,
          lng: (origin?.lng + destiny?.lng) / 2,
        }}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '500px' }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
        {!directionsResponse && (
          <>
            <Marker
              position={{ lat: origin?.lat, lng: origin?.lng }}
              draggable={true}
              onDragEnd={(coord) => onMarkerDragEndOrigin(coord)}
              label={'A'}
            />
            <Marker
              position={{ lat: destiny?.lat, lng: destiny?.lng }}
              draggable={true}
              onDragEnd={(coord) => onMarkerDragEndDestiny(coord)}
              label={'B'}
            />
          </>
        )}
        {/* <Marker position={center} draggable={true} /> */}
        {directionsResponse && (
          <DirectionsRenderer
            directions={directionsResponse}
            draggable={true}
            suppressMarkers={true}
            markerOptions={{ icon: '' }}
          />
        )}
      </GoogleMap>
      {/* </Box> */}
      <div className="row">
        <div className="col-6">
          <div className="input-map-container">
            <h6>Origen</h6>
            <div className="markpoint-legend-conteiner">
              <div className="markpoint-legend">
                <h6>A</h6>
              </div>
              <Autocomplete>
                <input
                  className="input-map"
                  type="text"
                  placeholder="Origen"
                  //   ref={originRef}
                  onChange={handleOriginChange}
                  value={origin?.address}
                  disabled={true}
                />
              </Autocomplete>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="input-map-container">
            <h6>Destino</h6>
            <div className="markpoint-legend-conteiner">
              <div className="markpoint-legend">
                <h6>B</h6>
              </div>
              <Autocomplete>
                <input
                  className="input-map"
                  type="text"
                  placeholder="Destino"
                  //   ref={destiantionRef}
                  onChange={handleDestinyChange}
                  value={destiny?.address}
                  disabled={true}
                />
              </Autocomplete>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '36px' }} className="buttons-center-container">
        {route?.distance && (
          <>
            <h4 style={{ marginRight: '36px' }}>
              Distancia: {route?.distance} Km
            </h4>
            <h4>Duración: {route?.time} min </h4>
          </>
        )}
      </div>

      <div style={{ marginTop: '24px' }} className="buttons-center-container">
        {!route?.distance && (
          <ActionButton action={calculateRoute} label={'Calcular Distancia'} />
        )}

        {route?.distance && (
          <>
            {/* <AltButton action={() => reTrazeRoute()} label={'Modificar Ruta'} /> */}
            <ActionButton action={() => handleClose(false)} label={'Aceptar'} />
          </>
        )}
      </div>
    </>
  );
};
