import { useState } from 'react';
import { FilterButton } from '../../../common/Buttons/Buttons';
import { Header } from '../../../common/Header/Header.jsx';
import craneStats from '../../../../images/craneStats.svg';
import operatorStats from '../../../../images/operatorStats.svg';
import financeStats from '../../../../images/financeStats.svg';
import craneIndicator from '../../../../images/craneIndicator.svg';
import paymentIndicator from '../../../../images/paymentIndicator.svg';
import facturationIndicator from '../../../../images/facturationIndicator.svg';
import bikeIndicator from '../../../../images/bikeIndicator.svg';
import craneCostIndicator from '../../../../images/craneCostIndicator.svg';
import savingIndicator from '../../../../images/savingIndicator.svg';
import { IndicatorsCard } from '../../../common/Cards/Cards';
import auth from '../../../../services/authService';
import { operationsRoles } from '../../../../assets/helpers/roles';

import './Indicators.scss';

export const Indicators = () => {
  let filterButtons = [
    { name: 'Servicios', value: 'services', image: craneStats },
    { name: 'Operaciones', value: 'operations', image: operatorStats },
    { name: 'Finanzas', value: 'finance', image: financeStats },
  ];

  // Los Supervisores no pueden ver los indicadores de finanzas
  if (auth.getUserType() === operationsRoles.operationsSupervisor) {
    filterButtons = filterButtons.filter((f) => f.name !== 'Finanzas');
  }

  const [selected, setSelected] = useState(filterButtons[0].value);

  const selectButton = (value) => {
    setSelected(value);
  };

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">Indicadores</h1>
        <div className="filter-button-container">
          {filterButtons?.map((f) => (
            <FilterButton
              key={f.name}
              button={f}
              selected={selected}
              selectButton={selectButton}
            />
          ))}
        </div>
        <div className="gray-line"></div>
        <br />
        <div className="row">
          <div className="col-12 col-md-4">
            <IndicatorsCard
              src={craneIndicator}
              value={'$315'}
              label={'Monto facturado por servicio de grúa'}
            />
          </div>
          <div className="col-12 col-md-4">
            <IndicatorsCard
              src={paymentIndicator}
              value={'$500'}
              label={'Pago a proveedores'}
            />
          </div>
          <div className="col-12 col-md-4">
            <IndicatorsCard
              src={bikeIndicator}
              value={'$255'}
              label={'Pago a motorizados'}
            />
          </div>
          <div className="col-12 col-md-4">
            <IndicatorsCard
              src={facturationIndicator}
              value={'$315'}
              label={'Monto facturado por servicio de AV'}
            />
          </div>
          <div className="col-12 col-md-4">
            <IndicatorsCard
              src={craneCostIndicator}
              value={'$45'}
              label={'Costo promedio de grúa'}
            />
          </div>
          <div className="col-12 col-md-4">
            <IndicatorsCard
              src={savingIndicator}
              value={'$158'}
              label={'Monto ahorro por servicio de AV'}
            />
          </div>
        </div>
      </div>
    </>
  );
};
