import { filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const vehicleService = {
  getVehicleBrands: async (vehicleClass) => {
    const response = await authInstance
      .get(
        `${url}/vehicles/getBrands?description=&limit=100000&offset=0${
          vehicleClass ? `&class=${vehicleClass}` : ''
        }`
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getVehicleModels: async (brandId) => {
    const response = await authInstance
      .get(`${url}/vehicles/getModels?brandId=${brandId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getVehicleClasses: async (fleet) => {
    const response = await authInstance
      .get(`${url}/vehicles/getClasses/?fleet=${fleet}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
