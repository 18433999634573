import { useState, useEffect, useRef } from 'react';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons.jsx';
import { Header } from '../../../common/Header/Header.jsx';
import { SelectPerPage } from '../../../common/Forms/Input/Input.jsx';
import {
  ProvidersPaymentTable,
} from '../../../common/Tables/Tables.jsx';
import {
  ProvidersPaymentHeader,
} from '../../../../assets/helpers/tables.js';
import { notify } from '../../../../assets/helpers/toast.js';
import { caseService } from '../../../../services/caseService.js';
import { providersService } from '../../../../services/providersService.js';
import { productService } from '../../../../services/productService';
import { rateService } from '../../../../services/rateService';
import {
  providersPaymentsTypes,
} from '../../../../assets/helpers/options.js';
import { FilterDatePicker, Select } from '../../../common/Forms/Input/Input.jsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas.js';
import { ProdiversPaymentModal } from "../../../common/Modals/Modals.jsx"
import {
  exportDataToExcel,
  capitalize,
  exists,
  rename,
  printTime,
  serviceName,
  filterParams
} from '../../../../assets/helpers/funciones.js';
import { enumStatus, getProvidersEnum, paymentStatusOptions, paymentMethods as paymentMethodsEnum, IVA, caseType } from '../../../../assets/helpers/options.js';
import moment from 'moment';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner.jsx';

import './ProvidersPayment.scss';

const itemsPerpage = [10, 25, 50, 100, 500, 1000, 2500, 5000];
const paymentMethodsQuantity = [
  { _id: "1", name: "1" },
  { _id: "2", name: "2" },
]

export const FilterButton = (props) => {
  const { label, type, action } = props;

  if (label === type) {
    return <ActionButton label={label} action={() => action(label)} />;
  } else {
    return <AltButton label={label} action={() => action(label)} />;
  }
};


export const ProvidersPayment = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const [dollarRate, setDollarRate] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsloading] = useState(false);


  const {
    watch,
  } = useForm({
    resolver: yupResolver(schemas.filterExportableSchema(t)),
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
    watch: watch2,
    setValue: setValue2
  } = useForm({
    resolver: yupResolver(schemas.providerPaymentSchema(t)),
  });


  const [sinceDate, setSinceDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [providersOptions, setProvidersOptions] = useState({
    type: getProvidersEnum?.ROAD_ATTENDANT,
    isInternal: true,
  });

  const [providersPaymentModal, setProvidersPaymentModal] = useState({
    isOpen: false,
    selected: [],
  });




  const [exportablesDataTable, setExportablesDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isInternal: true,
    isLoading: true,
    type: providersPaymentsTypes?.PAYMENT_AV,
    provider: "",
    paymentStatus: "",
  });



  const validateBillPaymentAmount = (data) => {
    let totalAmount = 0

    if (typeof parseFloat(data?.amount1) === 'number' && !isNaN(parseFloat(data?.amount1))) {
      totalAmount += parseFloat(data?.amount1)
    }

    if (typeof parseFloat(data?.amount2) === 'number' && !isNaN(parseFloat(data?.amount2))) {
      totalAmount += parseFloat(data?.amount2)
    }
    if (data?.billNumber && totalAmount?.toFixed(2) !== parseFloat((providersPaymentModal?.totalAmount * (100 + IVA) / 100)?.toFixed(2))) {
      notify("b La suma de los montos no coincide con el monto total a pagar", "info")
      return false
    }

    if (!data?.billNumber && parseFloat(totalAmount?.toFixed(2)) !== parseFloat((providersPaymentModal?.totalAmount)?.toFixed(2))) {
      notify("A La suma de los montos no coincide con el monto total a pagar", "info")
      return false
    }
    else return true
  }

  const handleAmountChange = (amountIndex) => {
    setValue2(`amountBsS${amountIndex}`, (watch2(`amount${amountIndex}`) * dollarRate).toFixed(2))

    if (amountIndex == 1) {
      setValue2(`amount2`, (providersPaymentModal?.totalAmount - parseFloat(watch2(`amount1`)))?.toFixed(2))
      setValue2(`amountBsS2`, (watch2(`amount${amountIndex}`) * dollarRate).toFixed(2))
    }
  }

  const handleMethodsChange = () => {
    setValue2(`amount2`, 0)
    setValue2(`amountBsS2`, 0)
  }


  const createProviderPayment = handleSubmit2(async (data) => {

    if (!validateBillPaymentAmount(data)) {
      return null
    }
    let services = []
    let paymentMethods = []

    for (let i = 0; i < data.providerMethods; i++) {

      if (data[`method${i + 1}`]) {
        paymentMethods?.push(
          data[`method${i + 1}`]
        )
      }
    }

    for (let i = 0; i < providersPaymentModal?.data?.length; i++) {
      services.push({
        _id: providersPaymentModal?.data[i]?._id,
        code: providersPaymentModal?.data[i]?.code,
        amount: providersPaymentModal?.data[i]?.providerPay,
        amountBsS: providersPaymentModal?.data[i]?.providerPay * dollarRate,
      })
    }

    let dataToPost = {
      observation: data?.observation,
      billNumber: data?.billNumber,
      services: services,
      providerId: providersPaymentModal?.data[0]?.providerId,
      paymentMethod: paymentMethods
    };


    if (!isLoading) {
      setIsloading(true);
      providersService
        .payProviders(filterParams(dataToPost))
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Pago realizado`, 'success');
            getExportables();
            handlePaymentModal();
            reset2();
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  });

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };



  const selectType = (type) => {

    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        provider: "",
      };
    });

    if (type === providersPaymentsTypes?.PAYMENT_AV) {

      setProvidersOptions((prevState) => {
        return {
          ...prevState,
          type: getProvidersEnum?.ROAD_ATTENDANT,
          isInternal: true,
        };
      });
    }
    if (type === providersPaymentsTypes?.PAYMETN_INTERNAL_TOW) {
      setProvidersOptions((prevState) => {
        return {
          ...prevState,
          type: getProvidersEnum?.CRANE,
          isInternal: true,
        };
      });

    }
    if (type === providersPaymentsTypes?.PAYMENT_EXTERNAL_TOW) {
      setProvidersOptions((prevState) => {
        return {
          ...prevState,
          type: getProvidersEnum?.CRANE,
          isInternal: false,
        };
      });

    }

    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        type: type,
      };
    });
  };

  const handleSinceDateChange = (e) => {
    if (e > untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const selectitemsPerpage = (event) => {
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * exportablesDataTable?.limit,
      };
    });
  };


  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  function getExportables(type) {
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      sinceDate: moment.utc(sinceDate).format('YYYY-MM-DD'),
      untilDate: moment.utc(untilDate).format('YYYY-MM-DD'),
      limit: exportablesDataTable?.limit,
      offset: exportablesDataTable?.offset,
      type: type ? type : exportablesDataTable?.type,
      provider: exportablesDataTable?.provider,
      paymentStatus: exportablesDataTable?.paymentStatus,
    };

    caseService
      .getExportables(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setExportablesDataTable((prevState) => {
            let data = [...exportablesDataTable?.data];
            if ([
              providersPaymentsTypes?.PAYMETN_INTERNAL_TOW,
              providersPaymentsTypes?.PAYMENT_AV,
              providersPaymentsTypes?.PAYMENT_EXTERNAL_TOW,
            ].includes(params?.type)) {
              data = response?.data?.services;
            }

            for (let i = 0; i < data?.length; i++) {
              data[i].selected = false;
            }


            return {
              ...prevState,
              data: data,
              count: response?.data?.count,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setExportablesDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch(() => {
        setExportablesDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  }

  const handleMultipleSelection = (value, name) => {
    let data = [...exportablesDataTable?.data];


    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.providerName === name && data[i]?.providerPaymentStatus !== "Pagado") {
        data[i].selected = value
      }
    }
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        data: data,
      };
    })
  }

  const selectPayment = (payment, index) => {
    let datos = [...exportablesDataTable?.data];
    datos[index].selected = !datos[index].selected;

    let none = '';

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].selected) {
        none = payment?.status;
      }
    }

    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        data: datos,
        selectedType: none,
      };
    });
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {

          let filteredMethods = response.data?.filter(p => [paymentMethodsEnum?.PAGO_MÓVIL, paymentMethodsEnum?.ZELLE, paymentMethodsEnum?.CASH, paymentMethodsEnum?.TRANSFERENCIA_BANESCO, paymentMethodsEnum?.PAGO_MÓVIL_BANCAMIGA, paymentMethodsEnum?.TRANSFERENCIA_BANCAMIGA, paymentMethodsEnum?.PAGO_MÓVIL_BNC, paymentMethodsEnum?.PAYPAL, paymentMethodsEnum?.CASH_USD]?.includes(p?.name))

          setPaymentMethods(filteredMethods);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };


  const getSelectedayments = () => {
    let selected = exportablesDataTable?.data?.filter(p => p?.selected)
    return selected
  }

  const handlePaymentModal = () => {

    const selected = getSelectedayments();
    let totalAmount = 0

    let particularPayment = 0
    let affiliatedPayment = 0

    for (let i = 0; i < selected.length; i++) {

      if (selected[i]?.caseType === caseType?.PARTICULAR) {
        particularPayment = particularPayment + selected[i]?.providerPay
      }

      if (selected[i]?.caseType === caseType?.AFFILIATED) {
        affiliatedPayment = affiliatedPayment + selected[i]?.providerPay
      }
    }

    for (let i = 0; i < selected.length; i++) {
      totalAmount = totalAmount + selected[i]?.providerPay
    }

    setProvidersPaymentModal((prevState) => {
      return {
        ...prevState,
        data: providersPaymentModal?.isOpen ? [] : selected,
        isOpen: !providersPaymentModal?.isOpen,
        totalAmount: totalAmount,
        particularPayment: parseFloat(particularPayment.toFixed(2)),
        affiliatedPayment: parseFloat(affiliatedPayment.toFixed(2)),
      };
    });
    reset2()

    setValue2("providerMethods", 2)
    const [cash] = paymentMethods?.filter((p) => p?.name === paymentMethodsEnum?.CASH_USD)
    const [pagoMovil] = paymentMethods?.filter((p) => p?.name === paymentMethodsEnum?.PAGO_MÓVIL_BANCAMIGA)
    setValue2("method1", cash?._id)
    setValue2("method2", pagoMovil?._id)
  }


  const exportTable = () => {
    let dataToPrint = [];

    const validateFleet = (isInternal) => {
      if (isInternal) {
        return 'Propia';
      }
      if (isInternal === false) {
        return 'Externa';
      } else return '';
    };

    if (!exportablesDataTable?.type) {
      for (let i = 0; i < exportablesDataTable?.data?.length; i++) {
        let data = {};
        let data2 = {};
        let data3 = {};

        for (
          let j = 0;
          j < exportablesDataTable?.data[i]?.services?.length;
          j++
        ) {
          data[`Servicio Prestado ${j + 1}`] = serviceName(
            exportablesDataTable?.data[i]?.services[j]?.productCode
          );
          data[`Código de servicio ${j + 1}`] =
            exportablesDataTable?.data[i]?.services[j]?.code;

          data[`Flota ${j + 1}`] = validateFleet(
            exportablesDataTable?.data[i]?.services[j]?.provider?.isInternal
          );
          data[`Chofer ${j + 1}`] =
            exportablesDataTable?.data[i]?.services[j]?.provider?.contactName;
          data[`Turno Servicio ${j + 1}`] =
            exportablesDataTable?.data[i]?.services[j]?.shift;
          data[`Costo ${j + 1}`] = exportablesDataTable?.data[i]?.services[j]
            ?.price
            ? exportablesDataTable?.data[i]?.services[j]?.price
            : '';
          data[`PVP ${j + 1}`] = exportablesDataTable?.data[i]?.services[j]?.pvp
            ? exportablesDataTable?.data[i]?.services[j]?.pvp
            : '';
          data[`Estatus de Pago ${j + 1}`] = exportablesDataTable?.data[i]
            ?.services[j]?.paymentStatus
            ? exportablesDataTable?.data[i]?.services[j]?.paymentStatus
            : '';

          for (
            let k = 0;
            k < exportablesDataTable?.data[i]?.services[j]?.payments?.length;
            k++
          ) {
            data2[`Método de Pago ${k + 1}`] = capitalize(
              exportablesDataTable?.data[i]?.services[0]?.payments[0]?.method
            );
            data2[`Monto Pago ${k + 1}`] = exportablesDataTable?.data[i]
              ?.services[0]?.payments[0]?.totalAmount
              ? exportablesDataTable?.data[i]?.services[0]?.payments[0]
                ?.totalAmount
              : '';
            data2[`Referencia de Pago ${k + 1}`] = exists(
              exportablesDataTable?.data[i]?.services[0]?.payments[0]?.reference
            );
          }

          for (
            let h = 0;
            h < exportablesDataTable?.data[i]?.newStages?.length;
            h++
          ) {
            data3[`Tiempo AV Asignado (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.AV_ASSIGNED
            );
            data3[`Tiempo AV en vía (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.AV_ON_ROAD
            );
            data3[`Tiempo AV en origen`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.AV_IN_ORIGIN
            );
            data3[`Tiempo en búsqueda grúa (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.SEARCHING_TOW
            );
            data3[`Tiempo grúa asginada (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.TOW_ASSIGNED
            );
            data3[`Tiempo grúa en vía (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.TOW_ON_ROAD
            );
            data3[`Tiempo en grúa en origen (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.TOW_IN_ORIGIN
            );
            data3[`Tiempo cliente vía destino (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              enumStatus?.CUSTOMER_ON_ROAD
            );
            data3[`Tiempo total gestión (min)`] = printTime(
              exportablesDataTable?.data[i],
              h,
              ''
            );
          }
        }

        dataToPrint?.push({
          Operador:
            capitalize(exportablesDataTable?.data[i]?.user?.firstName) +
            ' ' +
            capitalize(exportablesDataTable?.data[i]?.user?.lastName),
          'Nro. de caso': exists(exportablesDataTable?.data[i]?.caseId),
          'Fecha de creación': exportablesDataTable?.data[i]?.createdAt
            ? moment
              .utc(exportablesDataTable?.data[i]?.createdAt)
              .format('DD/MM/YYYY')
            : '',
          'Hora de creación': exportablesDataTable?.data[i]?.createdAt
            ? moment.utc(exportablesDataTable?.data[i]?.createdAt).format('LTS')
            : '',
          Estatus: capitalize(exportablesDataTable?.data[i]?.status),
          Nombre:
            capitalize(
              exportablesDataTable?.data[i]?.particularCustomer?.firstName
            ) ||
            capitalize(
              exportablesDataTable?.data[i]?.membership?.customer?.firstName
            ),
          Apellido:
            capitalize(
              exportablesDataTable?.data[i]?.particularCustomer?.lastName
            ) ||
            capitalize(
              exportablesDataTable?.data[i]?.membership?.customer?.lastName
            ),
          Placa:
            exists(
              exportablesDataTable?.data[i]?.particularCustomer?.vehiclePlate
            ) ||
            exists(exportablesDataTable?.data[i]?.membership?.vehicle?.plate),
          Marca:
            capitalize(
              exportablesDataTable?.data[i]?.particularCustomer?.vehicleBrand
                ?.brand
            ) ||
            capitalize(
              exportablesDataTable?.data[i]?.membership?.vehicle?.brand
            ),
          Modelo:
            capitalize(
              exportablesDataTable?.data[i]?.particularCustomer?.vehicleModel
                ?.model
            ) ||
            capitalize(
              exportablesDataTable?.data[i]?.membership?.vehicle?.model
            ),
          Año:
            exists(
              exportablesDataTable?.data[i]?.particularCustomer?.vehicleYear
            ) ||
            exists(exportablesDataTable?.data[i]?.membership?.vehicle?.year),
          Plan: capitalize(exportablesDataTable?.data[i]?.membership?.code),
          'Estatus de Afiliación': capitalize(
            exportablesDataTable?.data[i]?.membership?.status
          ),
          'Tipo de gestión': capitalize(
            exportablesDataTable?.data[i]?.caseType
          ),
          Avería: capitalize(exportablesDataTable?.data[i]?.fault),
          'Avería Real': capitalize(exportablesDataTable?.data[i]?.realFault),
          'Estado origen': capitalize(
            exportablesDataTable?.data[i]?.originState
          ),
          'Ciudad origen': capitalize(
            exportablesDataTable?.data[i]?.originCity
          ),
          'Estado destino': capitalize(
            exportablesDataTable?.data[i]?.destinyState
          ),
          'Ciudad destino': capitalize(
            exportablesDataTable?.data[i]?.destinyCity
          ),
          ...data,
          ...data2,
          ...data3,
        });
      }
    }

    if (
      [
        providersPaymentsTypes?.PAYMENT_AV,
        providersPaymentsTypes?.PAYMETN_INTERNAL_TOW,
        providersPaymentsTypes?.PAYMENT_EXTERNAL_TOW,
      ].includes(exportablesDataTable?.type)
    ) {
      for (let i = 0; i < exportablesDataTable?.data?.length; i++) {
        dataToPrint?.push({
          'Empresa/Chofer': capitalize(
            exportablesDataTable?.data[i]?.providerName
          ),
          'Fecha de servicio': exportablesDataTable?.data[i]?.createdAt
            ? moment
              .utc(exportablesDataTable?.data[i]?.createdAt)
              .format('DD/MM/YYYY')
            : '',
          'Servicio Prestado': capitalize(
            capitalize(exportablesDataTable?.data[i]?.product)
          ),
          'Tipo de gestión': capitalize(
            exportablesDataTable?.data[i]?.caseType
          ),
          'Código de servicio': capitalize(exportablesDataTable?.data[i]?.code),
          Placa: exists(exportablesDataTable?.data[i]?.plate),
          Marca: exists(exportablesDataTable?.data[i]?.vehicleBrand),
          'Estado origen': capitalize(
            exportablesDataTable?.data[i]?.originState
          ),
          'Ciudad origen': capitalize(
            exportablesDataTable?.data[i]?.originCity
          ),
          'Monto a pagar proveedor $':
            exportablesDataTable?.data[i]?.providerPay,
        });
      }
    }

    let name = `${rename(exportablesDataTable?.type || 'DB')}_pag_${exportablesDataTable?.page
      }_desde_${moment
        .utc(watch('sinceDate'))
        .format('DD-MM-YYYY')}_hasta_${moment
          .utc(watch('untilDate'))
          .format('DD-MM-YYYY')}`;

    exportDataToExcel(dataToPrint, `${name}.xlsx`);
  };

  const getProviders = () => {
    let params = {
      type: providersOptions?.type,
      offset: 0,
      limit: 10000,
      isInternal: providersOptions?.isInternal,
      isActive: true
    };

    setProvidersOptions((prevState) => {
      return {
        ...prevState,
        data: [],
        count: 0,
        isLoading: false,
      };
    });


    providersService
      .getProvidersByType(filterParams(params))
      .then((response) => {
        if (response.status === 200) {

          setProvidersOptions((prevState) => {
            return {
              ...prevState,
              data: [{ _id: "", name: "" }, ...response?.data?.providers],
              count: response?.data?.count,
              isLoading: false,
            };
          });
        }
      })
      .catch(() => { });
    return true;
  };


  useEffect(() => {
    getDollarRate()
    getPaymentMethods()
    getExportables();
    getProviders()
  }, [
    exportablesDataTable?.page,
    exportablesDataTable?.offset,
    exportablesDataTable?.limit,
    exportablesDataTable?.type,
    exportablesDataTable?.sinceDate,
    exportablesDataTable?.untilDate,
    exportablesDataTable?.provider,
    exportablesDataTable?.paymentStatus,
    sinceDate,
    untilDate,
  ]);

  useEffect(() => {
    getProviders()
  }, [
    providersOptions?.type,
    providersOptions?.isInternal,
  ]);


  return (
    <>
      <LoadSpinner show={exportablesDataTable.isLoading} />
      <Header />
      <ProdiversPaymentModal modal={providersPaymentModal} handleModal={handlePaymentModal}
        options={{ paymentMethodsQuantity: paymentMethodsQuantity, paymentMethods: paymentMethods }}
        onChange={{ handleAmountChange: handleAmountChange, handleMethodsChange: handleMethodsChange }}
        register={register2}
        errors={errors2}
        watch={watch2}
        action={createProviderPayment}
      />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            TU/GRUERO: {exportablesDataTable?.count}
          </h1>
          <ActionButton label={'Exportar'} action={() => exportTable()} />
        </div>
        <div className="breadcrumb-container">
          <div className="exportables-buttons-container">
            <FilterButton
              label={providersPaymentsTypes?.PAYMENT_AV}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={providersPaymentsTypes?.PAYMETN_INTERNAL_TOW}
              type={exportablesDataTable?.type}
              action={selectType}
            />
            <FilterButton
              label={providersPaymentsTypes?.PAYMENT_EXTERNAL_TOW}
              type={exportablesDataTable?.type}
              action={selectType}
            />
          </div>
          <div className="exportables-buttons-container">
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Desde'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleSinceDateChange}
              value={sinceDate}
            />{' '}
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Hasta'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleUntilDateChange}
              value={untilDate}
            />
            <div className='col-6 providers-payments-filter-container'>
              <Select
                options={providersOptions?.data}
                label={'Proveedores'}
                value={exportablesDataTable?.provider}
                name={"provider"}
                onChange={handleFilterChange}
              />
              <Select
                options={paymentStatusOptions}
                label={'Estatus de pago'}
                value={exportablesDataTable?.paymentStatus}
                name={"paymentStatus"}
                onChange={handleFilterChange}
              />
              <div style={{ width: "24px" }}>
                <SelectPerPage
                  label={'Registros'}
                  options={itemsPerpage}
                  value={exportablesDataTable?.limit}
                  onChange={selectitemsPerpage}
                />
              </div>
            </div>
          </div>
          <span></span>
        </div>
        {getSelectedayments().length > 0 &&
          <div style={{ marginBottom: '12px' }}>
            <ActionButton label={'Pagar'} action={() => handlePaymentModal()} />
          </div>
        }

        {[
          providersPaymentsTypes?.PAYMENT_AV,
          providersPaymentsTypes?.PAYMETN_INTERNAL_TOW,
          providersPaymentsTypes?.PAYMENT_EXTERNAL_TOW,
        ].includes(exportablesDataTable?.type) &&
          !exportablesDataTable.isLoading && (
            <ProvidersPaymentTable
              header={ProvidersPaymentHeader}
              handlePageChange={handlePageChange}
              selectPayment={selectPayment}
              data={exportablesDataTable}
              type={exportablesDataTable?.type}
              tableRef={tableRef}
              handleMultipleSelection={handleMultipleSelection}
            />
          )}
      </div >
    </>
  );
};
