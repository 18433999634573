import { fixResponseJSON, filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const rolesService = {
  getRoles: async (module) => {
    const response = await authInstance
      .get(`${url}/roles?module=${module}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
