import { filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const providersService = {
  getAVs: async (searchParam) => {
    const response = await authInstance
      .get(`${url}/providers/av`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getProvidersDoneService: async (userId, params) => {
    const myInit = {
      headers: {
        'Content-type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      method: 'GET',
    }

    const response = await authInstance
      .get(
        `${url}/providers/${userId}/countDoneServices?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getProvidersByType: async (params) => {
    const response = await authInstance
      .get(`${url}/providers/?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  payProviders: async (data) => {
    const response = await authInstance
      .post(`${url}/providers/payments`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  createProvider: async (data) => {
    const response = await authInstance
      .post(`${url}/providers/create`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  editProvider: async (data, providerId) => {
    const response = await authInstance
      .put(`${url}/providers/${providerId}/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  acrtivateProvider: async (data, providerId) => {
    const response = await authInstance
      .put(`${url}/providers/${providerId}/setActiveStatus`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getProvidersById: async (providerId) => {
    const response = await authInstance
      .get(`${url}/providers/${providerId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
  getInternalTows: async (providerId) => {
    const response = await authInstance
      .get(`${url}/providers/internalTows`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getProviders: async (params) => {
    const response = await authInstance
      .get(`${url}/providers/all?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getInternalTowServices: async (params) => {
    const response = await authInstance
      .get(`${url}/providers/activeServices?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
