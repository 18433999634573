import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const faultsService = {
  getFaults: async (searchParam) => {
    const response = await authInstance
      .get(`${url}/faults/search?description=&limit=1000`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
