import { useState } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { UsersTable, FleetsTable } from '../../common/Tables/Tables';
import { NavLink } from 'react-router-dom';
import {
  fleetsTableheader,
  fleetsTablesMockup,
} from '../../../assets/helpers/tables';
import './Configurations.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const UsersConfig = () => {
  const [perPage, setPerPage] = useState(itemsPerpage[1]);

  const selectitemsPerpage = (event) => {
    const { value } = event.target;
    setPerPage(value);
  };

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">Usuarios</h1>
        <div className="breadcrumb-container">
          <span></span>
          <NavLink to="/config/users/create">
            <ActionButton label={'+ Nuevo Usuario'} />
          </NavLink>
        </div>
        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <SelectPerPage
            label={'Registros por página'}
            options={itemsPerpage}
            value={perPage}
            onChange={selectitemsPerpage}
          />
        </div>
      </div>
    </>
  );
};

export const FleetConfig = () => {
  const [perPage, setPerPage] = useState(itemsPerpage[1]);

  const selectitemsPerpage = (event) => {
    const { value } = event.target;
    setPerPage(value);
  };

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">Flota</h1>
        <div className="breadcrumb-container">
          <span></span>
          <NavLink to="/config/fleet/create">
            <ActionButton label={'+ Nuevo Vehículo'} />
          </NavLink>
        </div>
        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <SelectPerPage
            label={'Registros por página'}
            options={itemsPerpage}
            value={perPage}
            onChange={selectitemsPerpage}
          />
        </div>
        <FleetsTable header={fleetsTableheader} data={fleetsTablesMockup} />
      </div>
    </>
  );
};
