import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { LoginForm } from '../../common/Forms/Form';
import { useHistory } from 'react-router-dom';
import { LogoButton, ActionButton } from '../../common/Buttons/Buttons';
import logo from '../../../images/logo.svg';
import { NavLink } from 'react-router-dom';
import { loginService } from '../../../services/loginService.js';
import { notify } from '../../../assets/helpers/toast';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import './Login.scss';

export const Login = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.LoginSchema(t)),
  });
  const [isLoading, setIsloading] = useState(false);
  let history = useHistory();
  // Function that handles the logout request
  const onSubmit = handleSubmit(async (data) => {
    // history.push('/services');
    setIsloading(true);
    loginService
      .login(data)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem(
            `accessToken`,
            response?.data?.tokens?.accessToken
          );
          sessionStorage.setItem(
            `refreshToken`,
            response?.data?.tokens?.refreshToken
          );
          sessionStorage.setItem(`user`, JSON.stringify(response?.data?.user));
          history.push('/services');
          setIsloading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsloading(false);
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  });

  const preloadInputs = () => {
    let develop = process.env.REACT_APP_DEVELOP;

    if (develop == 'true') {
      setValue('email', process.env.REACT_APP_OPERATIONS_USER);
      setValue('password', process.env.REACT_APP_OPERATIONS_PASSWORD);
    }
  };

  useEffect(() => {
    preloadInputs();
  }, []);

  return (
    <>
      <LoadSpinner label={'Iniciando Sesión'} show={isLoading} />
      <div className="container view-content">
        <div className="row">
          <div className="col-12 login-page-container">
            <div className="login-form">
              <div className="login-logo-container">
                <LogoButton link={'/'} src={logo} />
                <br />
                <h5>Módulo de Operaciones </h5>
                <div className="horizontal-bar-container">
                  <div></div>
                </div>
              </div>
              <LoginForm
                t={t}
                register={register}
                errors={errors}
                action={onSubmit}
              />
              {/* <div className="register-call-to-action-container">
                ¿No tienes una cuenta? {'    '}
                <NavLink to={'/sign-up'}>Regístrate</NavLink>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
