import Loader from 'react-loader-spinner';
import './LoadSpinner.scss';

export const LoadSpinner = (props) => {
  const { label, show } = props;

  if (show) {
    return (
      <div className="load-spinner-cotnainer">
        <Loader
          type="Oval"
          color={'#485259'}
          height={100}
          width={100}
          timeout={0} //3 secs
        />
        <h6 style={{ marginTop: '12px' }}>{label}</h6>
      </div>
    );
  } else return null;
};

export const TableLoadSpinner = (props) => {
  const { label, show } = props;

  if (show) {
    return (
      <div className="table-load-spinner-container">
        <Loader
          type="Oval"
          color={'#485259'}
          height={100}
          width={100}
          timeout={0} //3 secs
        />
        <h6 style={{ marginTop: '12px' }}>{label}</h6>
      </div>
    );
  } else return null;
};

export const ZoneLoadSpinner = (props) => {
  const { label, show } = props;

  if (show) {
    return (
      <div className="zone-load-spinner-container">
        <Loader
          type="Oval"
          color={'#485259'}
          height={100}
          width={100}
          timeout={0} //3 secs
        />
        <h6 style={{ marginTop: '12px' }}>{label}</h6>
      </div>
    );
  } else return null;
};
