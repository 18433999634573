import { useEffect, useState, } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import {
  FilterDatePicker,
} from '../../common/Forms/Input/Input';
import { providersService } from '../../../services/providersService';
import { caseService } from '../../../services/caseService';
import authService from '../../../services/authService.js';
import { notify } from '../../../assets/helpers/toast';
import { CounterTable } from '../../common/Tables/Tables.jsx';
import { AVStatstable } from '../../common/Tables/Tables.jsx';
import { operationsRoles } from '../../../assets/helpers/roles';
import { SelectPerPage } from "../../common/Forms/Input/Input.jsx"
import { setviceCounterTable, avStatsHeaderTable } from '../../../assets/helpers/tables';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner.jsx';
import moment from 'moment';
import './Stats.scss';

export const Stats = () => {

  const [sinceDate, setSinceDate] = useState(
    new Date(Date.now() - 3600 * 1000 * 24)
  );
  const [untilDate, setUntilDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );

  const itemsPerpage = [10, 25, 50, 100, 500];


  const [statsTable, setStatsTable] = useState({
    limit: 25,
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });


  const [counters, setCounters] = useState([])


  const handlePageChange = (event, value) => {
    setStatsTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * statsTable?.limit,
      };
    });
  };

  const selectitemsPerpage = (event) => {
    setStatsTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const getProvidersDoneService = () => {
    let user = sessionStorage.getItem('user');
    let since = sessionStorage.getItem('sinceDate');

    if (since) {

    }
    let until = sessionStorage.getItem('untilDate');
    user = JSON.parse(user);
    since = since ? since : sinceDate;
    until = until ? until : untilDate;


    let params = {
      from: since ? moment.utc(since).format('YYYY-MM-DD') : '',
      to: until ? moment.utc(until).format('YYYY-MM-DD') : '',
    };

    providersService
      .getProvidersDoneService(user?.provider?._id, params)
      .then((response) => {
        if (response.status === 200) {
          setCounters(response?.data?.results)
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {
      });
  };

  const handleSinceDateChange = (e) => {
    if (e > untilDate && untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate && sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const getCasesByOperator = () => {
    let user = sessionStorage.getItem('user');
    let since = sessionStorage.getItem('sinceDate');
    let until = sessionStorage.getItem('untilDate');
    user = JSON.parse(user);
    since = since ? since : sinceDate;
    until = until ? until : untilDate;


    let params = {
      sinceDate: since ? moment.utc(since).format('YYYY-MM-DD') : '',
      untilDate: until ? moment.utc(until).format('YYYY-MM-DD') : '',
      limit: statsTable?.limit,
      offset: statsTable?.offset, //Elementos que ya pasaron la pagina
      page: statsTable?.page,
      status: "Finalizada"
    };

    setStatsTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });


    caseService
      .getCasesByOperator(user?._id, params)
      .then((response) => {
        if (response.status === 200) {
          setStatsTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.cases,
              count: response?.data?.count,
              isLoading: false,
            };
          });

        } else {
          notify(`${response.message}`, 'error');
          setStatsTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setStatsTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
  };


  const filterHeader = (header) => {


    let user = authService?.getUser()

    if (user?.roles[0]?.code !== operationsRoles?.OPERATIONS_FLOTA_AV) {

      return header.filter((h) => h.name !== 'Averia Solventada')

    } else {
      return header
    }
  }


  useEffect(() => {
    getProvidersDoneService()
    getCasesByOperator()
  }, [statsTable?.page, statsTable?.offset, statsTable?.limit]);



  useEffect(() => {
    getProvidersDoneService()
    getCasesByOperator()
  }, [sinceDate, untilDate]);


  return (
    <>
      <LoadSpinner show={statsTable?.isLoading} />
      <Header />
      <div className="service-with-container container">
        <h1 style={{ marginBottom: '0px' }} className="title-component">
          Estadísticas
        </h1>
        <div className="service-filter-button-container">
          <span></span>
          <div className="row">
            <>
              <div className="col-3"></div>
              <FilterDatePicker
                bootstrap={'col-4'}
                label={'Desde'}
                type={'date'}
                name={'untilDate'}
                placeholder={''}
                onChange={handleSinceDateChange}
                value={sinceDate}
              />{' '}
              <FilterDatePicker
                bootstrap={'col-4'}
                label={'Hasta'}
                type={'date'}
                name={'untilDate'}
                placeholder={''}
                onChange={handleUntilDateChange}
                value={untilDate}
              />
            </>
            {/* )} */}
          </div>
        </div>
        <div className="gray-line"></div>
        <div className='stats-counter-data-table-container'>
          <CounterTable dataTable={counters} header={setviceCounterTable} />
        </div>
        <div className="gray-line"></div>
        <h4>{statsTable?.count ? `Servicios: ${statsTable?.count}` : ""}</h4>
        <br />
        <SelectPerPage
          label={'Registros por página'}
          options={itemsPerpage}
          value={setStatsTable?.limit}
          onChange={selectitemsPerpage}
        />
        <AVStatstable dataTable={statsTable} header={filterHeader(avStatsHeaderTable)} user={authService?.getUser()} handlePageChange={handlePageChange} />
      </div>
    </>
  );
};

