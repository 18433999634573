import React, { useState } from 'react';
import threedots from '../../../images/threedots.svg';
import logoGold from '../../../images/logoGold.svg';
import logoRetro from '../../../images/logoRetro.svg';
import logoClassic from '../../../images/logoClassic.svg';
import logoPlus from '../../../images/logoPlus.svg';
import logoMotoRider from '../../../images/logoMotoRider.png';
import logoRidery from '../../../images/logoRidery.svg';
import { AltButton, ActionButton } from '../Buttons/Buttons';
import {
  paintCard,
  exists,
  capitalize,
  scheduleFinalColor,
} from '../../../assets/helpers/funciones';
import {
  membershipCodes,
  planStatus,
  enumStatus,
  SERVICE_STATUS,
  incidenceColumnsEnum
} from '../../../assets/helpers/options';
import { caseType, PaymentStatus, statusEnum } from '../../../assets/helpers/options';
import {
  SMSServiceLogo,
  EstimatedTime,
} from '../../views/Incidence/IndicenceButtons';
import { hideForOperators } from "../../../assets/helpers/funciones"
import moment from 'moment';
import './Cards.scss';
import authService from '../../../services/authService';
import { operationsRoles } from '../../../assets/helpers/roles';

export const MembershipLogo = (props) => {
  const { code } = props;

  if (code === membershipCodes.GOLD) {
    return (
      <img className="case-membership-logo" src={logoGold} alt={'logo'}></img>
    );
  }
  if (code === membershipCodes.RETRO) {
    return (
      <img className="case-membership-logo" src={logoRetro} alt={'logo'}></img>
    );
  }
  if ([membershipCodes.PLUS, membershipCodes.PLUSF4]?.includes(code)) {
    return (
      <img className="case-membership-logo" src={logoPlus} alt={'logo'}></img>
    );
  }

  if (code === membershipCodes.RIDERY) {
    return (
      <img
        className="case-membership-logo"
        src={logoRidery}
        alt={'logo'}
        style={{ maxHeight: "40px" }}
      ></img>
    );
  }

  if (code === membershipCodes.CLASSIC) {
    return (
      <img
        className="case-membership-logo"
        src={logoClassic}
        alt={'logo'}
      ></img>
    );
  }
  if (code === membershipCodes.MOTORIDER) {
    return (
      <img
        className="case-membership-logo"
        src={logoMotoRider}
        alt={'logo'}
      ></img>
    );
  } else return null;
};

export const PlanStatus = (props) => {
  const { label, membership } = props;
  let status = 'false';
  let newLabel = label;

  if (
    [planStatus.ACTIVE, planStatus.TO_BE_RENEW, planStatus?.RENOVADO].includes(
      label
    )
  ) {
    status = 'true';
    newLabel = 'Activo';
  }
  if ([planStatus.WAITING_TO_BE_EXPIRED].includes(label)) {
    status = 'true';
    newLabel = 'Esperando Vencimiento';
  }
  if ([planStatus.EXPIRED, planStatus.TO_BE_EXPIRED].includes(label)) {
    status = 'false';
    newLabel = 'Vencido';
  }

  if ([planStatus.WAITING].includes(label)) {
    status = 'false';
    newLabel = label;
  }

  return (
    <div className='plan-card-status-container'>
      <div className={`plan-card-status plan-card-status-${status}`}>
        {capitalize(newLabel)}
      </div>
      {membership?.annullationReason &&
        <h6 style={{ textAlign: "center", marginTop: "12px" }}>{membership?.annullationReason}</h6>
      }
    </div>
  );
};

export const IncidenceColumn = (props) => {
  const {
    cases,
    options,
    actionButtons,
    statusButtons,
    title,
    caseStatus,
    status,
    handleSMSModal,
    handleSelectService,
  } = props;


  if ([operationsRoles?.OPERATIONS_FLOTA_AV]?.includes(authService?.getUserType()) && ![incidenceColumnsEnum?.AV_ASIGNADO, incidenceColumnsEnum?.AV_EN_VIA, incidenceColumnsEnum?.AV_EN_ORIGEN]?.includes(title)) {
    return null
  }

  if ([operationsRoles?.OPERATIONS_FLOTA_GRUA]?.includes(authService?.getUserType()) && [incidenceColumnsEnum?.ORDEN_NO_CREADA, incidenceColumnsEnum?.AV_ASIGNADO, incidenceColumnsEnum?.AV_EN_VIA, incidenceColumnsEnum?.AV_EN_ORIGEN]?.includes(title)) {
    return null
  }

  if ((!caseStatus && ![enumStatus.FINISHED, enumStatus.FAILED]?.includes(status)) ||
    (caseStatus === enumStatus.FINISHED && [enumStatus.FINISHED, enumStatus.FAILED]?.includes(status)) ||
    (caseStatus === enumStatus.SCHEDULED && [enumStatus.SCHEDULED,]?.includes(status)) ||
    (caseStatus === enumStatus?.PENDING_PAYMENT && ![enumStatus?.INITIATED]?.includes(status))
  ) {
    return (
      <div className="vertical-incidence-container">
        <IndicenceHeaderCard title={title} cases={cases} />
        <div className="vertical-incidence-break-container">
          {cases.map((c) => (
            <IncidenceCards
              key={c?._id}
              _case={c}
              options={options}
              actionButtons={actionButtons}
              statusButtons={statusButtons}
              handleSMSModal={handleSMSModal}
              handleSelectService={handleSelectService}
            />
          ))}
        </div>
      </div>
    );
  }
  else return null
};

export const validateTowImages = (_case) => {

  let [services] = _case?.services?.filter(s => s?.type === 'Grua')

  if (_case?.lastStage?.name === enumStatus?.CUSTOMER_ON_ROAD && services && !services?.provider?.isInternal && (!services?.towImages?.towAtOrigin || !services?.towImages?.vehicleOnRoad || !services?.towImages?.vehicleOnTow)) {
    return true
  } else {
    return false
  }
}

export const IndicenceHeaderCard = (props) => {
  const { title, cases } = props;

  return (
    <div
      className={`facturacion-component card indicator-component-header incidence-card-${paintCard(
        cases
      )}`}
    >
      <h6>{title}</h6>
      <div className="facturacion-component-quantity">
        <span>{cases?.length}</span>
      </div>
    </div>
  );
};

export const IncidenceCards = (props) => {
  let {
    _case,
    options,
    statusButtons,
    actionButtons,
    handleSMSModal,
    handleSelectService,
  } = props;
  const [showOptions, setShowOptions] = useState(false);

  // if (!_case?.lastService?.provider?.isInternal) {
  //   options = options?.filter((o) => o?.name !== 'Cambiar Gruero');
  // }


  const handlePaymentStatus = () => {

    if (_case?.lastService?.paymentStatus === PaymentStatus?.CUENTA_POR_COBRAR && !_case?.lastService?.pendingPayment?.pending) {
      return "Pago realizado"
    } else {
      return exists(_case?.lastService?.paymentStatus)
    }
  }

  return (
    <>
      <div
        style={{
          background: scheduleFinalColor(_case),
        }}
        className={`card incidence-card-${paintCard([_case])}`}
      // onClick={() => console.log("Case", _case)}
      >
        <div className="incidence-component-header">
          <h6>Nro. {_case?.caseId}</h6>
          <div className="incidence-component-code-header">
            <h6 style={{ textAlign: 'right' }}>
              {_case?.caseType === caseType.AFFILIATED ? (
                <>
                  {exists(_case?.membership?.code?.prefixCode)}-
                  {exists(_case?.membership?.saleChannel?.shortName)}-
                  {exists(_case?.membership?.membershipId)}
                </>
              ) : (
                'Cliente no Afiliado'
              )}

              <br />
              {_case?.caseType}
            </h6>
            <StatusHandler
              _case={_case}
              options={options}
              show={showOptions}
              handleOpen={setShowOptions}
            />
          </div>
        </div>
        <br />
        <div className="row card-row-container">
          <div className="col-10">
            <h5>
              {capitalize(
                _case?.membership?.customer?.firstName ||
                _case?.particularCustomer?.firstName
              ) +
                '  ' +
                capitalize(
                  _case?.membership?.customer?.lastName ||
                  _case?.particularCustomer?.lastName
                )}
            </h5>
          </div>
          <div style={{ padding: '0px' }} className="col-2">
            <MembershipLogo code={_case?.membership?.code?.code} />
          </div>
          <div className="col-12">
            <h6 className="incidence-component-vehicle">
              {exists(
                _case?.membership?.vehicle?.plate ||
                _case?.particularCustomer?.vehiclePlate
              )}
              &nbsp;
              {capitalize(
                _case?.membership?.vehicle?.brand ||
                _case?.particularCustomer?.vehicleBrand?.brand
              )}
              &nbsp;
              {capitalize(
                _case?.membership?.vehicle?.model ||
                _case?.particularCustomer?.vehicleModel?.model
              )}
              &nbsp;
              {exists(
                _case?.membership?.vehicle?.year ||
                _case?.particularCustomer?.vehicleYear
              )}
              &nbsp;
              {exists(_case?.particularCustomer?.vehicleColor)}
            </h6>
          </div>
          <br />
          <div className="col-12 incidence-card-text">
            <p>
              <b>Ruta:</b> {capitalize(_case?.originCity?.name)}{' '}
              {_case?.destinyCity?.name
                ? 'a ' + capitalize(_case?.destinyCity?.name)
                : ''}
              <br />
              <b>Avería:</b> {capitalize(_case?.fault?.description)}
              {_case?.lastService?.provider?.contactName && (
                <>
                  <br />
                  <b>
                    Proveedor (
                    {_case?.lastService?.provider?.isInternal
                      ? 'Interno'
                      : 'Externo'}
                    ) :
                  </b>{' '}
                  {capitalize(_case?.lastService?.provider?.contactName)}
                </>
              )}
              <EstimatedTime
                service={_case?.lastService?.product?.code}
                grua={'grua'}
              />
              <br />
              <b>Operador:</b> {exists(_case?.user?.firstName)}{' '}
              {exists(_case?.user?.lastName)}
              {_case?.createdBy?.firstName && (
                <>
                  <br />
                  <b>
                    Gestionado:
                  </b>{' '}
                  {capitalize(_case?.createdBy?.firstName) + " " + capitalize(_case?.createdBy?.lastName)}
                </>
              )}
              {[enumStatus?.FINISHED, enumStatus?.FAILED, enumStatus?.CANCELED]?.includes(_case?.status) && _case?.user && (
                <>
                  <br />
                  <b>
                    Finalizado:
                  </b>{' '}
                  {capitalize(_case?.user?.firstName) + " " + capitalize(_case?.user?.lastName)}
                </>
              )}
            </p>
            {_case?.lastService?.paymentStatus &&
              _case?.lastService?.paymentStatus ===
              PaymentStatus?.CUENTA_POR_COBRAR && (
                <p className="incidence-card-creation-date">
                  <b>Estatus de Pago: </b>
                  {handlePaymentStatus(_case)}
                </p>
              )}

            {_case?.scheduleDate && (
              <p className="incidence-card-creation-date">
                <b> Fecha y hora del servicio:</b> <br />
                {exists(
                  moment.utc(_case?.scheduleDate).format('DD/MM/YYYY LTS')
                )}
              </p>
            )}

            <p className="incidence-card-creation-date">
              Última modificación:{' '}
              {exists(
                moment.utc(_case?.lastStage?.updatedAt).format('DD/MM/YYYY LTS')
              )}
              <br /> Creado :{' '}
              {exists(moment.utc(_case?.createdAt).format('DD/MM/YYYY LTS'))}
            </p>
          </div>

          {_case?.lastService?.code && (
            <div className="icon-service-code-container">
              {_case?.lastService?.code && (
                <p>{exists(_case?.lastService?.code)}</p>
              )}
              <SMSServiceLogo
                service={_case?.lastService?.product?.code}
                action={() => handleSMSModal(_case)}
              />
            </div>
          )}
          {_case?.status === enumStatus?.SEARCHING_TOW &&
            !_case?.lastService?.code && (
              <div className="icon-service-code-container">
                {<p></p>}
                <SMSServiceLogo
                  service={_case?.lastService?.product?.code}
                  action={() => handleSMSModal(_case)}
                  grua={'grua'}
                />
              </div>
            )}

          {validateTowImages(_case) && <div className='crane-image-missing'><h6>Falta cargar imágenes de servicio de grúa</h6></div>}

          <div
            style={{ marginTop: '12px' }}
            className="buttons-center-container"
          >
            {hideForOperators() && actionButtons
              ?.filter((a) => a?.status?.includes(_case?.status))
              ?.map((a) => (
                <AltButton
                  key={a?.name}
                  action={() => a?.action(_case)}
                  label={a?.name}
                />
              ))}

            {hideForOperators() && statusButtons
              ?.filter((a) => a?.status?.includes(_case?.status))
              ?.map((a) => {
                if (_case?.status !== 'Servicio Agendado') {
                  return (
                    <ActionButton
                      key={a?.name}
                      action={() => a?.action(_case, a?.name)}
                      label={a?.name}
                    />
                  );
                }

                if (
                  _case?.status === 'Servicio Agendado' &&
                  _case?.lastService?.provider &&
                  a.name == 'Iniciar Servicio'
                ) {
                  return (
                    <ActionButton
                      key={a?.name}
                      action={() => a?.action(_case, a?.name)}
                      label={a?.name}
                    />
                  );
                }

                if (
                  _case?.status === 'Servicio Agendado' &&
                  !_case?.lastService?.provider &&
                  a.name == 'Asignar grúa'
                ) {
                  return (
                    <ActionButton
                      key={a?.name}
                      action={() => a?.action(_case, a?.name)}
                      label={a?.name}
                    />
                  );
                }
              })}

            {_case?.status === enumStatus?.FINISHED &&
              PaymentStatus?.CUENTA_POR_COBRAR && _case?.lastService?.pendingPayment?.pending && (
                <ActionButton
                  action={() => handleSelectService(_case)}
                  label={'Registrar pago'}
                />
              )}
          </div>
        </div>
      </div >
    </>
  );
};

export const StatusHandler = (props) => {
  const { options, handleOpen, show, _case } = props;


  if (
    [
      operationsRoles?.OPERATIONS_FLOTA_GRUA,
    ]?.includes(authService?.getUserType())
  ) {
    return null
  }

  return (
    <>
      < img src={threedots} alt="threedots" onClick={() => handleOpen(!show)} />
      {
        show && (
          <div className="incidence-status-options-container">
            {options
              ?.filter((o) => o?.status?.includes(_case?.status))
              ?.map((o) => {


                if ([membershipCodes?.RIDERY]?.includes(_case?.membership?.code?.code) && (!_case?.towProductQuantity[0] || _case?.towProductQuantity?.length === 0) && o?.name === statusEnum?.ASIGNAR_GRUA) {
                  return null
                }
                if ([
                  operationsRoles?.OPERATIONS_FLOTA_AV,
                ]?.includes(authService?.getUserType()) && o?.name !== statusEnum?.REVISION_EXPRESS) {
                  return null
                }


                return (
                  <Status
                    _case={_case}
                    option={o}
                    handleOpen={handleOpen}
                  />
                )
              })}
          </div>
        )
      }
    </>
  );
};

const handleSolvedByAV = (_case, option) => {

  let isTow = false

  for (let i = 0; i < _case?.services?.length; i++) {
    if (_case?.services[i]?.type === 'Grua') {
      isTow = true
    }
  }

  if ([statusEnum?.CHANGE_TO_SOLVED, statusEnum?.CHANGE_TO_CHECKING]?.includes(option.name) && isTow) {
    return false
  }

  if (_case.solvedByAV && option.name === statusEnum?.CHANGE_TO_SOLVED) {
    return false
  }
  if (!_case.solvedByAV && option.name === statusEnum?.CHANGE_TO_CHECKING) {
    return false
  }
  else return true
}

const validateIfHasCashbacks = (services) => {
  let hasCashbacks = false

  for (let i = 0; i < services?.length; i++) {
    if (services[i]?.paymentChanges?.length > 0) {
      hasCashbacks = true
    }
  }
  return hasCashbacks
}

export const Status = (props) => {
  const { _case, option, handleOpen } = props;

  let serivesCounter = 0

  if (!validateIfHasCashbacks(_case?.services) && option?.name === statusEnum?.ACTUALIZAR_VUELTOS) {
    return null
  }

  if (!handleSolvedByAV(_case, option)) {
    return null
  }

  if (_case.expressCheckupId && option.name === statusEnum?.REVISION_EXPRESS) {
    return null
  }

  for (let i = 0; i < _case?.services?.length; i++) {
    if ([SERVICE_STATUS?.ACTIVE, SERVICE_STATUS?.DONE]?.includes(_case?.services[i]?.status)) {
      serivesCounter++
    }
  }
  if (option.name === statusEnum?.CANCELAR_SERVICIO && serivesCounter < 2) {
    return null
  }
  else {
    return (
      <div
        key={option.name}
        className="incidence-status-options-option"
        onClick={() => {
          option.action(_case);
          handleOpen(false);
        }}
      >
        {option.name}
      </div>
    )
  }
}


export const IndicatorsCard = (props) => {
  const { label, src, value } = props;
  return (
    <div className="card indicators-card ">
      <h4>{label}</h4>
      <h3>{value}</h3>
      <div style={{ width: '100%' }} className="buttons-container">
        <span></span>
        <img src={src} alt="src" />
      </div>
    </div>
  );
};

export const OperationCard = (props) => {
  const { src, label, value } = props;

  return (
    <div className="card operations-stat-card">
      <div>
        <img src={src} alt="" />
        <h5>{label}</h5>
      </div>
      <h4>{value}</h4>
    </div>
  );
};
