import {
  GoogleMap,
  Marker,
} from '@react-google-maps/api';
import craneLogo from '../../../images/craneLogo.png';
import './Map.scss';


export const LocationMap = (props) => {
  const { data } = props;


  return (
    <>
      <GoogleMap
        center={{
          lat: data?.lat,
          lng: data?.lng,
        }}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '500px' }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        <>
          <Marker
            position={{ lat: data?.lat, lng: data?.lng }}
            label={''}
            icon={{
              url: craneLogo, scaledSize: new window.google.maps.Size(40, 40),
            }}
          >
          </Marker>
        </>
      </GoogleMap>
    </>
  );
};
