import { useState } from 'react';
import { FilterButton } from '../../../common/Buttons/Buttons';
import { Header } from '../../../common/Header/Header.jsx';
import particulares from '../../../../images/particulares.svg';
import afiliados from '../../../../images/afiliados.svg';
import caseOpenOperations from '../../../../images/caseOpenOperations.svg';
import craneIndicator from '../../../../images/craneIndicator.svg';
import craneCostIndicator from '../../../../images/craneCostIndicator.svg';
import operatorStats from '../../../../images/operatorStats.svg';
import bikeOrange from '../../../../images/bikeOrange.svg';
import toedOperator from '../../../../images/toedOperator.svg';
import fixOperator from '../../../../images/fixOperator.svg';
import finishedOperator from '../../../../images/finishedOperator.svg';
import locations from '../../../../images/locations.svg';

import { OperationCard } from '../../../common/Cards/Cards';
import './Operations.scss';

const filterButtons = [
  { name: 'Afiliados', value: 'afiliated', image: afiliados },
  { name: 'Particulares', value: 'particular', image: particulares },
];

export const Operations = () => {
  const [selected, setSelected] = useState(filterButtons[0].value);

  const selectButton = (value) => {
    setSelected(value);
  };

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">Operación</h1>
        <div className="filter-button-container">
          {filterButtons?.map((f) => (
            <FilterButton
              key={f.name}
              button={f}
              selected={selected}
              selectButton={selectButton}
            />
          ))}
        </div>
        <div className="gray-line"></div>

        <br />
        <br />
        <div className="row">
          <div className="indicators-section-container indicators-section-container-l col-12 col-md-6">
            <div className="row">
              <h2>Casos Activos</h2>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={caseOpenOperations}
                  label={'Casos abiertos'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={bikeOrange}
                  label={'AV Operando'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={craneIndicator}
                  label={'Gruas Propias Operando'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={bikeOrange}
                  label={'Motorizados operando'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={craneCostIndicator}
                  label={'Grúas operando'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={operatorStats}
                  label={'Operadores Activos'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={locations}
                  label={'Por Estados'}
                  value={'5'}
                />
              </div>
            </div>
          </div>
          <div className="indicators-section-container indicators-section-container-r  col-12 col-md-6">
            <h2>Casos Finalizados</h2>
            <div className="row">
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={finishedOperator}
                  label={'Casos Finalizados'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={bikeOrange}
                  label={'AV Realizados'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={toedOperator}
                  label={'Remolques Realizados'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={fixOperator}
                  label={'Averías Resueltas'}
                  value={'5'}
                />
              </div>
              <div className="col-12 col-lg-4">
                <OperationCard
                  src={craneIndicator}
                  label={'Servicios de grúas propias'}
                  value={'5'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
