import { useEffect, useState } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage, FilterDatePicker } from '../../common/Forms/Input/Input';
import {
  GarageGPTable,
} from '../../common/Tables/Tables';
import {
  GarageTableHeader,
} from '../../../assets/helpers/tables';
import {
  getProvidersEnum,
} from '../../../assets/helpers/options';
import { garageService } from '../../../services/garageService';
import { notify } from '../../../assets/helpers/toast';
import { exists, filterParams, capitalize, exportDataToExcel } from '../../../assets/helpers/funciones';
import moment from 'moment';
import { ActionButton, TableActionButton } from '../../common/Buttons/Buttons.jsx';
import refreshLogo from '../../../images/refresh.svg';
import './Garage.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const Garage = () => {

  const [sinceDate, setSinceDate] = useState(new Date(Date.now() - 7 * 3600 * 1000 * 24));
  const [untilDate, setUntilDate] = useState(new Date(Date.now()));


  const [invoiceTable, setInvoiceDataTable] = useState({
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    type: getProvidersEnum.CRANE,
    isInternal: false,
    isLoading: true,
  });

  const handleSinceDateChange = (e) => {
    if (e > untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const exportTable = () => {
    let dataToPrint = [];


    for (let i = 0; i < invoiceTable?.data?.length; i++) {
      dataToPrint?.push({
        'Grupo de Facturación': "",
        'Num.Factura': invoiceTable?.data[i]?.client?.clientNum,
        'Nombre Cliente': capitalize(invoiceTable?.data[i]?.client?.name),
        'Fecha Factura': invoiceTable?.data[i]?.date ? moment(invoiceTable?.data[i]?.date)?.format("DD/MM/YYYY") : "",
        'Fecha Cobro': "",
        'Forma Pago': invoiceTable?.data[i]?.paymentMethod,
        'Condiciones de Pago': capitalize(invoiceTable?.data[i]?.paymentDesc),
        'Importe Cobrado': parseFloat((invoiceTable?.data[i]?.total - invoiceTable?.data[i]?.pendingAmount)),
        'Total Factura': parseFloat(invoiceTable?.data[i]?.total),
        'Base Imponible': parseFloat(invoiceTable?.data[i]?.totalNoTax),
        'IVA': parseFloat(invoiceTable?.data[i]?.totalTax),
        'CXC Clientes': parseFloat(invoiceTable?.data[i]?.pendingAmount),
      })

    }
    exportDataToExcel(dataToPrint, `taller_gp.xlsx`);
  };


  const selectitemsPerpage = (event) => {
    setInvoiceDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setInvoiceDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * invoiceTable?.limit,
      };
    });
  };

  const getInvoice = () => {
    setInvoiceDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    let params = {
      offset: invoiceTable?.offset,
      limit: invoiceTable?.limit,
      dateFrom: moment.utc(sinceDate).format('YYYY-MM-DD'),
      dateTo: moment.utc(untilDate).format('YYYY-MM-DD'),
    };

    garageService
      .getInvoices(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setInvoiceDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.invoices,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setInvoiceDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setInvoiceDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };



  useEffect(() => {
    getInvoice();
  }, [
    invoiceTable?.page,
    invoiceTable?.offset,
    invoiceTable?.limit,
    invoiceTable?.type,
    invoiceTable?.sinceDate,
    invoiceTable?.untilDate,
    sinceDate,
    untilDate,
  ]);

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Taller GP: {exists(invoiceTable?.count)}
        </h1>
        <div className="breadcrumb-container">
          <div className='exportables-buttons-container'>
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Desde'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleSinceDateChange}
              value={sinceDate}
            />{' '}
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Hasta'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleUntilDateChange}
              value={untilDate}
            />
          </div>
          <div className="service-filter-buttons-container">
            <TableActionButton src={refreshLogo} action={() => getInvoice()} />
            <ActionButton label={"Exportar"} action={() => exportTable()} />
          </div>

        </div>

        <div className="filter-button-container">
          <SelectPerPage
            label={'Registros por página'}
            options={itemsPerpage}
            value={invoiceTable?.limit}
            onChange={selectitemsPerpage}
          />
        </div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
        </div>
        <GarageGPTable
          header={GarageTableHeader}
          dataTable={invoiceTable}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

