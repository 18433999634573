import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { ActionButton } from '../../common/Buttons/Buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Header } from '../../common/Header/Header';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';
import {
  ScheduleServicesModal,
  MapModal,
  CancelCaseModal,
  CreateAVModal,
  MessageAVModal,
  MessageTowModal,
} from '../../common/Modals/Modals';
import { membershipService } from '../../../services/membershipService';
import { servicesService } from '../../../services/servicesService';
import { stagesService } from '../../../services/stagesService';
import { garageService } from '../../../services/garageService';
import { caseService } from '../../../services/caseService';
import { providersService } from '../../../services/providersService';
import { faultsService } from '../../../services/faultsService';
import { statesService } from '../../../services/statesService';
import { productService } from '../../../services/productService';
import { membershipProductsService } from '../../../services/membershipProductsService';
import { rateService } from '../../../services/rateService';
import { notify } from '../../../assets/helpers/toast';
import { CraneCounter } from '../../miscelaneos/CraneCounter/CraneCounter.jsx';
import moment from 'moment';
import {
  clientTypes,
  notFound,
  particularServices,
  afiliatedServices,
  membershipStatus,
  PaymentStatusOptions,
  PaymentStatus,
  addressEnum,
  addressStatus,
  authorizedByOptions,
  referenceLocation,
  garageLocation,
  afiliatedAddressOptions,
  paymentMethods as metodosDePago,
} from '../../../assets/helpers/options';
import {
  ClientDetail,
  IncidenceSearch,
  AvailableServices,
  SelectedServices,
  IncidenceDetailForm,
  HandleCaseButtons,
  ServiceHistory,
} from './IncidenceSections';
import './Incidence.scss';
import {
  capitalize,
  filterParams,
  exists,
  getOption,
  isValidMongoId,
  parseNull,
  filterOption,
  filterOptionById
} from '../../../assets/helpers/funciones';

export const AffiliatedIncidence = () => {
  const { t } = useTranslation();

  const [statesOptions, setStatesOptions] = useState([]);
  const [originCitiesOptions, setOriginCitiesOptions] = useState([]);
  const [destinycitiesOptions, setDestinyCitiesOptions] = useState([]);
  const [faultsOptions, setFaultsOptions] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [bsDisabled, setbsDisabled] = useState(true);
  const [addressOption, setAddressOption] = useState([]);
  const [showCancelService, setShowCancelService] = useState({
    show: false,
    caseSelected: '',
    cancelOptions: [],
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    resetField: resetField2,
    reset: reset2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.CreateIncidenceSchema(t)),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    getValues: getValues3,
    resetField: resetField3,
    watch: watch3,
    setError: setError3
  } = useForm({
    resolver: yupResolver(schemas.CreateAVFormSchema(t)),
  });

  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    control: control4,
  } = useForm({
    resolver: yupResolver(schemas.ScheduleServiceSchema(t)),
  });

  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [dollarRate, setDollarRate] = useState('');
  const [reason, setReason] = useState('');
  const [showSMSModal, setShowSMSModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [showSMSTowModal, setShowSMSTowModal] = useState({
    isOpen: false,
    provider: '',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [selectedBike, setSelectedBike] = useState('');
  const [caseInfo, setCaseInfo] = useState();
  const [fileUploaded, setFileUploaded] = useState([]);
  const [availableServices, setAvailableServices] = useState({
    selected: '',
    available: [],
  });
  const [showCraneCounterModal, setShowCraneCounterModal] = useState({
    show: false,
    src: '',
    zoom: 1,
  });
  const [openPayModal, setOpenPayModal] = useState(false);
  const [serviceHistory, setServiceHistory] = useState([]);
  const [errorsDropzone, setErrorsDropzone] = useState({});
  const [cashBackImage, setCashBackImage] = useState([]);
  const [clientSearch, setClientSearch] = useState({
    searchParam: '',
    options: [],
    isOpen: false,
    selected: '',
    exists: true,
  });

  const [_case, setCase] = useState({
    selected: '',
  });
  const [origin, setOrigin] = useState({
    lat: '',
    lng: '',
    address: '',
  });
  const [destiny, setDestiny] = useState({
    lat: '',
    lng: '',
    address: '',
  });

  const [route, setRoute] = useState({
    distance: '',
    time: '',
  });

  let history = useHistory();
  // Function that handles the logout request

  const scheduleService = handleSubmit4(async data => {
    let fecha = moment.utc(data?.date).format('YYYY-MM-DD');
    let date = new Date(fecha + 'T' + data?.time);
    date = moment.utc(date).format();

    let dataToPost = {
      date: date,
      productId: availableServices?.selected?._id,
      reason: reason,
    };

    if (
      !reason &&
      [afiliatedServices?.crane, particularServices?.crane]?.includes(
        availableServices?.selected?.code
      )
    ) {
      notify('Debe indicar un motivo de selección de la grúa', 'info');
      return null;
    }

    if (!isLoading) {
      setIsloading(true);
      caseService
        .scheduleCase(filterParams(dataToPost), _case?.selected?._id)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Servicio agendado con exito`, 'success');

            if (
              [afiliatedServices?.crane, particularServices?.crane].includes(
                availableServices?.selected?.code
              )
            ) {
              history.push('/services');
              setIsloading(false);
            } else {
              openSMSModal();
              setShowSchedule(false);
              setIsloading(false);
            }
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  const createAffiliatedCase = handleSubmit2(async data => {
    let dataToPost = { ...data };

    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    if (isValidMongoId(dataToPost?.originLocationStatus)) {
      dataToPost.originLocationId = watch2('originLocationStatus');
      dataToPost.originLocationStatus = addressEnum?.GARAGE;
    }

    if (isValidMongoId(dataToPost?.destinyLocationStatus)) {
      dataToPost.destinyLocationId = watch2('destinyLocationStatus');
      dataToPost.destinyLocationStatus = addressEnum?.GARAGE;
    }

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    dataToPost.membership = clientSearch?.selected?._id;
    if (!isLoading) {
      setIsloading(true);
      caseService
        .createCase(filterParams(dataToPost))
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso creado con exito`, 'success');
            getAvailableProducts(response?.data?.case);
            setCase(prevState => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  const editAffiliatedCase = handleSubmit2(async data => {
    let dataToPost = { ...data };
    dataToPost.originLatitude = origin?.lat.toString();
    dataToPost.originLongitude = origin?.lng.toString();

    dataToPost.destinyLatitude = destiny?.lat.toString();
    dataToPost.destinyLongitude = destiny?.lng.toString();

    if (isValidMongoId(dataToPost?.originLocationStatus)) {
      dataToPost.originLocationId = watch2('originLocationStatus');
      dataToPost.originLocationStatus = addressEnum?.GARAGE;
    }

    if (isValidMongoId(dataToPost?.destinyLocationStatus)) {
      dataToPost.destinyLocationId = watch2('destinyLocationStatus');
      dataToPost.destinyLocationStatus = addressEnum?.GARAGE;
    }

    // Datos utilizados unicamente para manejar logica del form
    delete dataToPost?.isOriginBasement;
    delete dataToPost?.withOutDestinyAddress;

    // Unicamente para casos particulares
    dataToPost.membership = clientSearch?.selected?._id;

    if (!isLoading) {
      setIsloading(true);
      caseService
        .editCase(filterParams(dataToPost), _case?.selected?._id)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`Caso editado con exito`, 'success');
            getAvailableProducts(response?.data?.case);
            setCase(prevState => {
              return {
                ...prevState,
                selected: response?.data?.case,
              };
            });
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  // Recalculate distance if origin or destny changes
  const forceRecalulateDistance = (e, value) => {
    if (value === addressStatus?.ORIGIN) {
      setValue2('originLocationStatus', addressEnum.OTHER);
    }
    if (value === addressStatus?.DESTINY) {
      setValue2('destinyLocationStatus', addressEnum.OTHER);
    }

    setValue2('caseKm', '');
  };

  const clearDestinyData = () => {
    resetField2('destinyLocationStatus');
    resetField2('destinyState');
    resetField2('destinyCity');
    resetField2('destinyAddress');
  };

  // Precarga el formulario de casos con la info por defecto
  const preloadCaseForm = membership => {
    // setValue2('contactNumberPrefix', membership?.customer?.phonePrefix);
    // setValue2('contactNumber', membership?.customer?.phoneNumber);
    setValue2('originLocationStatus', afiliatedAddressOptions[2]?._id);
    setValue2('isOriginBasement', false);
    setValue2('destinyLocationStatus', afiliatedAddressOptions[2]?._id);
  };

  const getMembershipById = membershipId => {
    membershipService
      .getMembershipById(membershipId, 'operations')
      .then(response => {
        if (response.status === 200) {
          setClientSearch(prevState => {
            return {
              ...prevState,
              selected: response?.data?.membership,
              isOpen: false,
            };
          });
          getLastServices(response?.data?.membership?._id);
          preloadCaseForm(response?.data?.membership);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const selectClient = option => {
    getMembershipById(option?.membershipId);
  };

  const handleClientSearchChange = e => {
    const { value, name } = e.target;
    reset2();
    setCase({ selected: '' });
    setAvailableServices({
      selected: '',
      available: [],
    });
    setClientSearch(prevState => {
      return {
        ...prevState,
        [name]: value,
        selected: '',
        // isOpen: false,
      };
    });
  };

  const getMembership = searchParam => {
    if (!searchParam) {
      notify('Ingresar campos para realizar la busqueda', 'info');
      return null;
    }
    setIsloading(true);
    membershipService
      .getMembership(searchParam)
      .then(response => {
        if (response.status === 200) {
          setClientSearch(prevState => {
            return {
              ...prevState,
              options: response?.data?.memberships,
              isOpen: true,
            };
          });
          setIsloading(false);
        } else {
          notify(`${response.message}`, 'error');
          setIsloading(false);
        }
      })
      .catch(error => {
        setIsloading(false);
      });
  };

  const getAvailableProducts = _case => {
    const params = {
      membershipId: _case?.membership,
      originCityId: _case?.originCity,
      distance: parseFloat(_case?.caseKm),
    };

    membershipProductsService
      .getAvailableProducts(params)
      .then(response => {
        if (response.status === 200) {
          let availableServices = response?.data?.availableServices;
          if (watch2('withOutDestinyAddress')) {
            availableServices = availableServices.filter(
              a =>
                ![particularServices.crane, afiliatedServices.crane].includes(
                  a?.code
                )
            );
          }
          setAvailableServices(prevState => {
            return {
              ...prevState,
              selected: '',
              available: [...availableServices],
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const getCaseDetail = caseId => {
    caseService
      .getCaseById(_case?.selected?._id)
      .then(response => {
        if (response.status === 200) {
          setCaseInfo(response?.data?.case);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const openSMSModal = () => {
    getDollarRate();
    let [provider] = providersOptions.filter(p => p?._id === selectedBike);
    setShowSMSModal(prevState => {
      return {
        ...prevState,
        isOpen: !showSMSModal?.isOpen,
        provider: provider,
      };
    });
  };

  const openSMSTowModal = () => {
    setShowSMSTowModal(prevState => {
      return {
        ...prevState,
        isOpen: !showSMSTowModal?.isOpen,
      };
    });
  };

  const setBsRate = e => {
    const { name, value } = e?.target;

    if (!dollarRate) {
      setbsDisabled(false);
    }

    if (name.includes('amount')) {
      setValue3(
        name.replace('amount', 'amountBsS'),
        (value * dollarRate).toFixed(2)
      );
    }
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then(response => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setbsDisabled(true);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(error => { });
  };

  const selectService = service => {
    getCaseDetail();
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach(e => {
      e.selected = false;
      if (e._id === service._id) {
        e.selected = true;
      }

      setAvailableServices(prevState => {
        return {
          ...prevState,
          selected: service,
          available: [...services],
        };
      });
    });
  };

  const getStates = () => {
    statesService
      .getStates()
      .then(response => {
        if (response.status === 200) {
          setStatesOptions(response?.data?.states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(error => { });
  };

  const getCities = (e, reference) => {
    forceRecalulateDistance();
    statesService
      .getCities(e?.target?.value ? e?.target?.value : e)
      .then(response => {
        if (response.status === 200) {
          if (reference === referenceLocation?.ORIGIN) {
            setOriginCitiesOptions(response?.data?.cities);
          }
          if (reference === referenceLocation?.DESTINY) {
            setDestinyCitiesOptions(response?.data?.cities);
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(error => { });
    return true;
  };

  const getGarageLocation = () => {
    garageService
      .getGarageAddress()
      .then(response => {
        if (response.status === 200) {
          setAddressOption([
            { name: addressEnum?.OWN, _id: addressEnum?.OWN },
            ...response?.data?.addresses,
            { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
          ]);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };

  const getFaults = () => {
    faultsService
      .getFaults()
      .then(response => {
        if (response.status === 200) {
          setFaultsOptions(response?.data?.newFaults);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(error => { });
  };

  const validateCashBackImages = () => {
    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let canPost = true


    setErrorsDropzone((prevState) => {
      return {
        ...prevState,
        error1: false,
        error2: false,
        error3: false,
      };
    });

    for (let i = 1; i <= cashbackMethodsQuantity; i++) {

      const requiredMetods = paymentMethods?.filter((method) => [metodosDePago?.CASH_BS, metodosDePago?.CASH_EUR, metodosDePago?.CASH_USD, metodosDePago?.PUNTO_DE_VENTA]?.includes(method?.name))
      const method = filterOptionById(requiredMetods, watch3("returnedMethod" + i))

      if (method && !watch3(`returnedReference${i}`)) {
        setError3(`returnedReference${i}`, { type: 'custom', message: 'Obligatorio' });
        canPost = false

      }

      if (!cashBackImage[`image${i}`] && method) {
        notify(`Adjunte todos los comprobantes de pago de ${method?.name}`, 'error');
        canPost = false
        setErrorsDropzone((prevState) => {
          return {
            ...prevState,
            [`error${i}`]: true,
          };
        });
      }
    }
    return canPost
  }



  const calculateCashBack = () => {

    if (parseFloat(watch3("paid")) > parseFloat(watch3("amount0"))) {
      setValue3("paidBsS", (parseFloat(watch3("paid")) * dollarRate)?.toFixed(2))

      if (!watch3("cashbackMethodsQuantity")) {
        setValue3("cashbackMethodsQuantity", 1)
        let amountReturned = parseFloat(watch3(`amount0`)) - parseFloat(watch3("paid"))
        if (parseFloat(watch3(`amount1IGTF`))) {
          amountReturned = amountReturned + parseFloat(watch3(`amount1IGTF`))
        }
        amountReturned = amountReturned?.toFixed(2)
        setValue3("amountReturned", Math.abs(amountReturned))
        setValue3("amountReturnedBsS", (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
        setValue3(`returnedAmount1`, Math.abs(amountReturned))
        setValue3(`returnedAmountBsS1`, (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
      }
    }
  }

  const validateCashBackTotal = () => {
    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch3(`amountReturned`))
    let total = 0
    for (let i = 1; i <= cashbackMethodsQuantity; i++) {
      total = total + parseFloat(watch3(`returnedAmount${i}`))
    }

    if (total !== amountReturned) {
      notify('El la suma de los montos debe ser igual al monto a entregar', 'error');
      return false
    }
    if (!watch3("returnedMethod1")) {
      // setError3(`returnedMethod1`, { type: 'custom', message: 'Obligatorio' });
      return false
    }
    else {
      return true
    }
  }

  const handleCashbackMethodsQuantity = () => {
    for (let i = 1; i <= 3; i++) {
      setValue3(`returnedMethod${i}`, "")
      setValue3(`returnedAmount${i}`, "")
      setValue3(`returnedAmountBsS${i}`, "")
      setCashBackImage((prevState) => {
        return {
          ...prevState,
          [`image${i}`]: ""
        };
      });
    }

    if (watch3("cashbackMethodsQuantity") === "1") {
      setValue3(`returnedAmount1`, watch3("amountReturned"))
      setValue3(`returnedAmountBsS1`, (parseFloat(watch3("amountReturned")) * dollarRate)?.toFixed(2))
    }
  }

  const handleCashbackBs = (index) => {

    let cashbackMethodsQuantity = parseFloat(watch3("cashbackMethodsQuantity"))
    let amountReturned = parseFloat(watch3(`amountReturned`))

    setValue3(`returnedAmountBsS${index}`, (parseFloat(watch3(`returnedAmount${index}`)) * dollarRate)?.toFixed(2))

    // Automatically set the amount for the last method
    if (index === cashbackMethodsQuantity - 1) {
      let paid = 0
      for (let i = 1; i < cashbackMethodsQuantity; i++) {
        paid = paid + parseFloat(watch3(`returnedAmount${i}`))
      }

      if (amountReturned - paid <= 0) {
        notify('El la suma de los montos no puede superar el vuelto a entregar', 'error');
        for (let j = 1; j <= cashbackMethodsQuantity; j++) {
          setValue3(`returnedAmount${j}`, 0)
          setValue3(`returnedAmountBsS${j}`, 0)
        }
        return null
      }

      setValue3(`returnedAmount${cashbackMethodsQuantity}`, (amountReturned - paid)?.toFixed(2))
      setValue3(`returnedAmountBsS${cashbackMethodsQuantity}`, (parseFloat(watch3(`returnedAmount${cashbackMethodsQuantity}`)) * dollarRate)?.toFixed(2))
    }
  }


  const handleDrop = (event, index) => {
    let file = ""
    // File added on drop
    if (event?.dataTransfer?.files) {
      file = Array?.from(event?.dataTransfer?.files)[0];
    }

    let maxWeight = 5 //Peso en MB

    // File added on Click
    if (event?.target?.files?.length > 0) {
      file = event?.target?.files[0];
    }

    if (!['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(file.type)
    ) {
      notify('Agregar un formato valido de imagen', 'error');
      return null;
    }
    if (file.size * 0.000001 > maxWeight) {
      notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
    }
    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: file
      };
    });
  };


  const removeFile = (index) => {

    setCashBackImage((prevState) => {
      return {
        ...prevState,
        [`image${index}`]: ""
      };
    });

  }


  // Function that preloads garage location as origin or destiny
  const handleLocation = (location, reference) => {
    if (
      location &&
      ![addressEnum?.OWN, addressEnum?.OTHER]?.includes(location)
    ) {
      let [filteredOption] = addressOption.filter(a => a?._id === location);
      setValue2(`${reference}State`, filteredOption?.stateId);
      statesService
        .getCities(filteredOption?.stateId)
        .then(response => {
          if (response.status === 200) {
            if (reference === referenceLocation?.ORIGIN) {
              setOriginCitiesOptions(response?.data?.cities);
            }
            if (reference === referenceLocation?.DESTINY) {
              setDestinyCitiesOptions(response?.data?.cities);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });

      setTimeout(() => {
        setValue2(`${reference}City`, filteredOption?.cityId);
        setValue2(`${reference}Address`, filteredOption?.address);
      }, 1200);
    }
  };

  // Function that preloads garage location as origin or destiny
  const selectGarage = (e, reference) => {
    forceRecalulateDistance();
    const { value } = e?.target;
    // Preload GARAGE data
    if (value && ![addressEnum?.OWN, addressEnum?.OTHER]?.includes(value)) {
      handleLocation(value, reference);
    }
    // Own Value
    if (value === addressEnum?.OWN) {
      Promise.allSettled([
        getCities(clientSearch?.selected?.vehicle?.stateId, reference),
      ]).then(r => {
        setTimeout(() => {
          setValue2(
            `${reference}State`,
            clientSearch?.selected?.vehicle?.stateId
          );
          setValue2(
            `${reference}City`,
            clientSearch?.selected?.vehicle?.cityId
          );
          setValue2(
            `${reference}Address`,
            capitalize(clientSearch?.selected?.vehicle?.address)
          );
        }, 1500);
      });
    }
  };

  const unSelectService = () => {
    let services = availableServices?.available;
    setSelectedBike('');
    services.forEach(e => {
      e.selected = false;
      setAvailableServices(prevState => {
        return {
          ...prevState,
          selected: '',
          available: [...services],
        };
      });
    });
  };

  const openMapModal = () => {
    if (
      !getValues2('originState') ||
      !getValues2('originCity') ||
      !getValues2('originAddress')
    ) {
      notify('Ingrese datos de origen', 'error');
      return null;
    }
    if (
      !getValues2('destinyState') ||
      !getValues2('destinyCity') ||
      !getValues2('destinyAddress')
    ) {
      notify('Ingrese datos de destino', 'error');
      return null;
    }

    let [originState] = statesOptions.filter(
      s => s._id === getValues2('originState')
    );
    let [originCity] = originCitiesOptions.filter(
      c => c._id === getValues2('originCity')
    );

    let [destinyState] = statesOptions.filter(
      s => s._id === getValues2('destinyState')
    );
    let [destinyCity] = originCitiesOptions.filter(
      c => c._id === getValues2('destinyCity')
    );

    setOrigin({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(originState?.name) +
        ' ' +
        capitalize(originCity?.name) +
        ' ' +
        getValues2('originAddress'),
    });

    setDestiny({
      lat: '',
      lng: '',
      address:
        'Venezuela ' +
        capitalize(destinyState?.name) +
        ' ' +
        capitalize(destinyCity?.name) +
        ' ' +
        getValues2('destinyAddress'),
    });
    setMapModalOpen(true);
  };

  const handleCloseModal = () => {
    setValue2('caseKm', route?.distance);
    setValue2('estimatedTime', route?.time);
    setMapModalOpen(false);
    setRoute({
      time: '',
      distance: '',
    });
  };

  const selectBikeProvider = e => {
    setSelectedBike(e?.target?.value);
  };

  const getAVs = () => {
    providersService
      .getAVs()
      .then(response => {
        if (response.status === 200) {
          setProvidersOptions(response?.data?.providers);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const startTowService = () => {
    if (!reason) {
      notify('Debe indicar un motivo de selección de la grúa', 'info');
      return null;
    }

    const data = {
      case: _case?.selected?._id,
      reason: reason,
    };

    if (
      availableServices?.selected?.code === afiliatedServices?.craneExtended
    ) {
      data.useExtendedTow = true;
    }

    if (!isLoading) {
      setIsloading(true);
      stagesService
        .createInitialTowStage(data)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            history.push('/services');
            notify(`Servicio creado en búsqueda de grúa`, 'success');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  };

  const refreshAfterCancel = () => {
    history.push('/services');
  };

  const registerPayment = () => {
    setValue3(
      'contact',
      clientSearch?.selected?.customer?.phonePrefix +
      '-' +
      clientSearch?.selected?.customer?.phoneNumber
    );
    setValue3('vehicleYear', clientSearch?.selected?.vehicle?.year);
    setValue3(
      'vehicleModel',
      capitalize(clientSearch?.selected?.vehicle?.model)
    );
    setValue3(
      'vehicleBrand',
      capitalize(clientSearch?.selected?.vehicle?.brand)
    );
    setValue3('vehiclePlate', exists(clientSearch?.selected?.vehicle?.plate));
    setValue3(
      'vehicleColor',
      capitalize(clientSearch?.selected?.vehicle?.color)
    );
    setValue3('estimatedTime', exists(_case?.selected?.estimatedTime));
    setOpenPayModal(true);
  };

  // Load payemnt methods and refactor JSON
  let loadPaymentForms = params => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 0; i < parseInt(getValues3('paymentMethods')); i++) {
      payments.push({
        method: params[`method` + i.toString()],
        reference: params[`reference` + i.toString()],
        amount: params[`amount` + i.toString()],
        amountBsS: params[`amountBsS` + i.toString()],
      });
    }

    // "REmove unwanted params from JSON"
    let words = ['method', 'reference', 'amount', 'amountBsS', 'paymentMethod'];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };


  const validateTotalPayment = (data) => {

    if (
      (parseFloat(watch3("pvp")) >= 0 || parseFloat(watch3("amountHandling")) >= 0 || parseFloat(watch3("amountExcess")) >= 0) && ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(watch3('paymentStatus'))
    ) {
      let totalSum = 0;
      for (let i = 0; i < watch3("paymentMethods"); i++) {
        totalSum = totalSum + parseFloat(watch3(`amount${i}`));
      }
      if (totalSum !== (parseNull(watch3('pvp')) + parseNull(watch3('amountHandling')) + parseNull(watch3('amountExcess')))) {
        notify(
          'Monto a pagar por el cliente y la suma de los pagos no coinciden',
          'info'
        );
        return false;
      } else return true
    }
    else return true
  }

  const createAVService = handleSubmit3(async (data) => {

    let isCash = watch3("method0") === filterOption(paymentMethods, metodosDePago?.CASH_USD)?._id

    if (!validateTotalPayment(data)) {
      return null
    }

    if (isCash) {
      calculateCashBack()

      if (parseFloat(watch3("cashbackMethodsQuantity")) > 0) {
        // Validate the total of the cashback
        // Validate that all images are up
        if (!validateCashBackTotal() || !validateCashBackImages()) {
          return null
        }
      }
    }


    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    delete dataToPost?.contact;
    delete dataToPost?.vehicleBrand;
    delete dataToPost?.vehicleColor;
    delete dataToPost?.vehicleYear;
    delete dataToPost?.vehicleModel;
    delete dataToPost?.estimatedTime;
    delete dataToPost?.vehiclePlate;

    dataToPost.product = availableServices?.selected?._id;
    dataToPost.case = _case?.selected?._id;
    dataToPost.provider = selectedBike; // // Unicamente para casos particulares

    let fileToPost = appendFile(dataToPost);
    if (!isLoading && fileToPost) {
      setIsloading(true);
      servicesService
        .createAVService(fileToPost)
        .then(response => {
          if ([200, 201].includes(response.status)) {
            notify(`AV creado con exito`, 'success');
            history.push('/services');
            setIsloading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  const changePaymentStatus = () => {
    setValue3('authorizedBy', '');
    if (watch3('paymentStatus') === PaymentStatus?.COURTESY) {
      setValue3('pvp', 0);
      return null;
    }
    if (watch3('paymentStatus') === PaymentStatus?.N_A) {
      setValue3('pvp', 0);
      return null;
    } else {
      setValue3('pvp', '');
      return null;
    }
  };

  // If the payment method is set to 0 clear the form for the N payment methods
  const restorePaymentMethods = () => {
    if (parseFloat(watch3('pvp')) === 0) {
      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        resetField3(`method${i}`);
        resetField3(`reference${i}`);
        resetField3(`paymentMethod${i}`);
        resetField3(`amount${i}`);
        resetField3(`amountBsS${i}`);
      }
      resetField3('paymentMethods');
    }
    // setValue3()
  };

  const validateMaxPaymentMethods = () => {
    if (watch3('paymentMethods') > 5) {
      setValue3('paymentMethods', 5);
      notify('El limite permitido es de 5 métodos de pago', 'info');
    }
  };

  const appendFile = data => {
    const formData = new FormData();
    formData.append('case', data?.case);
    formData.append('product', data?.product);
    formData.append('provider', data?.provider);
    formData.append('price', data?.price);
    formData.append('pvp', data?.pvp);
    formData.append('paymentStatus', data?.paymentStatus);
    if (data?.authorizedBy) {
      formData.append('authorizedBy', data?.authorizedBy);
    }

    // SI es cuentas por cobrar o NA no anexar datos ni comprobantes de pago

    if (
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      for (var i = 0; i < data?.payments.length; i++) {
        formData.append(`payments[${i}][amount]`, data?.payments[i].amount);
        formData.append(
          `payments[${i}][amountBsS]`,
          data?.payments[i].amountBsS
        );
        formData.append(`payments[${i}][method]`, data?.payments[i].method);
        formData.append(
          `payments[${i}][reference]`,
          data?.payments[i].reference
        );
      }

      for (let i = 0; i < parseInt(watch3('paymentMethods')); i++) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch3('paymentMethods')) &&
      parseFloat(watch3('paymentMethods')) > 0 &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }
    if (
      parseFloat(watch3('pvp')) &&
      ![PaymentStatus?.N_A, PaymentStatus?.CUENTA_POR_COBRAR]?.includes(
        watch3('paymentStatus')
      )
    ) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum !== parseFloat(watch3('pvp'))) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    // Vueltos
    for (let j = 0; j < parseFloat(watch3("cashbackMethodsQuantity")); j++) {
      formData.append(`paymentChanges[${j}][paid]`, data[`paid`]);
      formData.append(`paymentChanges[${j}][method]`, data[`returnedMethod${j + 1}`]);
      formData.append(`paymentChanges[${j}][amount]`, data[`returnedAmount${j + 1}`]);
      formData.append(`paymentChanges[${j}][amountBsS]`, data[`returnedAmountBsS${j + 1}`]);
      if (data[`returnedReference${j + 1}`]) {
        formData.append(`paymentChanges[${j}][ref]`, data[`returnedReference${j + 1}`]);
      }
      formData.append(`paymentChanges[${j}][hasEvidence]`, data[`returnedReference${j + 1}`] ? true : false);
      if (cashBackImage[`image${j + 1}`]) {
        formData.append(`paymentChangeEvidence`, cashBackImage[`image${j + 1}`]);
      }
    }



    return formData;
  };

  const handleCancelService = incidence => {
    if (incidence) {
      membershipService
        .getCancelReason(incidence?.caseType)
        .then(response => {
          if (response.status === 200) {
            setShowCancelService({
              cancelOptions: response?.data?.newCancellationReasons,
              show: !showCancelService.show,
              selected: incidence ? incidence : '',
            });
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(error => { });
    } else {
      setShowCancelService({
        cancelOptions: [],
        show: !showCancelService.show,
        selected: incidence ? incidence : '',
      });
    }
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then(response => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
          setFilteredMethods(response?.data?.filter((d) => d?.name !== metodosDePago?.CASH_USD));
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const getLastServices = membershipId => {
    membershipService
      .getLastServices(membershipId)
      .then(response => {
        if (response.status === 200) {
          setServiceHistory(response?.data?.latestServices);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const getInternalTowServices = () => {
    let params = {
      type: 'all',
    };

    providersService
      .getInternalTowServices(params)
      .then(response => {
        if (response.status === 200) {
          setShowCraneCounterModal(prevState => {
            return {
              ...prevState,
              data: response?.data,
            };
          });
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(error => { });
  };

  const openDocumentModal = (src, service) => {
    setShowCraneCounterModal(prevState => {
      return {
        ...prevState,
        show: !showCraneCounterModal?.show,
        src: !showCraneCounterModal?.src ? src : '',
        service: service,
      };
    });
  };

  const zoomDocumentModal = zoom => {
    setShowCraneCounterModal(prevState => {
      return {
        ...prevState,
        zoom: zoom,
      };
    });
  };

  const handleReason = e => {
    setReason(e?.target?.value);
  };

  useEffect(() => {
    getInternalTowServices();
    getPaymentMethods();
    getStates();
    getFaults();
    getAVs();
    getGarageLocation();
  }, []);

  return (
    <>
      <CraneCounter
        showCraneCounterModal={showCraneCounterModal}
        openDocumentModal={openDocumentModal}
        zoomDocumentModal={zoomDocumentModal}
      />
      <LoadSpinner label={'Cargando'} show={isLoading} />
      <MessageAVModal
        showSMSModal={showSMSModal}
        handleClose={openSMSModal}
        caseInfo={caseInfo}
        action={registerPayment}
        service={availableServices?.selected}
      />
      <MessageTowModal
        showSMSModal={showSMSTowModal}
        handleClose={openSMSTowModal}
        caseInfo={caseInfo}
        action={startTowService}
      />
      <CreateAVModal
        show={openPayModal}
        register={register3}
        openPayModal={openPayModal}
        handleClose={() => setOpenPayModal()}
        createAVService={createAVService}
        errors={errors3}
        options={{
          paymentMethods: paymentMethods,
          PaymentStatusOptions: PaymentStatusOptions,
          authorizedByOptions: authorizedByOptions,
          filteredMethods: filteredMethods,
        }}
        onChange={{
          changePaymentStatus: changePaymentStatus,
          restorePaymentMethods: restorePaymentMethods,
          setBsRate: setBsRate,
          validateMaxPaymentMethods: validateMaxPaymentMethods,
          handleCashbackMethodsQuantity: handleCashbackMethodsQuantity,
          handleCashbackBs: handleCashbackBs
        }}
        watch={watch3}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
        handleDrop={handleDrop}
        cashBackImage={cashBackImage}
        removeFile={removeFile}
        errorsDropzone={errorsDropzone}

      />
      <CancelCaseModal
        showCancelCase={showCancelService}
        handleClose={() => handleCancelService()}
        refresh={refreshAfterCancel}
      />
      <Header />
      <ScheduleServicesModal
        show={showSchedule}
        handleClose={() => setShowSchedule(false)}
        register={register4}
        control={control4}
        errors={errors4}
        action={scheduleService}
        t={t}
      />
      <div className="container">
        <div className="card incidence-card">
          <div className="buttons-container">
            <span></span>
            <ActionButton
              label={`Incidencia Particular`}
              action={() => history.push('/incidence/create/particular')}
            />
          </div>
          <div className="title-center-component">
            <h4>Nueva Incidencia</h4>
            <h5>{`Cliente Afiliado`}</h5>
          </div>
          <IncidenceSearch
            clientSearch={clientSearch}
            onChange={handleClientSearchChange}
            search={getMembership}
            selectClient={selectClient}
            type={clientTypes.AFFILIATED}
          />

          {clientSearch?.selected && (
            <ClientDetail membership={clientSearch?.selected} />
          )}

          {clientSearch?.selected &&
            clientSearch?.selected !== notFound &&
            [
              membershipStatus?.ACTIVO,
              membershipStatus['EN ESPERA'],
              membershipStatus['ESPERANDO VENCIMIENTO'],
              membershipStatus?.['POR RENOVACION'],
              membershipStatus?.RENOVADO,
            ].includes(clientSearch?.selected?.status?.membershipStatus) && (
              <IncidenceDetailForm
                register={register2}
                createCase={createAffiliatedCase}
                editCase={editAffiliatedCase}
                errors={errors2}
                setValue={setValue2}
                getValues={getValues2}
                watch={watch2}
                onChange={{
                  getCities: getCities,
                  selectGarage: selectGarage,
                  clearDestinyData: clearDestinyData,
                  forceRecalulateDistance: forceRecalulateDistance,
                }}
                options={{
                  statesOptions: statesOptions,
                  originCitiesOptions: originCitiesOptions,
                  destinycitiesOptions: destinycitiesOptions,
                  faultsOptions: faultsOptions,
                  addressOption: addressOption,
                }}
                openMapModal={openMapModal}
                _case={_case?.selected}
              />
            )}

          <MapModal
            show={mapModalOpen}
            handleClose={handleCloseModal}
            origin={origin}
            setOrigin={setOrigin}
            destiny={destiny}
            setDestiny={setDestiny}
            route={route}
            setRoute={setRoute}
          />

          {_case?.selected && (
            <AvailableServices
              availableServices={availableServices}
              selectService={selectService}
            />
          )}

          {_case?.selected && availableServices?.selected && (
            <SelectedServices
              providersOptions={providersOptions}
              availableServices={availableServices}
              unSelectService={unSelectService}
              value={selectedBike}
              onChange={selectBikeProvider}
              openSMSModal={openSMSModal}
              openSMSTowModal={openSMSTowModal}
              handleReason={handleReason}
              reason={reason}
              clientType={clientTypes?.AFFILIATED}
            />
          )}
          {_case?.selected && availableServices?.selected && (
            <HandleCaseButtons
              setShowSchedule={setShowSchedule}
              startTowService={startTowService}
              selectedBike={selectedBike}
              service={availableServices?.selected}
              _case={_case?.selected}
              handleCancelService={handleCancelService}
              registerPayment={registerPayment}
              openModals={{
                openSMSTowModal: openSMSTowModal,
                openSMSModal: openSMSModal,
              }}
              bikerData={getOption(selectedBike, providersOptions)}
            />
          )}

          {clientSearch?.selected && clientSearch?.selected !== notFound && (
            <ServiceHistory serviceHistory={serviceHistory} />
          )}
        </div>
      </div>
    </>
  );
};

export const CaseDetail = props => {
  const { title, selectedCase } = props;
  return (
    <div className="incidence-table-section-container">
      <div className="filter-button-container"></div>
      <div className="buttons-container">
        <h6>{title}</h6>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <p>
            <b>Avería: </b> {selectedCase?.fault?.description} <br />
            <b>Caso Nro: </b> {selectedCase?.caseId}
            <br />
            {/* <b>Código de servicio: </b> CCCT15-0024435 */}
            <b>Operador: </b>
            {selectedCase?.user?.firstName} {selectedCase?.user?.lastName}
          </p>
        </div>
      </div>
    </div>
  );
};
