import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const statesService = {
  getStates: async (data) => {
    const response = await authInstance
      .get(`${url}/states/list`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getCities: async (stateId) => {
    const response = await authInstance
      .get(`${url}/cities/list?stateId=${stateId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
