import { useState, useEffect, useRef } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  ExportablesReviewTable
} from '../../common/Tables/Tables';
import {
  reviewsTable
} from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { caseService } from '../../../services/caseService';
import { FilterDatePicker } from '../../common/Forms/Input/Input';
import moment from 'moment';
import { LoadSpinner } from '../../common/LoadSpinner/LoadSpinner';

import './Reviews.scss';

const itemsPerpage = [10, 25, 50, 100, 500, 1000, 2500, 5000];


export const Reviews = () => {
  const tableRef = useRef(null);

  const [sinceDate, setSinceDate] = useState(
    new Date(Date.now() - 30 * 3600 * 1000 * 24)

  );
  const [untilDate, setUntilDate] = useState(new Date());
  const [exportablesDataTable, setExportablesDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isInternal: true,
    isLoading: true,
    type: '',
  });
  console.log('exportablesDataTable:', exportablesDataTable)


  const handleSinceDateChange = (e) => {
    if (e > untilDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setSinceDate(e);
    }
  };
  const handleUntilDateChange = (e) => {
    if (e < sinceDate) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setUntilDate(e);
    }
  };

  const selectitemsPerpage = (event) => {
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * exportablesDataTable?.limit,
      };
    });
  };



  const getReviewTable = (type) => {


    let params = {
      dateFrom: moment.utc(sinceDate).format('YYYY-MM-DD'),
      dateTo: moment.utc(untilDate).format('YYYY-MM-DD'),
      limit: exportablesDataTable?.limit,
      offset: exportablesDataTable?.offset,
      // page: exportablesDataTable?.page,
      type: type ? type : exportablesDataTable?.type,
    };

    setExportablesDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    caseService
      .getExpressCheckupCases(params)
      .then((response) => {
        if (response.status === 200) {
          setExportablesDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.cases,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setExportablesDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setExportablesDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };


  useEffect(() => {
    getReviewTable();
  }, [
    exportablesDataTable?.page,
    exportablesDataTable?.offset,
    exportablesDataTable?.limit,
    exportablesDataTable?.type,
    exportablesDataTable?.sinceDate,
    exportablesDataTable?.untilDate,
    sinceDate,
    untilDate,
  ]);


  return (
    <>
      <LoadSpinner show={exportablesDataTable.isLoading} />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Formulario de revisión de vehículo: {exportablesDataTable?.count}
          </h1>
          {/* <ActionButton label={'Exportar'} action={() => exportTable()} /> */}
        </div>
        <div className="breadcrumb-container">
          <div className="exportables-buttons-container">
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Desde'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleSinceDateChange}
              value={sinceDate}
            />{' '}
            <FilterDatePicker
              bootstrap={'col-4'}
              label={'Hasta'}
              type={'date'}
              name={'untilDate'}
              placeholder={''}
              onChange={handleUntilDateChange}
              value={untilDate}
            />
            <div style={{ marginTop: '12px' }}>
              <SelectPerPage
                label={'Registros por página'}
                options={itemsPerpage}
                value={exportablesDataTable?.limit}
                onChange={selectitemsPerpage}
              />
            </div>
          </div>
          <span></span>
        </div>

        {!exportablesDataTable.isLoading && (
          <ExportablesReviewTable
            header={reviewsTable}
            handlePageChange={handlePageChange}
            data={exportablesDataTable}
            type={exportablesDataTable?.type}
            tableRef={tableRef}
          />
        )}
      </div>
    </>
  );
};
