import { useEffect, useState } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import {
  LevelOptions,
  ConditionOptions,
  LeakOptions,
  HealthOptions,
  caseType
} from '../../../assets/helpers/options';
import { caseService } from '../../../services/caseService';
import { notify } from '../../../assets/helpers/toast';
import { CardCaseInfoForm } from '../../common/Forms/Form.jsx';
import { useHistory, useParams } from 'react-router-dom';
import { capitalize, filterParams } from '../../../assets/helpers/funciones';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../assets/schemas/schemas';
import moment from 'moment';
import './ExpressCheck.scss';


export const ExpressCheck = () => {


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control
  } = useForm({
    resolver: yupResolver(schemas.revisionVehicleSchema()),
  });


  let { caseId } = useParams();


  const [caseInfo, setCaseInfo] = useState();
  const [isLoading, setIsloading] = useState(false);


  const history = useHistory();

  const getCaseById = (caseId) => {
    caseService
      .getCaseById(caseId)
      .then((response) => {
        if (response.status === 200) {
          setCaseInfo(response?.data?.case);
          preloadData(response?.data?.case)
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => { });
  };


  const onSubmit = handleSubmit(async (data) => {

    let dataToPost = { ...data }

    dataToPost.caseId = caseId
    dataToPost.vehicleModel = dataToPost.vehicleModel.toUpperCase()
    dataToPost.vehicleBrand = dataToPost.vehicleBrand.toUpperCase()
    dataToPost.customerType = caseInfo?.membership ? caseType?.AFFILIATED : caseType?.PARTICULAR

    delete dataToPost.color
    delete dataToPost.provider


    if (!isLoading) {
      setIsloading(true);
      caseService
        .createExpressCheckupCase(filterParams(dataToPost))
        .then((response) => {
          if (response.status === 200) {
            notify(`Revisión realizada`, 'success');
            setIsloading(false);
            history?.push("/services")
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });


  const preloadForms = () => {
    // setValue("coolantLevel", "Bajo")
    // setValue("engineOilLevel", "Alto")
    setValue("brakeFluidLevel", "Medio")
    setValue("gearboxOilLevel", "No Aplica")
    setValue("powerSteeringFluidLevel", "Medio")
    // /as
    // setValue("engineOilCondition", "Mal")
    // setValue("coolantFluidCondition", "Regular")
    setValue("brakeFluidCondition", "Bien")
    setValue("powerSteeringFluidCondition", "Regular")
    setValue("gearboxOilCondition", "Regular")
    setValue("timingBeltCondition", "Mal")
    setValue("uniqueBeltCondition", "Bien")
    setValue("alternatorBeltCondition", "Regular")
    setValue("airConditionerBeltCondition", "Regular")
    setValue("steeringPumpBeltCondition", "Bien")
    setValue("pulleysCondition", "Bien")
    setValue("tensorCondition", "Bien")
    setValue("upperHosesCondition", "No Aplica")
    setValue("lowerHosesCondition", "No Aplica")
    setValue("thermostatCondition", "No Aplica")
    setValue("coolantTankCondition", "No Aplica")
    // 
    // setValue("valveCoverLeak", "Si")
    // setValue("oilPanLeak", "No")
    // setValue("gearboxPanLeak", "No")
    setValue("frontcrankshaftSealLeak", "No Aplica")
    setValue("rearCrankshaftSealLeak", "No Aplica")
    setValue("gearboxSealLeak", "No")
    setValue("camshaftSealLeak", "No Aplica")
    setValue("steeringHosesLeak", "No Aplica")
    setValue("steeringPumpLeak", "No Aplica")
    setValue("transferCaseLeak", "Si")
    setValue("differentialLeak", "No")
    setValue("UpperRadiatorTankLeak", "No")
    setValue("LowerRadiatorTankLeak", "Si")
    // 
    // setValue("fuelPumpHealth", "Óptima")

  }


  const preloadData = (_case) => {
    // preloadForms()

    if (_case?.membership) {
      if (_case?.services[0]?.code && _case?.services[0]?.type === "Asistencia vial") {
        setValue("serviceCode", _case?.services[0]?.code)
        setValue("provider", _case?.services[0]?.provider?.contactName)
        setValue("serviceDate", new Date(_case?.createdAt))
      }
      setValue("vehiclePlate", _case?.membership?.vehicle?.plate)
      setValue("color", capitalize(_case?.membership?.vehicle?.color))
      setValue("vehicleBrand", capitalize(_case?.membership?.vehicle?.brand))
      setValue("vehicleModel", capitalize(_case?.membership?.vehicle?.model))
      setValue("customerFirstName", capitalize(_case?.membership?.customer?.firstName))
      setValue("customerLastName", capitalize(_case?.membership?.customer?.lastName))
      setValue("customerPhonePrefix", _case?.membership?.customer?.phonePrefix)
      setValue("customerPhoneNumber", _case?.membership?.customer?.phoneNumber)
      setValue("customerId", _case?.membership?.customer?._id)
    }

    if (_case?.particularCustomer) {

      if (_case?.services[0]?.code && _case?.services[0]?.type === "Asistencia vial") {
        setValue("serviceCode", _case?.services[0]?.code)
        setValue("provider", _case?.services[0]?.provider?.contactName)
      }
      setValue("vehiclePlate", _case?.particularCustomer?.vehiclePlate)
      setValue("color", capitalize(_case?.particularCustomer?.vehicleColor))
      setValue("vehicleBrand", capitalize(_case?.particularCustomer?.vehicleBrand?.brand))
      setValue("vehicleModel", capitalize(_case?.particularCustomer?.vehicleModel?.model))
      setValue("customerFirstName", capitalize(_case?.particularCustomer?.firstName))
      setValue("customerLastName", capitalize(_case?.particularCustomer?.lastName))
      setValue("customerPhonePrefix", _case?.particularCustomer?.phonePrefix)
      setValue("customerPhoneNumber", _case?.particularCustomer?.phoneNumber)
      setValue("customerId", _case?.particularCustomer?._id)
    }
  }


  useEffect(() => {
    getCaseById(caseId);
  }, []);

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Formulario de revisión de vehículo
        </h1>
        <div className="gray-line"></div>
        <CardCaseInfoForm
          register={register}
          watch={watch}
          options={
            {
              LevelOptions: LevelOptions,
              ConditionOptions: ConditionOptions,
              LeakOptions: LeakOptions,
              HealthOptions: HealthOptions
            }
          }
          control={control}
          errors={errors}
          cancel={() => history.push("/services")}
          action={onSubmit}
        />

      </div>
    </>
  );
};




