import { providerStatus } from '../helpers/options'
import { operationsRoles } from '../helpers/roles'
import authService from '../../services/authService'
import {
  particularServices,
  afiliatedServices,
  statusHeadervalues,
  enumStatus,
  gpsStatusEnum,
} from './options'
import * as XLSX from 'xlsx'

export const rename = (str) => {
  if (str) {
    if (typeof str === 'number') {
      str = str.toString()
    }

    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  }
}

export const currencyFormat = (value) => {
  if (!value && value != 0) {
    return ''
  }

  return '$' + value.toLocaleString('es')

  // value = Number(value).toFixed(2);
  // if (value < 0) {
  //   value = value * -1;
  //   return '-$' + value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // } else {
  //   return '$' + value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }
}

export const capitalize = (str) => {
  if (str) {
    if (typeof str === 'number') {
      str = str.toString()
    }

    str = str?.toLowerCase()
    const arr = str.split(' ')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    const str2 = arr.join(' ')
    return str2
  } else return ''
}

export const translateStatus = (str) => {
  if (str === providerStatus.available) {
    return 'Disponible'
  }
  if (str === providerStatus.busy) {
    return 'Ocupado'
  } else {
    return ''
  }
}

export const translateFullStatus = (str) => {
  if (!str?.isActive) {
    return 'Inactivo'
  }

  if (str?.status === providerStatus?.available) {
    return 'Disponible'
  }
  if (str?.status === providerStatus?.busy) {
    return 'Ocupado'
  } else {
    return ''
  }
}

export const exists = (str) => {
  if (str) {
    return str
  } else return ''
}

export function format(inputDate) {
  if (inputDate) {
    // expects Y-m-d
    var splitDate = inputDate.split('-')
    if (splitDate.count == 0) {
      return null
    }

    var year = splitDate[0]
    var month = splitDate[1]
    var day = splitDate[2]

    return day + '/' + month + '/' + year
  }
}

export const fixResponseJSON = async (response) => {
  let rullResp = await response.json()
  rullResp.status = response.status
  return rullResp
}

export const filterParams = (params) => {
  for (const key in params) {
    if (params[key] === undefined || params[key] === '') {
      delete params[key]
    }
  }
  return params
}

// Function that checks if the case time has expired or soon to be and sends a notification
export const checkAlert = (caso) => {
  let start = new Date(caso?.lastStage?.createdAt)
  let now = new Date()
  var diff = now.getTime() - start.getTime() // This will give difference in milliseconds
  diff = Math.round(diff / 60000)
  if (
    diff > parseInt(process.env.REACT_APP_ALERT_TIME) &&
    diff < parseInt(process.env.REACT_APP_WARNING_TIME)
  ) {
    // notify(
    //   `${caso?.status}: ${caso?.caseId} de ${capitalize(
    //     caso?.membership?.customer?.firstName ||
    //       caso?.particularCustomer?.firstName
    //   )} ${capitalize(
    //     caso?.membership?.customer?.lastName ||
    //       caso?.particularCustomer?.lastName
    //   )} tiene ${diff} minutos sin ser atendida `,
    //   'success'
    // );
    return 'reminder'
  }

  if (diff > parseInt(process.env.REACT_APP_WARNING_TIME)) {
    // notify(
    //   `${caso?.status}: ${caso?.caseId} de ${capitalize(
    //     caso?.membership?.customer?.firstName ||
    //       caso?.particularCustomer?.firstName
    //   )} ${capitalize(
    //     caso?.membership?.customer?.lastName ||
    //       caso?.particularCustomer?.lastName
    //   )} tiene ${diff} minutos sin ser atendida `,
    //   'error'
    // );
    return 'warning'
  }
}

// Function that checks if the case time has expired or soon to be
export const checkCardTime = (caso) => {
  let start = new Date(caso?.lastStage?.createdAt)
  let now = new Date()
  var diff = now.getTime() - start.getTime() // This will give difference in milliseconds
  diff = Math.round(diff / 60000)
  if (
    diff > parseInt(process.env.REACT_APP_ALERT_TIME) &&
    diff < parseInt(process.env.REACT_APP_WARNING_TIME)
  ) {
    return 'reminder'
  }

  if (diff > parseInt(process.env.REACT_APP_WARNING_TIME)) {
    return 'warning'
  }
}
// Function that checks if the cases have expired or soon to be and paints the card
export let paintCard = (casos) => {
  let alert = ''

  for (let i = 0; i < casos?.length; i++) {
    if (checkCardTime(casos[i]) === 'reminder' && alert !== 'warning') {
      alert = checkCardTime(casos[i])
    }
    if (checkCardTime(casos[i]) === 'warning') {
      alert = checkCardTime(casos[i])
    }
  }
  return alert
}

export const msToTime = (duration) => {
  var seconds = Math.floor((duration / 1000) % 60)
  var minutes = Math.floor((duration / (1000 * 60)) % 60)
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  if (hours > 0) {
    return hours + ':' + minutes + ':' + seconds
  } else {
    return minutes + ':' + seconds
  }
}
export const checkFilterAndWarn = (casos, status) => {
  let filteredCases = casos?.filter((c) => c.status === status)
  for (let i = 0; i < filteredCases.length; i++) {
    checkAlert(filteredCases[i])
  }
  return filteredCases
}

export const serviceName = (code) => {
  if ([afiliatedServices.crane, particularServices.crane].includes(code)) {
    return 'Grúa'
  }
  if ([afiliatedServices.gas, particularServices.gas].includes(code)) {
    return 'Aux Gasol'
  }
  if ([afiliatedServices.accompaniment].includes(code)) {
    return 'AV'
  }
  if ([afiliatedServices.battery, particularServices.battery].includes(code)) {
    return 'Aux Bateria'
  }
  if ([afiliatedServices.tire, particularServices.tire2].includes(code)) {
    return 'Cambio Caucho'
  }
  if ([particularServices.tire1].includes(code)) {
    return 'Inflado Caucho'
  } else {
    return code
  }
}

export const calculateDuration = (data) => {
  let duration = ''

  if (data?.endDate)
    duration =
      new Date(data?.endDate)?.getTime() - new Date(data?.createdAt)?.getTime()
  else duration = new Date()?.getTime() - new Date(data?.createdAt)?.getTime()

  if (data?.stage?.stage === enumStatus?.FINISHED) {
    duration = null
  }

  if (duration === null) {
    return null
  } else {
    return (duration / 60000).toFixed() + ' min'
  }
}

export const reasignTime = (data, index) => {
  let totalTime = 0
  for (let j = 0; j < data?.newStages.length; j++) {
    if (data?.stages[j]?.name === data?.newStages[index]?.name) {
      return (data?.stages[j]?.totalTime / 60).toFixed(2) + ' min'
    }

    if (index === data?.newStages.length - 1) {
      for (let i = 0; i < data?.stages.length; i++) {
        if (statusHeadervalues.includes(data?.stages[i]?.name)) {
          totalTime = totalTime + data?.stages[i]?.totalTime
        }
      }
      totalTime = (totalTime / 60).toFixed(2)
      return totalTime > 0 ? totalTime + ' min' : ''
    }
  }
}

export const printTime = (data, index, stageName) => {
  let stagesData = data?.stages?.filter((s) =>
    Object.values([
      enumStatus?.AV_ASSIGNED,
      enumStatus?.AV_ON_ROAD,
      enumStatus?.AV_IN_ORIGIN,
      enumStatus?.SEARCHING_TOW,
      enumStatus?.TOW_ASSIGNED,
      enumStatus?.TOW_ON_ROAD,
      enumStatus?.TOW_IN_ORIGIN,
      enumStatus?.CUSTOMER_ON_ROAD,
    ])?.includes(s?.name)
  )

  let totalTime = 0

  let [stage] = data?.stages?.filter((s) => s?.name === stageName)

  if (stage) {
    let time = (stage?.totalTime / 60)?.toFixed(2)
    return parseFloat(time)
  }

  if (!stageName) {
    if (index === data?.newStages.length - 1) {
      for (let i = 0; i < stagesData?.length; i++) {
        totalTime = totalTime + stagesData[i]?.totalTime
      }
      totalTime = (totalTime / 60).toFixed(2)
      return parseFloat(totalTime) > 0 ? parseFloat(totalTime) : ''
    }
  }
}

export const scheduleFinalColor = (_case) => {
  if (_case?.createdBy?.firstName === 'BOTMAKER') {
    return '#357D5B'
  }

  if (_case?.scheduleDate) {
    return '#FFD700'
  }
  if (_case?.status === enumStatus?.FINISHED) {
    let pendingPayments = 0
    for (let i = 0; i < _case?.services?.length; i++) {
      if (_case?.services[i]?.pendingPayment?.pending) {
        pendingPayments = pendingPayments + 1
      }
    }

    if (pendingPayments > 0) {
      return '#DDDAD6'
    }
  }
}

export const exportDataToExcel = (DATA, fileName) => {
  let ws = XLSX.utils.json_to_sheet(DATA)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'sheet')
  let buf = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' }) // generate a nodejs buffer
  let str = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }) // generate a binary string in web browser
  XLSX.writeFile(wb, fileName)
}

export const validateCourtesy = (value, paymentStatus) => {
  return value === paymentStatus
}

export const hideForOperators = () => {
  if (
    [
      operationsRoles?.OPERATIONS_FLOTA_AV,
      operationsRoles?.OPERATIONS_FLOTA_GRUA,
    ]?.includes(authService?.getUserType())
  ) {
    return false
  } else return true
}

export const getOption = (value, options) => {
  let [option] = options?.filter((o) => o?._id === value)
  return option || ''
}

export const transcriptStatus = (role) => {
  let src = ''
  switch (role) {
    // Operaciones
    case gpsStatusEnum?.OFFLINE:
      src = 'Fuera de servicio'
      break
    case gpsStatusEnum?.ACK:
      src = 'Vehículo apagado'
      break
    case gpsStatusEnum?.ONLINE:
      src = 'Activo'
      break
    default:
      src = role
  }
  return src
}

export const calculateMiddlePoint = (data) => {
  if (data.length === 0) {
    return null
  }

  let sumLat = 0
  let sumLng = 0

  data.forEach((d) => {
    sumLat += d.lat
    sumLng += d.lng
  })

  const avgLat = sumLat / data.length
  const avgLng = sumLng / data.length
  const center = { lat: avgLat, lng: avgLng }
  return center
}

export const filterOption = (options, name) => {
  const [selected] = options?.filter((o) => o?.name === name)
  return selected || ''
}

export const filterOptionById = (options, _id) => {
  const [selected] = options?.filter((o) => o?._id === _id)
  return selected || ''
}

export const parseNull = (value) => {
  value = parseFloat(value)

  if (!value) {
    return 0
  } else {
    return value
  }
}
export const isValidMongoId = (input) => {
  const regex = /^[0-9a-fA-F]{24}$/
  return regex.test(input)
}
