import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const membershipProductsService = {
  getAvailableProducts: async (params) => {
    for (const key in params) {
      if (params[key] === undefined || params[key] === '') {
        delete params[key]
      }
    }

    const response = await authInstance
      .get(`${url}/productsCatalog/affiliated?` + new URLSearchParams(params))
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
