import { useEffect, useState } from 'react';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  FleetLocationTable,
} from '../../common/Tables/Tables';
import {
  fleetLocationTableheader,
} from '../../../assets/helpers/tables';
import {
  getProvidersEnum,
} from '../../../assets/helpers/options';
import { gpsService } from '../../../services/gpsService.js';
import { notify } from '../../../assets/helpers/toast';
import { SearchInput } from '../../common/Forms/Input/Input';
import { useHistory } from 'react-router-dom';
import { exists, filterParams } from '../../../assets/helpers/funciones';
import { LocationMapModal } from '../../common/Modals/Modals';
import './FleetLocation.scss';

const itemsPerpage = [10, 25, 50, 100, 500];

export const FleetLocation = () => {
  const history = useHistory();

  const [fleetLocationsTable, setFleetLocationsTable] = useState({
    limit: itemsPerpage[0],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    type: getProvidersEnum.CRANE,
    isInternal: false,
    isLoading: true,
  });


  const [locationModal, setLocationModal] = useState({
    show: false,
  });

  const selectitemsPerpage = (event) => {
    setFleetLocationsTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setFleetLocationsTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * fleetLocationsTable?.limit,
      };
    });
  };

  const getFleetLocation = () => {
    setFleetLocationsTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    let params = {
      offset: fleetLocationsTable?.offset,
      limit: fleetLocationsTable?.limit,
      search: fleetLocationsTable?.description,
    };

    gpsService
      .getFleetLocation(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setFleetLocationsTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.devices,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setFleetLocationsTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setFleetLocationsTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const handleLocationModal = (data) => {
    setLocationModal((prevState) => {
      return {
        ...prevState,
        show: !prevState.show,
        data: data ? data : "",
      };
    });
  }

  const handleSearchChange = (e) => {
    const { value } = e?.target;
    setFleetLocationsTable((prevState) => {
      return {
        ...prevState,
        description: value,
        page: 1,
        offset: 0,
      };
    });
    if (!value) {
      window.location.reload();
    }
  };

  const handleEdit = (e) => {
    history.push(`/providers/external/edit/${e?._id}`);
  };

  useEffect(() => {
    getFleetLocation();
  }, [
    fleetLocationsTable?.page,
    fleetLocationsTable?.offset,
    fleetLocationsTable?.limit,
    fleetLocationsTable?.type,
  ]);

  return (
    <>
      <LocationMapModal modal={locationModal} handleModal={handleLocationModal} />
      <Header />
      <div className="service-with-container container">
        <h1 className="title-component">
          Ubicación de Flota: {exists(fleetLocationsTable?.count)}
        </h1>
        <div className="breadcrumb-container">
          <div className="providers-buttons-container">
            <span></span>
          </div>
        </div>
        <div className="gray-line"></div>

        <div className="filter-button-container"></div>
        <br />
        <div className="breadcrumb-container">
          <span></span>
          <div className="service-filter-buttons-container">
            <SelectPerPage
              label={'Registros por página'}
              options={itemsPerpage}
              value={fleetLocationsTable?.limit}
              onChange={selectitemsPerpage}
            />
            <SearchInput
              placeholder={'Busqueda'}
              width="120px"
              value={fleetLocationsTable?.description}
              onChange={handleSearchChange}
              searchAction={getFleetLocation}
            />
          </div>
        </div>
        <FleetLocationTable
          header={fleetLocationTableheader}
          dataTable={fleetLocationsTable}
          handlePageChange={handlePageChange}
          edit={handleEdit}
          handleLocationModal={handleLocationModal}
        />
      </div>
    </>
  );
};

