import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const vehicularAssetsService = {
  getVehicularAssets: async (data) => {
    const response = await authInstance
      .get(
        `${url}/vehicularAssets/?offset=${data?.offset}&limit=${data?.limit}`
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  createVehicularAsset: async (data) => {
    const response = await authInstance
      .post(`${url}/vehicularAssets/create`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  editVehicularAsset: async (data, vehicleId) => {
    const response = await authInstance
      .put(`${url}/vehicularAssets/${vehicleId}/update`, data)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },

  getVehicleById: async (vehicleId) => {
    const response = await authInstance
      .get(`${url}/vehicularAssets/${vehicleId}`)
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
