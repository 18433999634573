import { useState } from 'react';
import { Header } from '../../../common/Header/Header.jsx';
import watch from '../../../../images/watch.svg';
import watchOpen from '../../../../images/watchOpen.svg';
import arrowDown from '../../../../images/arrowDown.svg';
import craneOpen from '../../../../images/craneOpen.svg';
import craneClose from '../../../../images/craneClose.svg';
import dolarClose from '../../../../images/dolarClose.svg';
import dollarOpen from '../../../../images/dollarOpen.svg';

import weelClose from '../../../../images/weelClose.svg';
import weelOpen from '../../../../images/weelOpen.svg';

import './Parameters.scss';

export const Parameters = () => {
  return (
    <>
      <Header />
      <div className="container">
        <h1 className="title-component">Parámetros</h1>
        <div className="breadcrumb-container"></div>
        <div className="filter-button-container"></div>
        <br />
        <div>
          <ParameterCard
            label={'Tiempos de operación'}
            imgOpen={watchOpen}
            imgClose={watch}
          />
          <ParameterCard
            label={'Condiciones Planes'}
            imgOpen={craneOpen}
            imgClose={craneClose}
          />
          <ParameterCard
            label={'Costos Asociados'}
            imgOpen={dollarOpen}
            imgClose={dolarClose}
          />
          <ParameterCard
            label={'Choferes'}
            imgOpen={weelOpen}
            imgClose={weelClose}
          />
        </div>
      </div>
    </>
  );
};

export const ParameterCard = (props) => {
  const { label, imgOpen, imgClose } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="parameter-card">
      <div>
        {open ? (
          <div className="parameter-logo-container">
            <img src={imgOpen} alt="watch" />
          </div>
        ) : (
          <div className="parameter-logo-container">
            <img src={imgClose} alt="watch" />
          </div>
        )}
        <h5>{label}</h5>
      </div>
      {open ? (
        <img
          style={{ rotate: '-90deg' }}
          className="arrow-down"
          src={arrowDown}
          alt="arrowDown"
          onClick={() => setOpen(!open)}
        />
      ) : (
        <img
          className="arrow-down"
          src={arrowDown}
          alt="arrowDown"
          onClick={() => setOpen(!open)}
        />
      )}
    </div>
  );
};
