export const IVA = 16

export const capitalize = (str) => {
  if (str) {
    if (typeof str === 'number') {
      str = str.toString()
    }
    str = str?.toLowerCase()
    const arr = str.split(' ')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    const str2 = arr.join(' ')
    return str2
  } else return ''
}

export const addressStatus = {
  ORIGIN: 'origen',
  DESTINY: 'destino',
}

export const phoneCodes = [
  { _id: '0212', name: '0212' },
  { _id: '0412', name: '0412' },
  { _id: '0414', name: '0414' },
  { _id: '0424', name: '0424' },
  { _id: '0416', name: '0416' },
  { _id: '0426', name: '0426' },
]

export const idCodes = [
  { _id: 'V', name: 'V' },
  { _id: 'E', name: 'E' },
  { _id: 'J', name: 'J' },
  { _id: 'G', name: 'G' },
  { _id: 'R', name: 'R' },
  { _id: 'P', name: 'P' },
]

export const gendersEnum = {
  MALE: 'Masculino',
  FEMALE: 'Femenino',
  OTHER: 'Otro',
}

export const genderOptions = [
  { _id: gendersEnum?.MALE, name: gendersEnum?.MALE },
  { _id: gendersEnum?.FEMALE, name: gendersEnum?.FEMALE },
  { _id: gendersEnum?.OTHER, name: gendersEnum?.OTHER },
]

export const civilStateEnum = {
  SINGLE: 'Soltera/o',
  MARRIED: 'Casada/o',
  DIVORCED: 'Divorciada/o',
  WIDOW: 'Viuda/o',
  OTHER: 'Otro',
}

export const civilStateOptions = [
  { _id: civilStateEnum?.SINGLE, name: civilStateEnum?.SINGLE },
  { _id: civilStateEnum?.MARRIED, name: civilStateEnum?.MARRIED },
  { _id: civilStateEnum?.DIVORCED, name: civilStateEnum?.DIVORCED },
  { _id: civilStateEnum?.WIDOW, name: civilStateEnum?.WIDOW },
  { _id: civilStateEnum?.OTHER, name: civilStateEnum?.OTHER },
]

export const providerStatus = {
  available: 'Available',
  busy: 'Busy',
}

export const clientTypes = {
  AFFILIATED: 'Affiliated',
  PARTICULAR: 'Particular',
}

export const caseType = {
  AFFILIATED: 'Afiliado',
  PARTICULAR: 'Particular',
}

export const caseTypeOptions = [
  { _id: '', name: '' },
  { _id: caseType?.AFFILIATED, name: caseType?.AFFILIATED },
  { _id: caseType?.PARTICULAR, name: caseType?.PARTICULAR },
]

export const afiliatedServices = {
  crane: 'GRUA',
  craneExtended: 'GRUA_EXT',
  gas: 'AUX_GAS',
  tire: 'CAMBIO_CAUCHO',
  battery: 'AUX_BAT',
  accompaniment: 'ACOMP_VIAL',
}

export const particularServices = {
  crane: 'GRUA_PART',
  gas: 'AUX_GAS_PART',
  tire1: 'INF_CAUCHO_PART',
  tire2: 'CAMB_CAUCHO_PART',
  battery: 'AUX_BAT_PART',
  // accompaniment: ',
}

export const getProvidersEnum = {
  ROAD_ATTENDANT: 'Asistente Vial',
  CRANE: 'Grua',
}

export const lastServiceEnum = {
  ROAD_ATTENDANT: 'Asistencia vial',
  CRANE: 'Grua',
}

export const setviceType = {
  ROAD_ATTENDANT: 'AV',
  CRANE: 'GRUA',
}

export const providersOptions = [
  { _id: getProvidersEnum?.ROAD_ATTENDANT, name: 'Motorizado' },
  { _id: getProvidersEnum?.CRANE, name: 'Gruero' },
]

export const paymentStatusEnum = {
  PAID: 'Pagado',
  UNPAID: 'No pagado',
}

export const paymentStatusOptions = [
  { _id: '', name: '' },
  { _id: paymentStatusEnum?.PAID, name: paymentStatusEnum?.PAID },
  { _id: paymentStatusEnum?.UNPAID, name: paymentStatusEnum?.UNPAID },
]

export const statusEnum = {
  DEVOLVER_CASO: 'Devolver Caso',
  VER_CASO: 'Ver Caso',
  ACTUALIZAR_PAGO: 'Actualizar Pago',
  ACTUALIZAR_VUELTOS: 'Actualizar Vueltos',
  CAMBIAR_OPERADOR: 'Cambiar Operador',
  ASIGNAR_GRUA: 'Asignar Grua',
  CAMBIAR_MOTORIZADO: 'Cambiar Motorizado',
  REVISION_EXPRESS: 'Revisión Express',
  CAMBIAR_GRUERO: 'Cambiar Gruero',
  REGISTRAR_SEGUIMIENTO: 'Registrar Seguimiento',
  CARGAR_IMAGEN: 'Cargar Imagen',
  CARGAR_IMAGEN_SERVICIO_GRUA: 'Cargar Imagen de Servicio de Grua',
  EDITAR: 'Editar',
  CANCELAR_SERVICIO: 'Cancelar Servicio',
  CANCELAR_CASO: 'Cancelar Caso',
  CHANGE_TO_SOLVED: 'Cambiar a Averia Solventada',
  CHANGE_TO_CHECKING: 'Cambiar a Verificación',
  FAILED: 'Fallido',
}

export const notFound = 'not-found'

export const enumStatus = {
  INITIATED: 'Orden En Espera',
  //Moto
  AV_ASSIGNED: 'Av Asignado',
  AV_ON_ROAD: 'Av en via',
  AV_IN_ORIGIN: 'Av En Origen',
  //Grua
  SEARCHING_TOW: 'En Busqueda de Grua',
  TOW_ASSIGNED: 'Grua Asignada',
  TOW_ON_ROAD: 'Grua en Via',
  TOW_IN_ORIGIN: 'Grua En Origen',
  CUSTOMER_ON_ROAD: 'Cliente Via Destino',
  // Otros
  SCHEDULED: 'Servicio Agendado',
  FINISHED: 'Finalizada',
  FAILED: 'Fallido',
  CANCELED: 'Anuladas',

  // Este no es un status de servicio pero se usa para filtrar
  PENDING_PAYMENT: 'Cuenta por cobrar',
}

export const incidenceColumnsEnum = {
  ORDEN_NO_CREADA: 'ORDEN NO CREADA',
  AV_ASIGNADO: 'AV ASIGNADO',
  AV_EN_VIA: 'AV EN VIA',
  AV_EN_ORIGEN: 'AV EN ORIGEN',
  EN_BÚSQUEDA_DE_GRÚA: 'EN BÚSQUEDA DE GRÚA',
  GRÚA_ASIGNADA: 'GRÚA ASIGNADA',
  GRÚA_EN_VÍA: 'GRÚA EN VÍA',
  GRÚA_EN_ORIGEN: 'GRÚA EN ORIGEN',
  CLIENTE_VÍA_DESTINO: 'CLIENTE VÍA DESTINO',
  SERVICIOS_AGENDADOS: 'SERVICIOS AGENDADOS',
  SERVICIOS_FINALIZADOS: 'SERVICIOS FINALIZADOS',
  CUENTAS_POR_COBRAR: 'CUENTAS POR COBRAR',
  SERVICIOS_FALLIDOS: 'SERVICIOS FALLIDOS',
}

export const membershipCodes = {
  GOLD: 'GOLD',
  PLUS: 'PLUS',
  PLUSF4: 'PLUSF4',
  CLASSIC: 'CLASSIC',
  MOTORIDER: 'MOTORIDER',
  RCV: 'RCV',
  RETRO: 'RETRO',
  RCV_ELITE: 'RCV_ELITE',
  RIDERY: 'RIDERY',
}

export const addressEnum = {
  OWN: 'Propia',
  GARAGE: 'Taller',
  OTHER: 'Otra',
}

export const addressOptions = [
  { name: addressEnum?.OWN, _id: addressEnum?.OWN },
  { name: addressEnum?.GARAGE, _id: addressEnum?.GARAGE },
  { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
]

export const particularAddressOptions = [
  { name: addressEnum?.GARAGE, _id: addressEnum?.GARAGE },
  { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
]

export const afiliatedAddressOptions = [
  { name: addressEnum?.OWN, _id: addressEnum?.OWN },
  { name: addressEnum?.GARAGE, _id: addressEnum?.GARAGE },
  { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
]

export const locationEnum = {
  STREET: 'Calle',
  BASEMENT: 'Sotano',
}

export const locationOptions = [
  { name: locationEnum?.STREET, _id: false },
  { name: locationEnum?.BASEMENT, _id: true },
]

export const garageLocation = {
  state: { name: 'Distrito Capital', _id: '64695c7b447d0aa9c65969e8' },
  city: { name: 'Caracas', _id: '64695ff1a034e218fab88de5' },
  address:
    'Chacao Av. Blandín, con Calle Santa Teresa de Jesús, C.C. San Ignacio, Sotano 1, Sector este. La Castellana',
}

export const prodiverStatys = [
  { name: 'Activo', _id: true },
  { name: 'Inactivo', _id: false },
]

export const membershipStatus = {
  ACTIVO: 'ACTIVO',
  'EN ESPERA': 'EN ESPERA',
  RENOVADO: 'RENOVADO',
  'POR RENOVACION': 'POR RENOVACION',
  'ESPERANDO VENCIMIENTO': 'ESPERANDO VENCIMIENTO',
}

export const exportablesTypes = {
  PAYMENT_AV: 'Pago motorizados',
  PAYMETN_INTERNAL_TOW: 'Pagos grúas propias',
  PAYMENT_EXTERNAL_TOW: 'Pagos grúas externas',
  PAYMENT_PENDING: 'Cuentas por cobrar',
  ADMINISTRATION: 'Administración',
}
export const providersPaymentsTypes = {
  PAYMENT_AV: 'Pago motorizados',
  PAYMETN_INTERNAL_TOW: 'Pagos grúas propias',
  PAYMENT_EXTERNAL_TOW: 'Pagos grúas externas',
}

export const planStatus = {
  PENDING_FOR_APPROBAL: 'PENDIENTE DE APROBACION',
  ACTIVE: 'ACTIVO',
  WAITING: 'EN ESPERA',
  TO_BE_RENEW: 'POR RENOVACION',
  EXPIRED: 'VENCIDO',
  CANCELED: 'ANULADO',
  DENIED: 'DENEGADO',
  TO_BE_EXPIRED: 'POR VENCER',
  WAITING_TO_BE_EXPIRED: 'ESPERANDO VENCIMIENTO',
  INCOMPLETED: 'INCOMPLETO',
  RENOVADO: 'RENOVADO',
}

export const statusHeadervalues = [
  enumStatus.AV_ASSIGNED,
  enumStatus.AV_ON_ROAD,
  enumStatus.AV_IN_ORIGIN,
  enumStatus.SEARCHING_TOW,
  enumStatus.TOW_ASSIGNED,
  enumStatus.TOW_ON_ROAD,
  enumStatus.TOW_IN_ORIGIN,
  enumStatus.CUSTOMER_ON_ROAD,
  'Tiempo total gestión',
]

export const codeTypeEnum = {
  DISCOUNT: 'Descuento',
  GIFTCARD: 'Giftcard',
}

export const codeTypeOptions = [
  { name: 'Tarjeta', _id: codeTypeEnum.GIFTCARD },
  { name: 'Descuento', _id: codeTypeEnum.DISCOUNT },
]

export const paymentMethods = {
  CASH: 'EFECTIVO',
  // TDC: 'TARJETA INTERNACIONAL',
  TDC: 'STRIPE',
  BANK_TRANSFER: 'TRANSFERENCIA BANCARIA',

  BINANCE: 'BINANCE PAY(USDT)',
  CASH_BS: 'CASH(BsS)',
  PAYPAL: 'PAYPAL',
  ZELLE: 'ZELLE',
  STRIPE: 'STRIPE',
  PAGO_MÓVIL: 'PAGO MÓVIL BANESCO',
  PAGO_MÓVIL_BANCAMIGA: 'PAGO MÓVIL BANCAMIGA',
  PAGO_MÓVIL_BNC: 'PAGO MÓVIL BNC',
  CASH_USD: 'CASH(USD)',
  RESERVE: 'RESERVE',
  CASH_EUR: 'CASH(EUR)',
  TRANSFERENCIA_MERCANTIL: 'TRANSFERENCIA MERCANTIL',
  TRANSFERENCIA_BANESCO: 'TRANSFERENCIA BANESCO',
  TRANSFERENCIA_BANCAMIGA: 'TRANSFERENCIA BANCAMIGA',
  PUNTO_DE_VENTA: 'PUNTO DE VENTA',
  CASHEA: 'CASHEA',
}

export const salesPaymentMethodOptions = [
  { name: paymentMethods?.ZELLE, code: paymentMethods?.ZELLE },
  { name: paymentMethods?.PAGO_MÓVIL, code: paymentMethods?.PAGO_MÓVIL },
  { name: paymentMethods?.PAYPAL, code: paymentMethods?.PAYPAL },
  { name: paymentMethods?.RESERVE, code: paymentMethods?.RESERVE },
  { name: paymentMethods?.CASH_USD, code: paymentMethods?.CASH_USD },
]

export const paymentType = {
  FINANCIADO: 'Financiado',
  DE_CONTADO: 'De contado',
  PENDING: 'Cuenta por cobrar',
  COURTESY: 'Cortesia',
}

export const paymentTypeOptions = [
  {
    name: paymentType?.FINANCIADO + ' (2 cuotas)',
    _id: paymentType?.FINANCIADO,
  },
  { name: paymentType?.DE_CONTADO, _id: paymentType?.DE_CONTADO },
  { name: paymentType?.PENDING, _id: paymentType?.PENDING },
  // { name: paymentType?.COURTESY, _id: paymentType?.COURTESY },
]

export const paymentMethodsLimit = [
  { name: 'Pago único', _id: '1' },
  { name: 'Pago mixto (2 Metodos de pago)', _id: '2' },
]

export let selectedMembership = {
  _id: '64693588ad6cef4517e7377d',
  code: 'GOLD',
  originalPrice: 40,
  armorTypeSurcharge: 20,
  classSurcharge: 60,
  finalPrice: 120,
}

export const membership = {
  membershipId: 46602,
  code: '64693588ad6cef4517e7377d',
  status: '64e8f582310f4aae79ddf50f',
  paymentType: 'De contado',
  paymentStatus: 'Cuenta por cobrar',
  payments: [],
  priceInfo: {
    initialValue: 40,
    armorTypeSurcharge: 20,
    classSurcharge: 60,
    finalPrice: 120,
    finalPriceInBsS: 4039.2839999999997,
  },
  renewals: [],
  cases: [],
  products: [],
  contactInCampaign: true,
  _id: '6504e54f5e5fef30b52466fa',
  paymentDues: [],
  upgrades: [],
  createdAt: '2023-09-15T23:14:23.632Z',
  updatedAt: '2023-09-15T23:14:23.632Z',
}

export const salesMembershipStatus = {
  ACTIVO: 'ACTIVO',
  VENCIDO: 'VENCIDO',
  EN_ESPERA: 'EN ESPERA',
  POR_VENCER: 'POR VENCER',
  POR_RENOVACION: 'POR RENOVACION',
  ANULADO: 'ANULADO',
}

export const campaignContactOptions = [
  { name: 'Contactar', _id: true },
  { name: 'No Contactar', _id: false },
]

export const armoredOptions = [
  { name: 'Si', _id: true },
  { name: 'No ', _id: false },
]

export const cancelFleatLoadEnum = {
  DEBT_CUSTOMER: 'Cliente moroso',
  SINISTER_CUSTOMER: 'Cliente siniestro',
  POLICY_NONCOMPLIANCE: 'Incumplimiento de politicas',
  CUSTOMER_WITHDRAWS: 'Cliente se retira',
  CONTRACT_TYPE_CHANGE: 'Cambio de tipo de contrato',
  AFFILIATION_CRITERIA: 'No cumple criterios de afiliación',
  CUSTOMER_DECISION: 'Decisión del cliente',
}

export const cancelFleatLoadOptions = [
  {
    name: cancelFleatLoadEnum?.DEBT_CUSTOMER,
    _id: cancelFleatLoadEnum?.DEBT_CUSTOMER,
  },
  {
    name: cancelFleatLoadEnum?.SINISTER_CUSTOMER,
    _id: cancelFleatLoadEnum?.SINISTER_CUSTOMER,
  },
  {
    name: cancelFleatLoadEnum?.POLICY_NONCOMPLIANCE,
    _id: cancelFleatLoadEnum?.POLICY_NONCOMPLIANCE,
  },
  {
    name: cancelFleatLoadEnum?.CUSTOMER_WITHDRAWS,
    _id: cancelFleatLoadEnum?.CUSTOMER_WITHDRAWS,
  },
  {
    name: cancelFleatLoadEnum?.CONTRACT_TYPE_CHANGE,
    _id: cancelFleatLoadEnum?.CONTRACT_TYPE_CHANGE,
  },
  {
    name: cancelFleatLoadEnum?.AFFILIATION_CRITERIA,
    _id: cancelFleatLoadEnum?.AFFILIATION_CRITERIA,
  },
  {
    name: cancelFleatLoadEnum?.CUSTOMER_DECISION,
    _id: cancelFleatLoadEnum?.CUSTOMER_DECISION,
  },
]

export const rcvEnum = {
  RCV_BASIC: 'RCV BÁSICO',
  RCV_ELIT: 'RCV INTERVIAL BÁSICO',
  NA: 'N/A',
}

export const rcvOptions = [
  { name: 'RCV Básico', _id: rcvEnum.RCV_BASIC },
  { name: 'RCV Élite', _id: rcvEnum.RCV_ELIT },
  { name: rcvEnum.NA, _id: rcvEnum.NA },
]

export const userStatus = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  BLOCKED: 'Bloqueado',
}

export const userStatusOptions = [
  { name: userStatus?.ACTIVE, _id: userStatus?.ACTIVE },
  { name: userStatus?.INACTIVE, _id: userStatus?.INACTIVE },
  { name: userStatus?.BLOCKED, _id: userStatus?.BLOCKED },
]

export const modules = {
  SALES: 'Ventas',
  OPERATIONS: 'Operaciones',
}

export const modulesOptions = [
  { name: modules?.SALES, _id: modules?.SALES },
  { name: modules?.OPERATIONS, _id: modules?.OPERATIONS },
]

export const userType = {
  INTERNO: 'Interno',
  EXTERNO: 'Externo',
}

export const userTypeOptions = [
  { name: userType?.INTERNO, _id: userType?.INTERNO },
  { name: userType?.EXTERNO, _id: userType?.EXTERNO },
]

export const codeStatus = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  EXPIRED: 'Vencido',
}

export const codeStatusOptions = [
  { name: codeStatus?.ACTIVE, _id: codeStatus?.ACTIVE },
  { name: codeStatus?.INACTIVE, _id: codeStatus?.INACTIVE },
  { name: codeStatus?.EXPIRED, _id: codeStatus?.EXPIRED },
]

export const discountType = {
  PERCENTAGE: 'Porcentaje',
  AMOUNT: 'Monto',
}

export const discountTypeOptions = [
  { name: discountType?.PERCENTAGE, _id: discountType?.PERCENTAGE },
  { name: discountType?.AMOUNT, _id: discountType?.AMOUNT },
]

export const giftCardType = {
  RETAIL: 'Retail',
  PARTICULAR: 'Particular',
}

export const giftCardTypeOptions = [
  { name: giftCardType?.RETAIL, _id: giftCardType?.RETAIL },
  { name: giftCardType?.PARTICULAR, _id: giftCardType?.PARTICULAR },
]

export const loadStrategy = {
  LINK: 'Link',
  ARCHIVO: 'Archivo',
}

export const loadStrategyOptions = [
  { name: loadStrategy?.ARCHIVO, _id: loadStrategy?.ARCHIVO },
  { name: loadStrategy?.LINK, _id: loadStrategy?.LINK },
]

export const fleetType = {
  COLAB_FLEET: 'Flota Colaboradores',
  TECHNICAL_FLEET: 'Flota Técnica',
}

export const fleetTypeOptions = [
  { name: 'Técnica', _id: fleetType?.TECHNICAL_FLEET },
  { name: 'Colaboradores', _id: fleetType?.COLAB_FLEET },
]

export const fleetPaymentType = {
  FINANCIADO: 'Financiado',
  DE_CONTADO: 'De contado',
}

export const fleetPaymentTypeOptions = [
  { name: fleetPaymentType?.FINANCIADO, _id: fleetPaymentType?.FINANCIADO },
  { name: fleetPaymentType?.DE_CONTADO, _id: fleetPaymentType?.DE_CONTADO },
]

export const financingType = {
  MONTHLY: 'Mensual',
  QUARTER: 'Trimestral',
  SEMESTER: 'Semestral',
}

export const financingTypeOptions = [
  { name: financingType?.MONTHLY, _id: financingType?.MONTHLY },
  { name: financingType?.QUARTER, _id: financingType?.QUARTER },
  { name: financingType?.SEMESTER, _id: financingType?.SEMESTER },
]

export const fleetLoadRCVTypes = {
  N_A: 'N/A',
  RCV_BASIC: 'RCV BÁSICO',
  RCV_INTERVIAL_BASICO: 'RCV INTERVIAL BÁSICO',
}

export const fleetLoadRCVTypesOptions = [
  {
    name: 'Ninguno',
    _id: fleetLoadRCVTypes?.N_A,
  },
  {
    name: capitalize(fleetLoadRCVTypes?.RCV_BASIC),
    _id: fleetLoadRCVTypes?.RCV_BASIC,
  },
  {
    name: capitalize(fleetLoadRCVTypes?.RCV_INTERVIAL_BASICO),
    _id: fleetLoadRCVTypes?.RCV_INTERVIAL_BASICO,
  },
]

export const planTypes = {
  GOLD: 'GOLD',
  PLUS: 'PLUS',
  CLASSIC: 'CLASSIC',
  MOTORIDER: 'MOTORIDER',
}

export const planTypesOptions = [
  {
    name: 'Plan ' + capitalize(planTypes?.GOLD),
    _id: planTypes?.GOLD,
  },
  {
    name: 'Plan ' + capitalize(planTypes?.PLUS),
    _id: planTypes?.PLUS,
  },
  {
    name: 'Plan ' + capitalize(planTypes?.CLASSIC),
    _id: planTypes?.CLASSIC,
  },
  {
    name: 'Plan ' + capitalize(planTypes?.MOTORIDER),
    _id: planTypes?.MOTORIDER,
  },
]

export const sortCards = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortCardsOptions = [
  {
    name: 'Acendente',
    _id: sortCards?.ASC,
  },
  {
    name: 'Descendente',
    _id: sortCards?.DESC,
  },
]

export const filterCardByStatus = {
  ACTIVE: 'Activa',
  INACTIVE: 'Inactiva',
  EXPIRED: 'Vendida',
  CASHED: 'Canjeada',
}

export const filterCardByStatusOptions = [
  {
    name: 'Todos',
    _id: '',
  },
  {
    name: filterCardByStatus?.ACTIVE,
    _id: filterCardByStatus?.ACTIVE,
  },
  {
    name: 'Bloqueadas',
    _id: filterCardByStatus?.INACTIVE,
  },
  // {
  //   name: filterCardByStatus?.EXPIRED,
  //   _id: filterCardByStatus?.EXPIRED,
  // },
  {
    name: filterCardByStatus?.CASHED,
    _id: filterCardByStatus?.CASHED,
  },
]

export const fleetLoadPaymentStatus = {
  PAID: 'Pago realizado',
  PENDING: 'Cuenta por cobrar',
  EXPIRED: 'Pago vencido',
}

export const monthsOptions = [
  { name: 'Enero', _id: 'January' },
  { name: 'Febrero', _id: 'February' },
  { name: 'Marzo', _id: 'March' },
  { name: 'Abril', _id: 'April' },
  { name: 'Mayo', _id: 'May' },
  { name: 'Junio', _id: 'June' },
  { name: 'Julio', _id: 'July' },
  { name: 'Agosto', _id: 'August' },
  { name: 'Septiembre', _id: 'September' },
  { name: 'Octubre', _id: 'October' },
  { name: 'Noviembre', _id: 'November' },
  { name: 'Diciembre', _id: 'December' },
]

export const PaymentStatus = {
  DE_CONTADO: 'De contado',
  CUENTA_POR_COBRAR: 'Cuenta por cobrar',
  N_A: 'N/A',
  COURTESY: 'Cortesía',
}

export const PaymentStatusOptions = [
  { name: PaymentStatus?.DE_CONTADO, _id: PaymentStatus?.DE_CONTADO },
  {
    name: PaymentStatus?.CUENTA_POR_COBRAR,
    _id: PaymentStatus?.CUENTA_POR_COBRAR,
  },
  // { name: PaymentStatus?.COURTESY, _id: PaymentStatus?.COURTESY },
  { name: PaymentStatus?.N_A, _id: PaymentStatus?.N_A },
]

export const towSMSEnum = {
  FORM: 'FORM',
  SEARCHING_TOW: 'SEARCHING_TOW',
  ASSIGNED_TOW: 'ASSIGNED_TOW',
}

export const SERVICE_STATUS = {
  ACTIVE: 'Activo',
  DONE: 'Finalizado',
  CANCELLED: 'Cancelado',
}

export const authorizedByEnum = {
  CARLOS_HEIZE: 'Carlos Heinze',
  RUBEN_BLANCO: 'Rubén Blanco',
  ALESSANDRO_COSTANTINI: 'Alessandro Costantini',
  ALEJANDRO_ECHEVERRIA: 'Alejandro Echeverría',
  DAVID_SANTANA: 'David Santana',
}

export const authorizedByOptions = [
  { name: authorizedByEnum?.CARLOS_HEIZE, _id: authorizedByEnum?.CARLOS_HEIZE },
  { name: authorizedByEnum?.RUBEN_BLANCO, _id: authorizedByEnum?.RUBEN_BLANCO },
  {
    name: authorizedByEnum?.ALESSANDRO_COSTANTINI,
    _id: authorizedByEnum?.ALESSANDRO_COSTANTINI,
  },
  {
    name: authorizedByEnum?.ALEJANDRO_ECHEVERRIA,
    _id: authorizedByEnum?.ALEJANDRO_ECHEVERRIA,
  },
  {
    name: authorizedByEnum?.DAVID_SANTANA,
    _id: authorizedByEnum?.DAVID_SANTANA,
  },
]

export const towReasons = {
  AV_UNAVAILABLE: 'Sin disponibilidad AV',
  AV_UNREACHABLE_LOCATION: 'No llega AV',
  CLIENT_REQUEST: 'Petición del cliente',
  RAIN: 'Lluvia',
  WORKSHOP_WARRANTY: 'Garantía taller',
  ALREADY_CHECKED_VEHICLE: 'Vehículo ya verificado',
}

export const towReasonsOptions = [
  { name: '', _id: '' },
  { name: towReasons?.AV_UNAVAILABLE, _id: towReasons?.AV_UNAVAILABLE },
  {
    name: towReasons?.AV_UNREACHABLE_LOCATION,
    _id: towReasons?.AV_UNREACHABLE_LOCATION,
  },
  { name: towReasons?.CLIENT_REQUEST, _id: towReasons?.CLIENT_REQUEST },
  { name: towReasons?.RAIN, _id: towReasons?.RAIN },
  {
    name: towReasons?.WORKSHOP_WARRANTY,
    _id: towReasons?.WORKSHOP_WARRANTY,
  },
  {
    name: towReasons?.ALREADY_CHECKED_VEHICLE,
    _id: towReasons?.ALREADY_CHECKED_VEHICLE,
  },
]

export const externalTowReasons = {
  INTERNAL_TOW_UNAVAILABLE: 'Sin disponibilidad grúa propia',
  ORIGIN_RED_ZONE: 'Zona roja origen',
  DESTINATION_RED_ZONE: 'Zona roja destino',
  OUT_OF_COVERAGE: 'Fuera de limites',
}

export const externalTowReasonsOptions = [
  {
    name: externalTowReasons?.INTERNAL_TOW_UNAVAILABLE,
    _id: externalTowReasons?.INTERNAL_TOW_UNAVAILABLE,
  },
  {
    name: externalTowReasons?.ORIGIN_RED_ZONE,
    _id: externalTowReasons?.ORIGIN_RED_ZONE,
  },
  {
    name: externalTowReasons?.DESTINATION_RED_ZONE,
    _id: externalTowReasons?.DESTINATION_RED_ZONE,
  },
  {
    name: externalTowReasons?.OUT_OF_COVERAGE,
    _id: externalTowReasons?.OUT_OF_COVERAGE,
  },
]

export const LEVELS = {
  LOW: 'Bajo',
  NORMAL: 'Medio',
  HIGH: 'Alto',
  NONE: 'No Aplica',
}

export const LevelOptions = [
  { name: LEVELS?.HIGH, _id: LEVELS?.HIGH },
  { name: LEVELS?.NORMAL, _id: LEVELS?.NORMAL },
  { name: LEVELS?.LOW, _id: LEVELS?.LOW },
  { name: LEVELS?.NONE, _id: LEVELS?.NONE },
]

export const CONDITION = {
  BAD: 'Mal',
  REGULAR: 'Regular',
  GOOD: 'Bien',
  NONE: 'No Aplica',
}
export const ConditionOptions = [
  { name: CONDITION?.GOOD, _id: CONDITION?.GOOD },
  { name: CONDITION?.BAD, _id: CONDITION?.BAD },
  { name: CONDITION?.REGULAR, _id: CONDITION?.REGULAR },
  { name: CONDITION?.NONE, _id: CONDITION?.NONE },
]

export const LEAKS = {
  YES: 'Si',
  NO: 'No',
  NONE: 'No Aplica',
}
export const LeakOptions = [
  { name: LEAKS?.YES, _id: LEAKS?.YES },
  { name: LEAKS?.NO, _id: LEAKS?.NO },
  { name: LEAKS?.NONE, _id: LEAKS?.NONE },
]

export const HEALTH = {
  GOOD: 'Óptima',
  BAD: 'Deficiente',
  NONE: 'No Aplica',
}

export const HealthOptions = [
  { name: HEALTH?.GOOD, _id: HEALTH?.GOOD },
  { name: HEALTH?.BAD, _id: HEALTH?.BAD },
  { name: HEALTH?.NONE, _id: HEALTH?.NONE },
]

export const gpsStatusEnum = {
  OFFLINE: 'offline',
  ACK: 'ack',
  ONLINE: 'online',
}

export const cashBakOptions = [
  { _id: '1', name: '1' },
  { _id: '2', name: '2' },
  { _id: '3', name: '3' },
]

export const sumErrorMessage =
  'La suma de los pagos y la diferencia a pagar por el cliente no coinciden'

export const referenceLocation = {
  ORIGIN: 'origin',
  DESTINY: 'destiny',
}
