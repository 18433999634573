import { useState, useEffect, useRef } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { OperationsUserTable } from '../../common/Tables/Tables';
import { saleUsersTableheader } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { usersService } from '../../../services/usersService';
import { rolesService } from '../../../services/rolesService';
import { providersService } from '../../../services/providersService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { useHistory } from 'react-router-dom';
import { transcriptRoles, salesRoles, operationsRoles } from '../../../assets/helpers/roles';
import { filterParams } from '../../../assets/helpers/funciones';
import {
  userStatusOptions,
  modulesOptions,
  userTypeOptions,
  userType,
  getProvidersEnum
} from '../../../assets/helpers/options';
import {
  CreateSalesUserModal,
  EditSalesUserModal,
} from '../../common/Modals/Modals';
import './OperationsUsers.scss';

const itemsPerpage = [10, 25, 50, 100, 500, 1000, 2500, 5000];

export const OperationsUsers = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const history = useHistory();

  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.filterExportableSchema(t)),
  });

  const [sinceDate, setSinceDate] = useState(new Date());
  const [untilDate, setUntilDate] = useState(new Date());
  const [createUseModal, setCreateUserModal] = useState({
    isOpen: false,
  });
  const [editUserModal, setEditUserModal] = useState({
    isOpen: false,
  });

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });


  const selectitemsPerpage = (event) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * dataTable?.limit,
      };
    });
  };

  const redirectToOrder = (orderId) => {
    history.push(`/afiliations/order/${orderId}`);
  };

  const getRoles = () => {
    rolesService
      .getRoles('Operaciones')
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          let data = [...response?.data];
          for (let i = 0; i < data.length; i++) {
            data[i]._id = data[i]?.name;
            data[i].name = transcriptRoles(data[i]?.name);
          }
          setRolesOptions(data);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getProviders = () => {
    let params = {
      isInternal: true,
      isActive: true,
    }

    if ([operationsRoles?.OPERATIONS_FLOTA_AV, operationsRoles?.OPERATIONS_FLOTA_GRUA]?.includes(watch2('role'))) {


      if (watch2('role') === operationsRoles?.OPERATIONS_FLOTA_AV) {
        params.type = getProvidersEnum?.ROAD_ATTENDANT
      }
      if (watch2('role') === operationsRoles?.OPERATIONS_FLOTA_GRUA) {
        params.type = getProvidersEnum?.CRANE
      }
      providersService
        .getProviders(params)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setProvidersOptions(response?.data?.providers)
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => { });
    }
  };


  useEffect(() => {
    getFleets();
    getRoles();
    getProviders();
  }, [
    dataTable?.page,
    dataTable?.offset,
    dataTable?.limit,
    dataTable?.type,
    dataTable?.sinceDate,
    dataTable?.untilDate,
  ]);

  const getFleets = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
      module: 'Operaciones',
    };

    usersService
      .getUsers(params)
      .then((response) => {
        if (response.status === 200) {
          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.users,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const openDetail = (fleet) => {
    history.push(`/modules/fleet-register/detail/${fleet?._id}`);
  };

  const handleCreateFleetForm = () => {
    setValue2('module', 'Operaciones');
    setCreateUserModal((prevState) => {
      return {
        ...prevState,
        isOpen: !createUseModal?.isOpen,
      };
    });
  };

  const handleEditFleetForm = (user) => {
    setValue3('firstName', user?.firstName);
    setValue3('lastName', user?.lastName);
    setValue3('identification', user?.identification);
    setValue3('email', user?.email);
    setValue3('status', user?.status);

    setEditUserModal((prevState) => {
      return {
        ...prevState,
        isOpen: !editUserModal?.isOpen,
        selected: user?._id,
      };
    });
  };

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    reset: reset2,
    setError: setError2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.CreateUsersSchema()),
  });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    setValue: setValue3,
    reset: reset3,
  } = useForm({
    resolver: yupResolver(schemas.EditUsersSchema()),
  });

  const [isLoading, setIsloading] = useState(false);
  // Function that handles the logout request
  const onSubmit2 = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    delete dataToPost.confirmPassword;

    // ALl users are internell unless they are sales counter
    if (dataToPost?.role !== salesRoles?.SALES_COUNTER) {
      dataToPost.type = userType?.INTERNO;
    }

    if (![operationsRoles?.OPERATIONS_FLOTA_AV, operationsRoles?.OPERATIONS_FLOTA_GRUA]?.includes(watch2('role'))) {
      delete dataToPost.provider;
    }

    if ([operationsRoles?.OPERATIONS_FLOTA_AV, operationsRoles?.OPERATIONS_FLOTA_GRUA]?.includes(watch2('role')) && !watch2("provider")) {
      setError2('provider', { type: 'custom', message: 'Obligatorio' });
      return null
    }

    if (!isLoading) {
      setIsloading(true);
      usersService
        .createUsers(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Usuario creado con éxito`, 'success');
            reset2();
            getFleets();
            setIsloading(false);
            setCreateUserModal((prevState) => {
              return {
                ...prevState,
                isOpen: !createUseModal?.isOpen,
              };
            });
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const onSubmit3 = handleSubmit3(async (data) => {
    let dataToPost = { ...data };
    delete dataToPost.confirmPassword;

    dataToPost = filterParams(dataToPost);

    if (!isLoading) {
      setIsloading(true);
      usersService
        .editUsers(dataToPost, editUserModal?.selected)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Usuario editado con éxito`, 'success');
            reset3();
            getFleets();
            setIsloading(false);
            setEditUserModal((prevState) => {
              return {
                ...prevState,
                isOpen: !editUserModal?.isOpen,
                selected: '',
              };
            });
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  return (
    <>
      <CreateSalesUserModal
        modal={createUseModal}
        handleModal={handleCreateFleetForm}
        getFleets={getFleets}
        onChange={{ getRoles: getRoles, getProviders: getProviders }}
        register={register2}
        errors={errors2}
        action={onSubmit2}
        reset={reset2}
        watch={watch2}
        options={{
          rolesOptions: rolesOptions,
          userStatusOptions: userStatusOptions,
          modulesOptions: modulesOptions,
          userTypeOptions: userTypeOptions,
          providersOptions: providersOptions
        }}
      />
      <EditSalesUserModal
        modal={editUserModal}
        handleModal={handleEditFleetForm}
        getFleets={getFleets}
        onChange={{ getRoles: getRoles }}
        register={register3}
        errors={errors3}
        action={onSubmit3}
        reset={reset3}
        options={{
          rolesOptions: rolesOptions,
          userStatusOptions: userStatusOptions,
          modulesOptions: modulesOptions,
          userTypeOptions: userTypeOptions,
        }}
      />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Usuarios del Sistema {dataTable?.count}
          </h1>
          <ActionButton
            action={() => handleCreateFleetForm()}
            label={'+ Nuevo Usuario'}
          />
        </div>
        <div className="breadcrumb-container">
          <div className="exportables-buttons-container">
            <div style={{ marginTop: '12px' }}>
              <SelectPerPage
                label={'Registros por página'}
                options={itemsPerpage}
                value={dataTable?.limit}
                onChange={selectitemsPerpage}
              />
            </div>
          </div>
          <span></span>
        </div>
        <OperationsUserTable
          header={saleUsersTableheader}
          data={dataTable}
          handlePageChange={handlePageChange}
          redirectToOrder={redirectToOrder}
          tableRef={tableRef}
          openDetail={openDetail}
          handleEditFleetForm={handleEditFleetForm}
        />
      </div>
    </>
  );
};
