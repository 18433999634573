import { useEffect, useState } from 'react';
import { Header } from '../../../common/Header/Header.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Input } from '../../../common/Forms/Input/Input.jsx';
import { ApprovalModal } from '../../../common/Modals/Modals.jsx';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons.jsx';
import { productService } from '../../../../services/productService.js';
import { notify } from '../../../../assets/helpers/toast';
import { phoneCodes } from '../../../../assets/helpers/options.js';
import './Charge.scss';
import { vehicleService } from '../../../../services/vehicleService.js';

export const Charge = () => {
  let history = useHistory();
  const { t } = useTranslation();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [broadcastOptions, setBroadcastOptions] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemas.chargeSchema(t)),
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    setValue: setValue2,
    handleSubmit: handleSubmit2,
  } = useForm({
    resolver: yupResolver(schemas.RegisterStepTwoSchema(t)),
  });

  const onSubmit = handleSubmit(async (data) => { });

  const onSubmit2 = handleSubmit(async (data) => {
    history.push('/services');
  });

  const cancelPaymnent = () => {
    history.push('/incidence/assign-provider/9999999');
  };

  const getCarBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then((response) => {
        if (response.status === 200) {
          setBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('operations')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getBroadCastChannels = () => {
    productService
      .getBroadCastChannels()
      .then((response) => {
        if (response.status === 200) {
          setBroadcastOptions(response?.data?.broadcastChannels);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getCarBrands();
    getBroadCastChannels();
    getPaymentMethods();
  }, []);

  return (
    <>
      <Header />
      <ApprovalModal
        show={showApproveModal}
        t={t}
        register={register2}
        errors={errors2}
        action={onSubmit2}
        handleClose={setShowApproveModal}
      />
      <div className="container">
        <div className="card incidence-card">
          <div style={{ margin: '0px' }} className="buttons-container">
            <h2 style={{ margin: '0px' }}>Información Adicional</h2>
          </div>
          <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
          <form className="form-container">
            <div className="row">
              <Input title={'Detalles del propietario'} />
              <Input
                bootstrap={'col-4 col-sm-2 col-md-1'}
                label={'Teléfono del Cliente *'}
                type={'select'}
                options={phoneCodes}
                name={'name1'}
                register={register}
                placeholder={''}
              // errors={errors}
              />
              <Input
                bootstrap={'col-8 col-sm-4 col-md-3'}
                label={''}
                type={'text'}
                name={'name2'}
                register={register}
                placeholder={''}
              // errors={errors}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Marca del Vehículo*'}
                type={'select'}
                options={brandOptions}
                name={'name3'}
                register={register}
                placeholder={''}
              // errors={errors}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Año del Vehículo *'}
                type={'text'}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Color del Vehículo *'}
                type={'text'}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Placa del Vehículo *'}
                type={'text'}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Tiempo de llegada (min) *'}
                type={'text'}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Costo del Proveedor USD (sin IVA)*'}
                type={'text'}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />{' '}
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'PVP del servicio USD *'}
                type={'text'}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'¿Por dónde nos consiguió?'}
                type={'select'}
                options={broadcastOptions}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />
              <Input
                bootstrap={'col-12 col-md-4'}
                label={'Método 1'}
                type={'select'}
                options={paymentMethods}
                name={'name4'}
                register={register}
                placeholder={''}
              // errors={errors}
              />
            </div>
          </form>
          <div className="buttons-center-container">
            <AltButton action={() => cancelPaymnent()} label={'Cancelar'} />
            <ActionButton action={onSubmit} label={'Guardar'} />
          </div>
        </div>
      </div>
    </>
  );
};
